import { Button, Link } from "@mui/material";
import { exportRegisteredUsers } from "api";
import { useFullPageLoader } from "components/FullPageLoader";
import { UserProfileStore } from "layout/AppRouter";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import http from "utils/http";

function TableActions({ filters }: { filters: any; data: any }) {
  const role = UserProfileStore.useState((s) => s.state.role);
  const { setLoading } = useFullPageLoader();

  const { mutate } = useMutation(exportRegisteredUsers, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "Registered Trainees.xlsx";
      link.click();
      toast.success("Exported successfully");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleExport = () => {
    setLoading(true);
    mutate({
      ...filters,
      district:
        filters?.district == null || undefined ? "" : filters?.district?.id,
      courseId:
        filters?.course?.courseId == null || undefined
          ? ""
          : filters?.course?.courseId,
      course: "",
    });
  };

  const handleBulkUpdate = async () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) return;
      const formData = new FormData();
      formData.append("file", file);
      setLoading(true);

      try {
        await http.post(`/registration-form/bulk-update`, formData);
        setLoading(false);
        alert("Data Updated Successfully");
      } catch (error) {
        toast.error("Error Downloading");
        setLoading(false);
      }
    };
    input.click();
  };

  return (
    <div>
      <div className="flex items-center gap-4">
        <div>
          {(role === "SUPER_ADMIN" ||
            role === "ADMINISTRATOR" ||
            "SME_ADMIN") && (
            <Button
              variant="contained"
              startIcon={<img src="/images/bulkupload.svg" width="16px" />}
              style={{
                backgroundColor: "#F2F2F2",
                color: "#1C1C1C",
                margin: "0px",
                padding: "5px 10px",
                fontSize: "14px",
              }}
              onClick={handleBulkUpdate}>
              Bulk Update
            </Button>
          )}
          {(role === "SUPER_ADMIN" ||
            role === "ADMINISTRATOR" ||
            "SME_ADMIN") && (
            <Button
              variant="contained"
              startIcon={<img src="/images/export_execl.svg" width="16px" />}
              style={{
                backgroundColor: "#F2F2F2",
                color: "#1C1C1C",
                margin: "0px 10px",
                padding: "5px 10px",
                fontSize: "14px",
              }}
              onClick={handleExport}>
              Export to Excel
            </Button>
          )}
          {(role === "SUPER_ADMIN" ||
            role === "ADMINISTRATOR" ||
            "SME_ADMIN") && (
            <Link
              underline="none"
              style={{
                margin: "0px 5px",
                padding: "0px",
              }}
              href={`/print-table?${new URLSearchParams({
                ...filters,
                district:
                  filters?.district == null || undefined
                    ? ""
                    : filters?.district?.id,
                courseId: filters?.course == null ? "" : filters?.course?.id,
                course: "",
              }).toString()}&category=registeredTrainees`}
              target={"_blank"}>
              <Button
                variant="contained"
                startIcon={<img src="/images/export_pdf.svg" width="16px" />}
                style={{
                  backgroundColor: "#F2F2F2",
                  color: "#1C1C1C",
                  padding: "5px 10px",
                  fontSize: "14px",
                }}>
                Export to Pdf
              </Button>
            </Link>
          )}

          {(role === "SUPER_ADMIN" ||
            role === "ADMINISTRATOR" ||
            "SME_ADMIN") && (
            <Link
              underline="none"
              style={{
                margin: "0px 5px",
                padding: "0px",
              }}
              href={`/registered-trainees/download-forms?${new URLSearchParams({
                ...filters,
                district:
                  filters?.district == null ? "" : filters?.district?.id,
                course: "",
                courseId: filters?.course == null ? "" : filters?.course?.id,
              }).toString()}`}
              target={"_blank"}>
              <Button
                variant="contained"
                startIcon={<img src="/images/download.png" width="16px" />}
                style={{
                  backgroundColor: "#F2F2F2",
                  color: "#1C1C1C",
                  padding: "5px 10px",
                  fontSize: "14px",
                }}>
                Download Forms
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default TableActions;
