import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import { getWorkshopStorage, workshopUploadMedia } from "api";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
// import File from "./File";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import http from "utils/http";
import AddWorkshopVideoDialog from "./AddWorkshopVideoDialog";

function WorkshopFiles() {
  const [bulkLoading, setBulkLoading] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { folderId } = useParams();
  const id = folderId;
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState<any[]>([]);
  const [message, setMessage] = useState<any[]>([]);
  const [submited, setSubmited] = useState(false);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useQuery(
    ["getworkshopstorage", id],
    getWorkshopStorage
  );

  const handleSubmit = () => {
    files.map((item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("folderId", id!);
      mutate(formData);
    });
  };

  const handleChange = (e: any) => {
    setFiles(Array.from(e.target.files));
  };

  const { mutate } = useMutation(workshopUploadMedia, {
    onSuccess: (res: any) => {
      setStatus((prev) => [...prev, res?.data?.status]);
      setMessage((prev) => [...prev, res?.data?.message]);
      setSubmited(true);
      queryClient.invalidateQueries("storagetraineefollowup");
    },

    onError: (error: any) => {
      setSubmited(true);
      setStatus((prev) => [...prev, error.response?.data?.error]);
      setMessage((prev) => [...prev, error.response?.data?.message]);
    },
  });

  const handlenavBack = () => {
    navigate(-1);
  };

  const handlenavHome = () => {
    navigate(-2);
  };

  const handleUpload = () => {
    setFiles([]);
    setStatus([]);
    setMessage([]);
    setSubmited(false);
  };

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handleVideoClick = () => {
    setOpen(true);
  };

  async function handleDelte(id: any) {
    await http
      .delete(`workshop-storage/${id}`)
      .then((res) => {
        queryClient.invalidateQueries("getworkshopstorage");
      })
      .catch((res) => console.log(res?.data, "catch"));
  }

  const handleBulkUpdate = async () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) return;
      const formData = new FormData();
      formData.append("file", file);
      setBulkLoading(true);

      try {
        await http.post(`/trainee-followup/bulk-update/${id}`, formData);
        setBulkLoading(false);
        toast.success("Data Updated Successfully");
      } catch (error) {
        toast.error("Error Downloading");
        setBulkLoading(false);
      }
      queryClient.invalidateQueries("storagetraineefollowup");
    };
    input.click();

    const handlenavBack = () => {
      navigate(-1);
    };
  };

  if (isLoading) return <Loader />;

  return (
    <Box mt={4}>
      <Box mt={4} display="flex">
        <Typography
          sx={{
            cursor: "pointer",
          }}
          onClick={handlenavBack}
        >
          Home
        </Typography>{" "}
        <Typography m="0px 5px">/</Typography>
        <Typography
          sx={{
            cursor: "pointer",
          }}
        >
          {data?.data?.breadCrumbs[0].name}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box display="flex" gap={1}>
          <Box width="150px" height="35px">
            <input
              type="file"
              onChange={handleChange}
              multiple={true}
              name="file"
              accept="image/jpg, image/jpeg, image/png"
              id="upload"
              style={{ display: "none" }}
            />
            <label
              htmlFor="upload"
              style={{
                display: "inline",
                width: "400px",
                height: "150px",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#2D5696",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  padding: "5px 14px",
                }}
              >
                Upload Images
              </Box>
            </label>
          </Box>
          <Box
            sx={{
              height: "35px",
              backgroundColor: "#2D5696",
              color: "#FFFFFF",
              borderRadius: "4px",
              padding: "5px 14px",
            }}
            onClick={handleVideoClick}
          >
            Add Video
          </Box>
        </Box>
      </Box>

      {files.length > 0 ? (
        <Box>
          <Box
            sx={{
              overflowX: "auto",
              borderRadius: "4px",
              mt: 2,
              "& table": {
                width: "100%",
                border: "1px solid #2d5696",
              },
              "& table thead": {
                background: "#2d5696",
                color: "white",
              },
              "& th, & td": {
                border: "1px solid #2d5696",
                padding: 1,
              },
            }}
          >
            <table>
              <thead>
                <tr>
                  {["Name", "Type", "Size", "Status", "Error"].map(
                    (item, index) => (
                      <th key={index}>
                        <Typography fontSize={14} fontWeight={600}>
                          {item}
                        </Typography>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {files?.map((item: any, index: any) => (
                  <tr>
                    <td align="center">
                      <Typography variant="body2">{item?.name}</Typography>
                    </td>
                    <td align="center">
                      <Typography variant="body2">{item?.type}</Typography>
                    </td>
                    <td align="center">
                      <Typography variant="body2">
                        {bytesToSize(item?.size)}
                      </Typography>
                    </td>
                    {status.length == files?.length ? (
                      <td align="center">
                        {status[index] == "success" ? (
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <img src="/images/greentick.png" width="14px" />
                            <Typography sx={{ color: "#57CA22", ml: 1 }}>
                              Success
                            </Typography>
                          </Box>
                        ) : status.length > 0 ? (
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <img
                              src="/images/redexclamation.png"
                              width="14px"
                            />
                            <Typography sx={{ color: "#D2232E", ml: 1 }}>
                              Failed
                            </Typography>
                          </Box>
                        ) : (
                          <Box></Box>
                        )}
                      </td>
                    ) : (
                      <td>
                        <Typography></Typography>
                      </td>
                    )}
                    {message.length == files?.length ? (
                      <td align="center">
                        <Typography variant="body2">
                          {message[index]}
                        </Typography>
                      </td>
                    ) : (
                      <td>
                        <Typography></Typography>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            {submited === false ? (
              <Button
                style={{
                  marginTop: "8px",
                  backgroundColor: "#2d5696",
                  color: "#FFFFFF",
                }}
                onClick={handleSubmit}
              >
                submit
              </Button>
            ) : status.every((item: any) => item == "Bad Request") ? (
              <Button
                style={{
                  marginTop: "8px",
                  backgroundColor: "#2d5696",
                  color: "#FFFFFF",
                }}
                onClick={handleUpload}
              >
                Reupload
              </Button>
            ) : (
              <Button
                style={{
                  marginTop: "8px",
                  backgroundColor: "#2d5696",
                  color: "#FFFFFF",
                }}
                onClick={handleUpload}
              >
                Upload
              </Button>
            )}
          </Box>
        </Box>
      ) : data?.data?.result?.length > 0 ? (
        <Box>
          <Grid container spacing={1} mt={2}>
            {data?.data?.result?.map((item: any) => (
              <Grid item key={item?.id}>
                <Box
                  sx={{
                    borderRadius: "10px",
                    cursor: "pointer",
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "rgb(24, 47, 83, 0.2)",
                    padding: "15px 10px",
                    width: "280px",
                  }}
                >
                  <img
                    src={
                      item?.subType == "IMAGE"
                        ? "/images/image.svg"
                        : item?.subType == "AUDIO"
                        ? "/images/music.svg"
                        : "/images/video.svg"
                    }
                    width="30px"
                  />
                  <Typography>{item?.name}</Typography>
                  <Box onClick={() => handleDelte(item?.id)}>
                    <img src="/images/delete.png" width="15px" height="15px" />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          {data?.data?.count > 30 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pt: 2,
                pb: 1,
                alignItems: "center",
              }}
            >
              <Typography mr={2}>Page: {page}</Typography>
              <Pagination
                count={Math.round(data?.data?.count / 30)}
                page={page}
                onChange={handlePagination}
              />
            </Box>
          )}
        </Box>
      ) : (
        files?.length == 0 && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop="30vh"
          >
            <img src="/images/workshopempty.png" width="200px" height="200px" />
            <Typography mt={2}>No Files Available</Typography>
          </Box>
        )
      )}
      <AddWorkshopVideoDialog open={open} setOpen={setOpen} />
    </Box>
  );
}

export default WorkshopFiles;

export function bytesToSize(bytes: number): string {
  const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i: number = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)).toString()
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
}
