import { Box } from "@mui/material";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";

charts(FusionCharts);

const PieChart2d = ({ key, title, data }: any) => {
  const dataSource = {
    chart: {
      caption: `${title}`,
      captionAlignment: "left",
      captionOnTop: "1",
      captionHorizontalPadding: "40",
      legendPosition: "bottom", 
      legendAlign: "center", 
      legendBorderAlpha: "4",
      legendShadow: "0",
      legendItemFontSize: "12",
      legendItemFontColor: "#020202",
      legendItemWrap: "1", 
      legendIconScale: "1.2",
      legendItemPadding: "10", 
      legendItemMargin: "10",
      legendItemWidth: "150", 
      theme: "fusion",
      chartAlignment: "left",
      chartRightMargin: "100", 
      showLabels: "1",
      showValues: "1",
      alignCaptionWithCanvas: "0",
      captionPadding: "0",
      decimals: "1",
      plotToolText: "<b>$percentValue</b> of our Android users are on <b>$label</b>",
    },
    data: data?.map(({ key, count }: any) => ({
      label: key,
      value: count,
    })),
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "320px",
        borderRadius: "20px",
        marginBottom: "20px",
        boxShadow: "0px 6px 16px #0000001A",
        "& .fusioncharts-container": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <ReactFusioncharts
        type="doughnut2d"
        width="100%"
        // height="100%"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </Box>
  );
};

export default PieChart2d;
