// @ts-nocheck
import { Box, Card, Typography } from "@mui/material";
import { uploadMediaByformId } from "api";
import Loader from "components/Loader";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import http from "utils/http";
import UploadTraineeFiles from "./UpdateTraineeFiles";

function EditTraineeFollowup() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [registrationrecord, setregistrationrecord] = useState("IMAGE");
  const [registrationscreenshot, setregistrationscreenshot] = useState("IMAGE");
  const [courserecord, setcourserecord] = useState("IMAGE");
  const [datascreenshot, setdatascreenshot] = useState("IMAGE");
  const [coursescreenshot, setcoursescreenshot] = useState("IMAGE");
  const [examrecord, setexamrecord] = useState("IMAGE");
  const [examscreenshot, setexamscreenshot] = useState("IMAGE");
  const [certificatescreenshot, setcertificatescreenshot] = useState("IMAGE");
  const [certificatevideo, setcertificatevideo] = useState("IMAGE");
  const [placementoffer, setplacementoffer] = useState("IMAGE");
  const [feedbackvideo, setfeedbackvideo] = useState("IMAGE");
  const [keys, setkeys] = useState([]);

  const { data, isLoading } = useQuery("traineefollowupbyid", () =>
    http.get(`/trainee-followup/${id}`).then((res) => res.data)
  );

  const { mutate } = useMutation(uploadMediaByformId, {
    onSuccess: (res: any) => {
      navigate("/trainee-followup");
    },
    onError: (err: any) => {},
  });

  const handleSave = () => {
    let data = { ids: keys };
    mutate({ data, id });
  };
  if (isLoading) return <Loader />;

  return (
    <Box>
      <Box pt={4}>
        <Box mb={2}>
          <Link to={"/trainee-followup"}>
            <Typography color="#2D5696" fontSize="18px" fontWeight="600">
              {"< "}
              Edit Trainee Follow Up{" "}
            </Typography>
          </Link>
        </Box>

        <Card elevation={3}>
          <Box
            p={4}
            minHeight="120px"
            display="flex"
            justifyContent="space-around"
          >
            <Box minWidth="85px">
              <img src={data?.applicantFileUrl} width="85px" />
            </Box>
            <CustomTitleView title="BOCW ID" value={data?.bocRegNo} />
            <CustomTitleView title="Trainee Name" value={data?.name} />
            <CustomTitleView title="Mobile Number" value={data?.mobile} />
            <CustomTitleView title="Course Name" value={data?.course?.name} />
            <CustomTitleView title="Setwin Id" value={data?.formId} />
            <CustomTitleView title="District" value={data?.district} />
          </Box>
        </Card>
        <UploadTraineeFiles
          title="Registration App Record"
          state={registrationrecord}
          type="REGISTRATION_APP_RECORD"
          handleRadio={setregistrationrecord}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.REGISTRATION_APP_RECORD}
        />
        <UploadTraineeFiles
          title="Registration App Screenshot"
          state={registrationscreenshot}
          type="REGISTRATION_APP_SCREENSHOT"
          handleRadio={setregistrationscreenshot}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.REGISTRATION_APP_SCREENSHOT}
        />
        <UploadTraineeFiles
          title="Course Progress record"
          state={courserecord}
          type="COURSE_PROGRESS_RECORD"
          handleRadio={setcourserecord}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.COURSE_PROGRESS_RECORD}
        />
        <UploadTraineeFiles
          title="Course Progress Screenshot"
          state={coursescreenshot}
          type="COURSE_PROGRESS_SCREENSHOT"
          handleRadio={setcoursescreenshot}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.COURSE_PROGRESS_SCREENSHOT}
        />
        <UploadTraineeFiles
          title="Data Compensation Screenshot"
          state={datascreenshot}
          type="DATA_COMPENSATION_SCREENSHOT"
          handleRadio={setdatascreenshot}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.DATA_COMPENSATION_SCREENSHOT}
        />
        <UploadTraineeFiles
          title="Exam Module Record"
          state={examrecord}
          type="EXAM_MODULE_RECORD"
          handleRadio={setexamrecord}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.EXAM_MODULE_RECORD}
        />
        <UploadTraineeFiles
          title="Exam Module Screenshot"
          state={examscreenshot}
          type="EXAM_MODULE_SCREENSHOT"
          handleRadio={setexamscreenshot}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.EXAM_MODULE_SCREENSHOT}
        />
        <UploadTraineeFiles
          title="Certificate Screenshot"
          state={certificatescreenshot}
          type="CERTIFICATE_SCREENSHOT"
          handleRadio={setcertificatescreenshot}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.CERTIFICATE_SCREENSHOT}
        />
        <UploadTraineeFiles
          title="Certificate Video"
          state={certificatevideo}
          type="CERTIFICATE_VIDEO"
          handleRadio={setcertificatevideo}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.CERTIFICATE_VIDEO}
        />
        <UploadTraineeFiles
          title="Placement Offer"
          state={placementoffer}
          type="PLACEMENT_OFFER"
          handleRadio={setplacementoffer}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.PLACEMENT_OFFER}
        />
        <UploadTraineeFiles
          title="Feedback Video"
          state={feedbackvideo}
          type="FEEDBACK_VIDEO"
          handleRadio={setfeedbackvideo}
          setKeys={setkeys}
          URL={data?.traineeFollowup?.FEEDBACK_VIDEO}
        />
      </Box>
    </Box>
  );
}

export default EditTraineeFollowup;

const CustomTitleView = ({ title, value }: any) => {
  return (
    <Box p={1}>
      <Typography sx={{ opacity: 0.5, fontSize: "14px" }}>{title}</Typography>
      <Typography sx={{ fontSize: "16px", mt: "6px", maxWidth: "340px" }}>
        {value}
      </Typography>
    </Box>
  );
};
