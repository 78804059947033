import { Box, Stack, styled } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

export const drawerWidth = 280;

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  paddingTop: "5rem",
  minWidth: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));



export const StyledFeedBackComponent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 50px;
  @media (min-width: 600px) {
    gap: 15px; 
  }
  @media (min-width: 1280px) {
    gap: 30px; 
  }
`;
export const StyledFeedbackStackComponent = styled(Stack)`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  
  @media (min-width: 700px) {
    flex-direction: column;
    gap: 0px; 
  }

  @media (min-width: 960px) {
    flex-direction: row; 
    gap: 15px; 
    align-items:flex-end;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    gap: 15px; 
    align-items:flex-start;
`;
