import { AccountCircle, Logout } from "@mui/icons-material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  alpha,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Outlet,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import http from "utils/http";
import { UserProfileStore } from "./AppRouter";
import TemporaryDrawer from "./MobileMenu";
import { AppBar, drawerWidth } from "./styles";

export default function Layout(header: any) {
  const navigate = useNavigate();
  const [auth, setAuth] = useState<boolean>(true);
  const [open, setOpen] = useState(true);
  const [openmenu, setOpenMenu] = useState(false);
  const role = UserProfileStore.useState((s) => s.state.role);
  const [openarrow, setArrowOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!localStorage.getItem("AUTH_TOKEN")) {
      navigate("/login");
    } else {
      http
        .get("/user/profile")
        .then((res) => {
          UserProfileStore.update((s) => {
            s.state = res?.data;
          });
          localStorage.setItem("role", res?.data?.role);
        })
        .catch((e) => {
          navigate("/login");
        });
    }
  }, []);

  const { data: districtwiseData, isLoading: districtLoding } = useQuery(
    "workshopDistrictwise",
    () => http.get(`/workshop/district-wise`).then((res) => res.data)
  );

  const menu = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "/images/dashboard.png",
    },
    {
      title: "Registered Trainees",
      path: "registered-trainees",
      icon: "/images/registered-trainees.png",
    },
    {
      title: "Approved Trainees",
      path: "approved-trainees",
      icon: "/images/approved-trainees.png",
    },
    {
      title: "Time Statistics",
      path: "time-statistics",
      icon: "/images/time-statistics.png",
    },
    {
      title: "50% Course Completion",
      path: "course-completion",
      icon: "/images/course-completion.png",
    },
    {
      title: "Certified Trainees",
      path: "certified-trainees",
      icon: "/images/certified-trainees.png",
    },
    {
      title: "Trainee Follow Up",
      path: "/trainee-followup",
      icon: "/images/traineefollow-up.png",
    },
    {
      title: "Workshop & Placement Drive",
      path: "/workshop",
      icon: "/images/workshop.png",
    },
  ];

  const handleMenu = () => {
    setOpenMenu(true);
  };

  const handleMenuClick = () => {
    setArrowOpen(!openarrow);
  };

  return (
    <>
      {role==="FEEDBACK_ADMIN" ?
        (
          <>
            <Box
              sx={{
                display: "flex",
                height: header?.header == "true" ? "56px" : "65px",
              }}>
              <AppBar
                className="print-hide"
                position="fixed"
                sx={{ background: "white", zIndex: (theme) => 1201 }}>
                <Toolbar sx={{ m: 0, p: 0 }}>
                  <>
                    <Box
                      sx={{
                        display: header?.header == "true" ? "inline-block" : "none",
                        "@media(max-width:900px)": {
                          display: "inline-block",
                        },
                      }}>
                      <IconButton onClick={() => handleMenu()}>
                        <MenuIcon color="primary" />
                      </IconButton>
                    </Box>
                    <div className="flex-grow">
                      <div className="flex gap-4 items-center">
                        <img
                          // src="/images/logo.jpg"
                          src="/images/logo_new.jpg"
                          className="h-full w-auto rounded-full object-contain"
                          alt="Telangana"
                        />
                      </div>
                    </div>
                    <Box
                      display="flex"
                      justifyContent="space-around"
                      gap="10px"
                      ml="25px"
                      mr="10px">
                        {auth && <AccountMenu />}
                    </Box>
                  </>
                </Toolbar>
              </AppBar>
              <Drawer
                variant="permanent"
                className="print-hide"
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    background: "white",
                    display: header?.header == "true" ? "none" : "block",
                  },
                  "@media (max-width:900px)": {
                    display: "none",
                  },
                }}>
                <Toolbar />
                <Box sx={{ overflow: "auto" }}>
                  <List>
                    <Box
                      display="flex"
                      justifyContent="center"
                      height="60px"
                      alignItems="center"
                      sx={{
                        "&:hover": {
                          background: true
                            ? alpha("#67B8FB", 0.6)
                            : alpha("#67B8FB", 0.6),
                        },
                      }}>
                      <CustomSubLink to="/dashboard" marginL="false">
                        <IconButton>
                          <img
                            src="/images/dashboard.png"
                            style={{ width: "24px" }}
                            alt="Storage"
                          />
                        </IconButton>
                        <ListItemText
                          sx={{
                            color: "#0E125E",
                            "& .MuiTypography-root": {
                              fontWeight: 600,
                            },
                          }}
                          primary="Dashboard"
                        />
                      </CustomSubLink>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      height="60px"
                      alignItems="center"
                      sx={{
                        "&:hover": {
                          background: true
                            ? alpha("#67B8FB", 0.6)
                            : alpha("#67B8FB", 0.6),
                        },
                      }}>
                      <CustomSubLink to="/feedback" marginL="false">
                        <IconButton>
                          <img
                            src="/images/feedbackLogo.png"
                            style={{ width: "24px" }}
                            alt="Storage"
                          />
                        </IconButton>
                        <ListItemText
                          sx={{
                            color: "#0E125E",
                            "& .MuiTypography-root": {
                              fontWeight: 600,
                            },
                          }}
                          primary="FeedBack"
                        />
                      </CustomSubLink>
                    </Box>

                  </List>
                </Box>
              </Drawer>
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  p: 3,
                  minWidth: `calc(100% - ${drawerWidth}px)`,
                  marginTop: "2rem",
                }}>
                <Box paddingBottom={"1rem"}>
                  <Outlet />
                </Box>
              </Box>
              <TemporaryDrawer open={openmenu} setOpen={setOpenMenu} />
              
            </Box>
          </>
        ):
        (
        <Box
        sx={{
          display: "flex",
          height: header?.header == "true" ? "56px" : "65px",
        }}>
        <AppBar
          className="print-hide"
          position="fixed"
          sx={{ background: "white", zIndex: (theme) => 1201 }}>
          <Toolbar sx={{ m: 0, p: 0 }}>
            <>
              <Box
                sx={{
                  display: header?.header == "true" ? "inline-block" : "none",
                  "@media(max-width:900px)": {
                    display: "inline-block",
                  },
                }}>
                <IconButton onClick={() => handleMenu()}>
                  <MenuIcon color="primary" />
                </IconButton>
              </Box>
              <div className="flex-grow">
                <div className="flex gap-4 items-center">
                  <img
                    // src="/images/logo.jpg"
                    src="/images/logo_new.jpg"
                    className="h-full w-auto rounded-full object-contain"
                    alt="Telangana"
                  />
                </div>
              </div>

              <Box
                display="flex"
                justifyContent="space-around"
                gap="10px"
                ml="25px"
                mr="10px">
                {(role == "SUPER_ADMIN" || role == "ADMINISTRATOR") && (
                  <AccountSettings />
                )}
                {auth && <AccountMenu />}
              </Box>
            </>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className="print-hide"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              background: "white",
              display: header?.header == "true" ? "none" : "block",
            },
            "@media (max-width:900px)": {
              display: "none",
            },
          }}>
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <List>
              {menu
                .filter((item) => {
                  if (item.title == "Admin Settings") {
                    if (role == "SUPER_ADMIN") {
                      return item;
                    }
                  } else if (item.title === "District Course Details") {
                    if (role == "SUPER_ADMIN") {
                      return item;
                    }
                  } else if (item.title == "Courses") {
                    if (role == "SUPER_ADMIN") {
                      return item;
                    }
                  } else {
                    return item;
                  }
                })
                .map((item, index) => (
                  <CustomLink to={item.path} key={index} marginL="false">
                    <IconButton>
                      <img
                        src={item.icon}
                        style={{ width: "24px" }}
                        alt={item.title}
                      />
                    </IconButton>
                    <ListItemText
                      sx={{
                        color: "#0E125E",
                        "& .MuiTypography-root": {
                          fontWeight: 600,
                        },
                      }}
                      primary={item.title}
                    />
                  </CustomLink>
                ))}

              {/* {(role === "SUPER_ADMIN" ||
                role == "ADMINISTRATOR" ||
                role == "SME_ADMIN") && (
                <CustomLink to={"/workshop"} marginL="false">
                  <IconButton>
                    <img
                      src="/images/workshop.png"
                      style={{ width: "24px" }}
                      alt="workshop"
                    />
                  </IconButton>
                  <ListItemText
                    sx={{
                      color: "#0E125E",
                      "& .MuiTypography-root": {
                        fontWeight: 600,
                      },
                    }}
                    primary="Workshop"
                  />
                </CustomLink>
              )} */}
              {/* {role !== "SUPER_ADMIN" &&
                role !== "ADMINISTRATOR" &&
                role !== "SME_ADMIN" &&
                districtwiseData?.length != 0 &&
                districtwiseData?.length <= 2 && (
                  <CustomLink to={"/workshop/district-view"} marginL="false">
                    <IconButton>
                      <img
                        src="/images/workshop.png"
                        style={{ width: "24px" }}
                        alt="workshop"
                      />
                    </IconButton>
                    <ListItemText
                      sx={{
                        color: "#0E125E",
                        "& .MuiTypography-root": {
                          fontWeight: 600,
                        },
                      }}
                      primary="Workshop"
                    />
                  </CustomLink>
                )} */}
              {/* {role !== "SUPER_ADMIN" &&
                role !== "ADMINISTRATOR" &&
                role !== "SME_ADMIN" &&
                districtwiseData?.length > 2 && (
                  <CustomLink to={"/workshop"} marginL="false">
                    <IconButton>
                      <img
                        src="/images/workshop.png"
                        style={{ width: "24px" }}
                        alt="workshop"
                      />
                    </IconButton>
                    <ListItemText
                      sx={{
                        color: "#0E125E",
                        "& .MuiTypography-root": {
                          fontWeight: 600,
                        },
                      }}
                      primary="Workshop"
                    />
                  </CustomLink>
                )} */}

              {(role === "SUPER_ADMIN" || role === "ADMINISTRATOR") && (
                <Box onClick={handleMenuClick}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    height="60px"
                    alignItems="center"
                    sx={{
                      "&:hover": {
                        background: true
                          ? alpha("#67B8FB", 0.6)
                          : alpha("#67B8FB", 0.6),
                      },
                    }}>
                    <IconButton>
                      <img
                        src="/images/storage.png"
                        style={{ width: "24px" }}
                        alt="Storage"
                      />
                    </IconButton>
                    <ListItemText
                      sx={{
                        color: "#0E125E",
                        "& .MuiTypography-root": {
                          fontWeight: 600,
                        },
                      }}
                      primary="TBOCWWB Storage"
                    />
                    {openarrow ? (
                      <ExpandLess sx={{ color: "primary", mr: 7.5 }} />
                    ) : (
                      <ExpandMore sx={{ color: "primary", mr: 7.5 }} />
                    )}
                  </Box>
                </Box>
              )}
              
              <Collapse in={openarrow} timeout="auto" unmountOnExit>
                <Box>
                  <List component="div" disablePadding>
                    {submenu
                      ?.filter((i: any) => {
                        if (role == "SUPER_ADMIN" || role === "ADMINISTRATOR") {
                          return i;
                        }
                      })
                      .map((item, index) => (
                        <CustomSubLink to={item.path} key={index} marginL="true">
                          <IconButton>
                            <img
                              src={item.icon}
                              style={{ width: "24px" }}
                              alt={item.title}
                            />
                          </IconButton>
                          <ListItemText
                            sx={{
                              color: "#0E125E",
                              "& .MuiTypography-root": {
                                fontWeight: 600,
                              },
                            }}
                            primary={item.title}
                          />
                        </CustomSubLink>
                      ))}
                  </List>
                </Box>
              </Collapse>
              <Box
                display="flex"
                justifyContent="center"
                height="60px"
                alignItems="center"
                sx={{
                  "&:hover": {
                    background: true
                      ? alpha("#67B8FB", 0.6)
                      : alpha("#67B8FB", 0.6),
                  },
                }}>
                <CustomSubLink to="/feedback" marginL="false">
                  <IconButton>
                    <img
                      src="/images/feedbackLogo.png"
                      style={{ width: "24px" }}
                      alt="Storage"
                    />
                  </IconButton>
                  <ListItemText
                    sx={{
                      color: "#0E125E",
                      "& .MuiTypography-root": {
                        fontWeight: 600,
                      },
                    }}
                    primary="FeedBack"
                  />
                </CustomSubLink>
              </Box>
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            minWidth: `calc(100% - ${drawerWidth}px)`,
            marginTop: "2rem",
          }}>
          <Box paddingBottom={"1rem"}>
            <Outlet />
          </Box>
        </Box>
        <TemporaryDrawer open={openmenu} setOpen={setOpenMenu} />
        </Box>
        )
      }
    </>
    
  );
}

export const CustomSubLink = ({
  to,
  children,
  marginL,
}: {
  to: string;
  children: ReactNode;
  marginL: any;
}) => {
  const navigate = useNavigate();

  let resolved = useResolvedPath(to);

  let a = useParams();
  let match = useMatch({ path: `${resolved.pathname}/*`, end: true });

  return (
    <ListItemButton
      sx={{
        pl: marginL == "true" ? "28px" : "0px",
        background: match ? alpha("#67B8FB", 1) : "transparent",
        color: match ? "#67B8FB" : alpha("#67B8FB", 0.2),
        "&:hover": {
          background: match ? alpha("#67B8FB", 0.6) : alpha("#67B8FB", 0.6),
        },
      }}
      onClick={() => {
        navigate(to);
      }}>
      {children}
    </ListItemButton>
  );
};

export const CustomLink = ({
  to,
  children,
  marginL,
}: {
  to: string;
  children: ReactNode;
  marginL: any;
}) => {
  const navigate = useNavigate();

  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <ListItemButton
      sx={{
        pl: marginL == "true" ? "28px" : "0px",
        background: match ? alpha("#67B8FB", 1) : "transparent",
        color: match ? "#67B8FB" : alpha("#67B8FB", 0.2),
        "&:hover": {
          background: match ? alpha("#67B8FB", 0.6) : alpha("#67B8FB", 0.6),
        },
      }}
      onClick={() => {
        navigate(to);
      }}>
      {children}
    </ListItemButton>
  );
};

const AccountMenu = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="primary"
        sx={{
          "@media (max-width:900px)": {
            p: 0,
            m: 0,
          },
        }}>
        <AccountCircle />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

const AccountSettings = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdminuser = () => {
    navigate("/admin-settings");
  };

  const handleCourse = () => {
    navigate("/courses");
  };

  const handleDistrictCourse = () => {
    navigate("/district-course-details");
  };

  return (
    
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="primary"
        sx={{
          "@media (max-width:900px)": {
            p: 0,
            m: 0,
          },
        }}>
        <SettingsIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem onClick={handleAdminuser}>
          <ListItemIcon>
            <img src="/images/admin-settings.png" width="18px" alt="admin" />
          </ListItemIcon>
          Admin Users
        </MenuItem>
        <MenuItem onClick={handleCourse}>
          <ListItemIcon>
            <img src="/images/course.svg" width="18px" alt="admin" />
          </ListItemIcon>
          Courses
        </MenuItem>
        <MenuItem onClick={handleDistrictCourse}>
          <ListItemIcon>
            <img
              src="/images/districtcoursedetails.png"
              width="18px"
              alt="admin"
            />
          </ListItemIcon>
          District Course Dates
        </MenuItem>
      </Menu>
    </div>
  );
};

const submenu = [
  {
    title: "Certificate Upload",
    path: "/storage",
    icon: "/images/certificateuploadicon.png",
  },
  {
    title: "Trainee Follow Up",
    path: "/trainee-follow-up/storage",
    icon: "/images/traineefollow-up.png",
  },
  {
    title: "Workshop",
    path: "/workshop/storage",
    icon: "/images/workshop.png",
  },
  {
    title: "Logs",
    path: "/logs/storage",
    icon: "/images/log.svg",
  },
];
