import Login from "pages/Login";
import ViewDistrictWorkshop from "pages/ViewDistrictWorkshop";
import AdminSettings from "pages/admin-settings";
import ApprovedTrainees from "pages/approved-trainees";
import CertifixcateUpload from "pages/certificateupload";
import Districtsfolder from "pages/certificateupload/Districtsfolder";
import Uploadfile from "pages/certificateupload/Uploadfile";
import CertifiedTrainees from "pages/certified-trainees";
import CourseCompletion from "pages/course-completion";
import Courses from "pages/courses";
import DistrictCourseDetails from "pages/district-course-details";
import RegisteredTrainees from "pages/registered-trainees";
import ApplicationDetailsPage from "pages/registered-trainees/details";
import PrintTable from "pages/registered-trainees/print/PrintTable";
import PrintPdfPage from "pages/registered-trainees/print/printPdf";
import PrintPdfPageId from "pages/registered-trainees/print/printPdfid";
import DashboardStats from "pages/stats";
import Logs from "pages/storage/logs/Logs";
import TraineeFollowUpFiles from "pages/storage/trainee/TraineeFollowUpFiles";
import TraineeStorage from "pages/storage/trainee/TraineeStorage";
import WorkshopFiles from "pages/storage/workshop/WorkshopFiles";
import WorkshopStorage from "pages/storage/workshop/WorkshopStorage";
import TimeStatistics from "pages/time-statistics";
import TraineeFollowUp from "pages/traineefollow-up";
import EditTraineeFollowup from "pages/traineefollow-up/EditTraineeFollowup";
import ViewTraineeFollowup from "pages/traineefollow-up/ViewTraineeFollowup";
import Workshop from "pages/workshop";
import EditWorkshop from "pages/workshop/EditWorkshop";
import ViewWorkshop from "pages/workshop/ViewWorkshop";
import { Store } from "pullstate";
import { Navigate, Route, Routes } from "react-router-dom";
import { GoBackButton } from "../components";
import Layout from "./Layout";
import TraineeFollowupDistricts from "pages/storage/trainee/DistrictFolder";
import TraineeFollowupCategory from "pages/storage/trainee/CategoryFolders";
import CertifiedPrintPdf from "pages/registered-trainees/print/certifiedprintpdf";
import Feedback from "pages/feedback"
import ViewComponent from "pages/feedback/ViewComponent";
import Statistics from "pages/feedback/Statistics";
import PrintFeedbackForms from "pages/feedback/PrintFeedbackForms";

const initState = {
  id: null,
  name: "",
  username: "",
  email: "",
  mobile: "",
  password: "",
  role: "",
  isActive: null,
  isSuperUser: null,
  profileImage: null,
  updatedAt: "",
  createdAt: "",
};

export const UserProfileStore = new Store({
  state: initState,
  distName: "AllDistricts",
});

const AppRouter = () => {
  const a = UserProfileStore.useState((s) => s.state.role);

  const b = localStorage.getItem("role");

  let role = a || b;
  console.log("Role",role);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="/dashboard" element={<DashboardStats />} />
        <Route path="/feedback" element={<Feedback />} />

        <Route path="registered-trainees/*">
          <Route index element={<RegisteredTrainees />} />
          <Route path=":applicationId" element={<ApplicationDetailsPage />} />
        </Route>

        <Route path="approved-trainees/*" element={<ApprovedTrainees />} />
        <Route path="time-statistics/*" element={<TimeStatistics />} />
        <Route path="course-completion/*" element={<CourseCompletion />} />
        <Route path="certified-trainees/*" element={<CertifiedTrainees />} />

        {(role == "SUPER_ADMIN" || role == "ADMINISTRATOR") && (
          <>
            <Route path="trainee-followup/*" element={<TraineeFollowUp />} />
            <Route
              path="admin-settings"
              element={
                role == "SUPER_ADMIN" || role == "ADMINISTRATOR" ? (
                  <AdminSettings />
                ) : (
                  <NotFoundPage />
                )
              }
            />
            <Route
              path="district-course-details/*"
              element={<DistrictCourseDetails />}
            />
            <Route path="courses/*" element={<Courses />} />
            <Route path="storage" element={<CertifixcateUpload />} />
            <Route path="storage/:id" element={<Districtsfolder />} />
            <Route path="storage/batch/:folderId" element={<Uploadfile />} />
            <Route
              path="trainee-follow-up/storage"
              element={<TraineeStorage />}
            />
            <Route
              path="trainee-follow-up/storage/:id"
              element={<TraineeFollowupDistricts />}
            />
            <Route
              path="trainee-follow-up/storage/batch/:id"
              element={<TraineeFollowupCategory />}
            />
            <Route
              path="trainee-follow-up/storage/batch/district/:id"
              element={<TraineeFollowUpFiles />}
            />
            <Route path="workshop/storage" element={<WorkshopStorage />} />
            <Route
              path="workshop/storage/:folderId"
              element={<WorkshopFiles />}
            />
            <Route
              path="trainee-follow-up/storage/:folderId"
              element={<TraineeFollowUpFiles />}
            />
            <Route path="logs/storage" element={<Logs />} />
            <Route
              path="trainee-followup/edit/:id"
              element={<EditTraineeFollowup />}
            />
          </>
        )}

        <Route path="workshop/edit/:id" element={<EditWorkshop />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/feedback/:id" element={<ViewComponent />} />
      </Route>
      <Route path="/feedback/view-statistics" element={<Statistics />} />

      {(role == "SUPER_ADMIN" || role == "ADMINISTRATOR") && (
        <>
          <Route
            path="trainee-followup/view/:id"
            element={<ViewTraineeFollowup />}
          />
        </>
      )}
      <Route path="workshop/view/:id" element={<ViewWorkshop />} />
      <Route path="workshop" element={<Workshop />} />
      <Route path="workshop/district-view" element={<ViewDistrictWorkshop />} />
      

      <Route path="/login" element={<Login />} />
      <Route path="/print-table" element={<PrintTable />} />
      <Route
        path="/registered-trainees/download-forms"
        element={<PrintPdfPage />}
      />
      <Route path="/certified/download-forms" element={<CertifiedPrintPdf />} />
      <Route path="/feedback/download-forms" element={<PrintFeedbackForms />} />

      <Route
        path="/registered-trainees/download-id/:id"
        element={<PrintPdfPageId />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRouter;

const NotFoundPage = () => {
  return <GoBackButton label="Page Not Found. Go Back" />;
};
