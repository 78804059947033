import {
  Autocomplete,
  Box,
  Button,
  Chip,
  createTheme,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { getDistrictCourseDetails } from "api";
import { useFullPageLoader } from "components/FullPageLoader";
import Loader from "components/Loader";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import theme from "styles/theme";
import http from "utils/http";
import AddDistrictCourse from "./add-district-course";
import EditDistrictCourse from "./edit-district-course";

const themeOption = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: "#2d5696",
    },
  },
});

interface IFilters {
  district?: any;
  batch?: any;
}

function DistrictCourseDetails() {
  const [filters, setFilters] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [batch, setBatch] = useState("");
  const [district, setDistrict] = useState("");
  const { setLoading } = useFullPageLoader();

  const { setImportLdg } = useFullPageLoader();

  const { data, isLoading } = useQuery(
    [
      "district-course-details",
      {
        batch: batch,
        districtId: filters ? filters?.id : "",
      },
    ],
    getDistrictCourseDetails
  );

  const { data: batchs } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );
  const { data: districts, isLoading: DistrictLdg } = useQuery(
    "districts",
    () => http.get("/district").then((res) => res.data)
  );

  if (isLoading || DistrictLdg) return <Loader />;

  const handleBulkimport = async () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];

      if (!file) return;
      const formData = new FormData();
      formData.append("file", file);

      try {
        await http.post(`/batch-course-details/upload`, formData);
        alert("Data Updated Successfully");
      } catch (error) {
        toast.error("Error Downloading");
      }
    };
    input.click();
  };

  const getData = () => {
    let result = [...data?.data];
    return result;
  };

  return (
    <>
      <ThemeProvider theme={themeOption}>
        <Box py={4}>
          <Box>
            <div
              className="grid gap-x-1"
              style={{
                gridTemplateColumns: "1fr 1fr",

                alignItems: "end",
              }}>
              <FormControl fullWidth>
                <FormLabel>Batch</FormLabel>
                <Select
                  size="small"
                  displayEmpty
                  value={batch}
                  onChange={(e: any) => {
                    setBatch(e.target.value);
                  }}>
                  <MenuItem disabled value="">
                    <Typography sx={{ opacity: 0.5 }}>Select Batch</Typography>
                  </MenuItem>
                  {batchs?.map((item: any) => (
                    <MenuItem value={item?.batch}>{item?.batch}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <FormLabel>District</FormLabel>
                <Autocomplete
                  fullWidth
                  value={filters || ""}
                  options={districts || ""}
                  getOptionLabel={(option: any) => option?.name || ""}
                  onChange={(event, value: any) => {
                    setFilters(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="All Districts"
                    />
                  )}
                />
              </FormControl>
            </div>
          </Box>
          <div className="flex gap-4 items-center mt-2">
            <h3 className="text-primary font-medium text-sm text-opacity-80">
              Selected Filters
            </h3>
            <div className="">
              <span className="text-gray-500">Batch: </span>
              <span>
                {batch ? (
                  <Chip
                    label={batch}
                    onDelete={() => {
                      setBatch("");
                    }}
                  />
                ) : (
                  "All"
                )}
              </span>
            </div>
            <div className="">
              <span className="text-gray-500">District: </span>
              <span>
                {district ? (
                  <Chip
                    label={
                      districts?.find((item: any) => item?.id == district)?.name
                    }
                    onDelete={() => {
                      setDistrict("");
                    }}
                  />
                ) : (
                  "All"
                )}
              </span>
            </div>
          </div>
          <Box className=" flex justify-end gap-4 items-center" mr={4} mb={1}>
            <Button
              variant="contained"
              startIcon={<img src="/images/bulkupload.svg" width="16px" />}
              style={{
                backgroundColor: "#F2F2F2",
                color: "#1C1C1C",
                margin: "0px",
                padding: "5px 10px",
                fontSize: "14px",
              }}
              onClick={handleBulkimport}>
              Bulk Import
            </Button>
            <Link to="?addOpen=true">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#F2F2F2",
                  color: "#1C1C1C",
                  margin: "0px",
                  padding: "5px 10px",
                  fontSize: "14px",
                }}>
                Add
              </Button>
            </Link>
          </Box>

          <Box
            sx={{
              overflowX: "auto",
              borderRadius: "4px",
              "& table": {
                width: "100%",
                border: "1px solid #2d5696",
              },
              "& table thead": {
                background: "#2d5696",
                color: "white",
              },
              "& th, & td": {
                border: "1px solid #2d5696",
                padding: 1,
              },
            }}>
            <table>
              <thead>
                <tr>
                  {["District", "Batch", "Start Date", "Edit"].map(
                    (item, index) => (
                      <th key={index}>
                        <Typography fontSize={14} fontWeight={600}>
                          {item}
                        </Typography>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {getData()?.length ? (
                  <>
                    {getData()?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td align="center">
                          <Typography variant="body2">
                            {item?.district}
                          </Typography>
                        </td>
                        <td align="center">
                          <Typography variant="body2">{item?.batch}</Typography>
                        </td>
                        <td align="center">
                          <Typography variant="body2">
                            {item?.startDate}
                          </Typography>
                        </td>
                        <td align="center">
                          <Link
                            className="hover:underline text-primary font-medium"
                            to={`?editOpen=true&selected=${item?.district}`}>
                            Edit
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <section>No Records Found</section>
                )}
              </tbody>
            </table>
          </Box>
        </Box>
        <EditDistrictCourse
          data={getData()?.find(
            (item: any) => item?.district === searchParams?.get("selected")
          )}
          open={!!searchParams.get("editOpen")}
          setOpen={(v) => setSearchParams({})}
        />
        <AddDistrictCourse
          open={!!searchParams.get("addOpen")}
          setOpen={(v) => setSearchParams({})}
        />
      </ThemeProvider>
    </>
  );
}

export default DistrictCourseDetails;
