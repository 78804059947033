import { createTheme, ThemeOptions } from "@mui/material";

// const FONT_FAMILY = ["CenturyGothic"].join(",");

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#0E125E",
    },
    secondary: {
      main: "#f50000",
    },
    info: {
      main: "#000",
    },
  },
  typography: {
    fontFamily: "CenturyGothic",
  },
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       textTransform: "capitalize",
    //       fontWeight: "bold",
    //       fontFamily: "CenturyGothic",
    //       fontSize: "1rem",
    //     },
    //   },
    // },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            color: "white",
            backgroundColor: "#0E125E",
            padding: "0.5rem 1rem",
            whiteSpace: "nowrap",
          },
          "& .MuiTableCell-body": {
            color: "black",
            padding: "0.5rem 1rem",
            whiteSpace: "nowrap",
          },
        },
      },
      variants: [
        {
          props: {},
          style: {},
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          "& .MuiCardHeader-title": {
            fontSize: "16px",
          },
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: "secondary",
      },
    },
  },
};

const theme = createTheme(themeOptions);
export default theme;
