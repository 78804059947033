import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {
  bulkUpdateCertifiedTrainees,
  exportCertifiedTrainees,
  getCertifiedTrainees,
  uploadCertificate,
} from "api";
import Filters from "components/Filters";
import { useFullPageLoader } from "components/FullPageLoader";
import { UserProfileStore } from "layout/AppRouter";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import CertifiedTable from "./CertifiedTable";

const filters = {
  perPage: 100,
  page: 1,
  search: "",
  course: {
    id: 0,
    name: null,
    courseId: "",
    courseBatchNumber: null,
  },
  education: "",
  district: "",
  orderBy: "",
  order: "DESC",
};

interface props {
  batch?: string;
  distCode?: string;
  setDistrict?: any;
}

function CertifiedApplicantsTable(props: props) {
  const role = UserProfileStore.useState((s) => s.state.role);
  const [filter, setFilter] = useState(filters);
  const [toggleSort, setToggleSort] = useState(false);
  const { setLoading } = useFullPageLoader();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    [
      "certified-trainees",
      {
        ...filter,
        offset: (filter.page - 1) * filter.perPage ?? 500,
        limit: filter.perPage,
        district: props.distCode,
        batch: props.batch,
        course: filter?.course?.courseId,
      },
    ],
    getCertifiedTrainees
  );

  const { mutate } = useMutation(exportCertifiedTrainees, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "Certified Trainees.xlsx";
      link.click();
      toast.success("Exported successfully");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate: bulkUpdate } = useMutation(bulkUpdateCertifiedTrainees, {
    onSuccess: (res: any) => {
      toast.success("Imported successfully");
      queryClient.invalidateQueries("certified-trainees");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate: certificateUpload } = useMutation(uploadCertificate, {
    onSuccess: (res: any) => {
      toast.success("Uploaded successfully");
      queryClient.invalidateQueries("certified-trainees");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleExport = () => {
    setLoading(true);
    mutate({
      ...filter,
      course:
        (filter?.course?.courseId == "" ? "" : filter?.course?.courseId) ==
        undefined
          ? ""
          : filter?.course?.courseId,
      batch: props.batch,
      district: props.distCode,
    });
  };

  function handlePage(index: any) {
    setFilter((prev) => ({ ...prev, page: prev.page + index }));
  }

  const handleImportOnClick = async () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) return;
      const formData = new FormData();
      formData.append("file", file);
      setLoading(true);
      bulkUpdate(formData);
    };

    input.click();
  };

  const handlePageSize = (e: any) => {
    setFilter({ ...filter, perPage: e.target.value });
  };

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setFilter((prev: any) => ({ ...prev, page: value }));
  };

  const handleUpload = (formId: string) => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];

      if (!file) return;

      if (file?.type !== "application/pdf") {
        return toast.error("Only pdf files should be uploaded");
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("formId", formId);
      setLoading(true);
      certificateUpload(formData);
    };

    input.click();
  };

  return (
    <>
      <Filters
        filters={filter}
        setFilters={setFilter}
        distCode={props.distCode}
        setDistrict={props.setDistrict}
      />
      <div className="flex gap-2 items-center justify-end mb-2">
        {(role == "ADMINISTRATOR" ||
          role == "SUPER_ADMIN" ||
          role == "SME_ADMIN") && (
          <Button
            variant="contained"
            startIcon={<img src="/images/export_execl.svg" width="16px" />}
            style={{
              backgroundColor: "#F2F2F2",
              color: "#1C1C1C",
              margin: "0px 5px",
              padding: "5px 10px",
              fontSize: "14px",
            }}
            onClick={handleExport}
          >
            Export to Excel
          </Button>
        )}
        {(role == "ADMINISTRATOR" ||
          role == "SUPER_ADMIN" ||
          role == "SME_ADMIN") && (
          <Link
            underline="none"
            style={{
              margin: "0px 5px",
              padding: "0px",
            }}
            href={`/print-table?${new URLSearchParams({
              ...filter,
              course:
                (filter?.course?.courseId == ""
                  ? ""
                  : filter?.course?.courseId) == undefined
                  ? ""
                  : filter?.course?.courseId,
              batch: props.batch,
              district: props.distCode,
            } as any).toString()}&category=certifiedTrainees`}
            target={"_blank"}
          >
            <Button
              variant="contained"
              startIcon={<img src="/images/export_pdf.svg" width="16px" />}
              style={{
                backgroundColor: "#F2F2F2",
                color: "#1C1C1C",
                padding: "5px 10px",
                fontSize: "14px",
              }}
            >
              Export to Pdf
            </Button>
          </Link>
        )}

        {(role === "SUPER_ADMIN" ||
          role === "ADMINISTRATOR" ||
          "SME_ADMIN") && (
          <Link
            underline="none"
            style={{
              margin: "0px 5px",
              padding: "0px",
            }}
            href={`/certified/download-forms?${new URLSearchParams({
              ...filter,
              course:
                (filter?.course?.courseId == ""
                  ? ""
                  : filter?.course?.courseId) == undefined
                  ? ""
                  : filter?.course?.courseId,
              batch: props.batch,
              district: props.distCode,
            } as any).toString()}`}
            target={"_blank"}
          >
            <Button
              variant="contained"
              startIcon={<img src="/images/download.png" width="16px" />}
              style={{
                backgroundColor: "#F2F2F2",
                color: "#1C1C1C",
                padding: "5px 10px",
                fontSize: "14px",
              }}
            >
              Download Undertaking Forms
            </Button>
          </Link>
        )}
      </div>
      {isLoading && <CircularProgress />}
      {data && (
        <>
          <CertifiedTable
            columns={COLUMNS({
              handleUpload,
              showEdit: role === "SUPER_ADMIN",
            })}
            data={data?.data?.result || []}
            setFilter={setFilter}
            filters={filters}
            toggleSort={toggleSort}
            setToggleSort={setToggleSort}
          ></CertifiedTable>
          <Stack
            flexDirection={"row-reverse"}
            alignItems="center"
            marginTop={"1rem"}
            gap={1}
          >
            <Pagination
              count={Math.ceil(data?.data?.totalCount / filter.perPage)}
              page={filter.page}
              onChange={handlePagination}
            />
            <Typography>Page: {filter.page}</Typography>
            <FormControl size="small" sx={{ width: "100px" }}>
              <InputLabel id="demo-simple-select-label">Limit</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter.perPage}
                label="Limit"
                onChange={handlePageSize}
              >
                {[100, 250, 500, 1000, 2000, 3000, 4000, 5000].map((i: any) => (
                  <MenuItem value={i}>{i}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </>
      )}
    </>
  );
}

const COLUMNS = ({ handleUpload, showEdit }: any) => {
  return [
    {
      header: "Sr.No",
      accessor: "id",
    },
    {
      header: "BOCW ID",
      accessor: "bocRegNo",
      sortable: {
        sort: true,
        default: "ASC",
      },
    },
    {
      header: "Trainee Name",
      accessor: "name",
    },
    {
      header: "Mobile Number",
      accessor: "mobile",
    },
    {
      header: "Aadhar Number",
      accessor: "aadharNumber",
    },
    {
      header: "Education Qualification",
      accessor: "higherEducation",
    },
    {
      header: "Course Name",
      accessor: "course.name",
    },
    {
      header: "Setwin ID",
      accessor: "formId",
    },

    {
      header: "Min Course Duration Hours",
      accessor: "course.minimumCourseTime",
    },
    {
      header: "Course Start Date",
      accessor: "startDate",
    },

    {
      header: "Course End Date",
      accessor: "endDate",
    },
    {
      header: (
        <>
          <p>Actual Hours spent</p>
          <p>by the Trainee</p>
        </>
      ),
      accessor: "timeSpent",
      render: (data: any) => (
        <>
          {data?.timeSpent?.timeSpent
            ? (parseInt(data?.timeSpent?.timeSpent) / 3600).toFixed(2)
            : ""}
        </>
      ),
    },
    {
      header: "District",
      accessor: "district",
    },
    {
      header: "Certification Status",
      accessor: "certificateStatus",
      render: (data: any) => <>{data?.certificateStatus ? "Yes" : "No"}</>,
    },
    ...(showEdit
      ? [
          {
            header: "Upload Certificate",
            accessor: "something",
            render: (row: any) => (
              <Button
                onClick={() => handleUpload(row?.formId)}
                color="secondary"
              >
                Upload
              </Button>
            ),
          },
        ]
      : []),
    {
      header: "Download Certificate",
      accessor: "certificateKey",
      render: (value: any) =>
        value?.certificateStatus ? (
          <Button color="secondary">
            <a target="_blank" download href={value?.certificateKey}>
              Download Certificate
            </a>
          </Button>
        ) : (
          <></>
        ),
    },
  ];
};

export default CertifiedApplicantsTable;
