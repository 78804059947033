import { Add } from "@mui/icons-material";
import { Box, Fab, IconButton, Typography } from "@mui/material";
import { getStoragefolder, getTraineeStorage } from "api";
import Loader from "components/Loader";
import Adddistricts from "pages/certificateupload/Adddistricts";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FoldersTraineeStorage } from "./TraineeStorage";
import Addcategories from "./Addcategories";

function TraineeFollowupCategory() {
  const navigate = useNavigate();
  const [filters, setFilters] = useState("");

  const [open, setOpen] = useState(false);

  const { id } = useParams();

  const { data, isLoading } = useQuery(
    ["traineefollowupstorage", id],
    getTraineeStorage
  );

  const handlenavBack = () => {
    navigate(-1);
  };

  const handlenavBackHome = () => {
    navigate(-2);
  };

  if (isLoading) return <Loader />;

  return (
    <Box
      sx={{
        height: "90vh",
      }}
    >
      <Fab
        size="medium"
        color="secondary"
        sx={{ position: "fixed", bottom: 30, right: 30, borderRadius: "8px" }}
        aria-label="add"
      >
        <Box
          sx={{
            borderRadius: "8px",
            backgroundColor: "#2d5696",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => setOpen(true)} size="large">
            <Add sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
      </Fab>
      <Box mt={4} display="flex">
        <Typography
          sx={{
            cursor: "pointer",
          }}
          onClick={handlenavBackHome}
        >
          Home
        </Typography>{" "}
        <Typography m="0px 5px">/</Typography>
        <Typography
          sx={{
            cursor: "pointer",
          }}
          onClick={handlenavBack}
        >
          {data?.data?.breadCrumbs[0]?.name}
        </Typography>
        <Typography m="0px 5px">/</Typography>
        <Typography
          sx={{
            cursor: "pointer",
          }}
        >
          {data?.data?.breadCrumbs[1]?.name}
        </Typography>
      </Box>
      <Box>{isLoading && <Loader />}</Box>
      {data?.data?.result?.length > 0 ? (
        <Box>
          <FoldersTraineeStorage category={true} data={data?.data?.result} />
        </Box>
      ) : (
        <Box
          mt={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="80vh"
          alignItems="center"
        >
          <img src="/images/workshopempty.png" width="200px" height="200px" />
          <Typography mt={2}>No TraineeFollowup Caregories Found</Typography>
        </Box>
      )}

      <Addcategories open={open} setOpen={setOpen} />
    </Box>
  );
}

export default TraineeFollowupCategory;
