import { styled } from "@mui/material";

export const StyledTable = styled("table")(({ theme }) => ({
  width: "100%",
  border: "1px solid #2d5696",
  background: "#FFFFFF",
  fontFamily: theme.typography.fontFamily,
  position: "relative",
  minHeight: 300,

  "& th": {
    fontSize: 15,
    background: "#2d5696",
    color: "white",
    fontWeight: 700,
  },
  "& td, & th": {
    textAlign: "center",
    padding: "0 0.5rem",
    fontSize: 14,
    border: "1px solid #2d5696",
  },
  "& tbody tr:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "& section": {
    position: "absolute",
    top: "50%",
    left: "40%",
  },
}));

export const StyledTableLoader = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  background: "rgba(255,255,255, 0.4)",
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
