import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.css";
import theme from "./styles/theme";

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);
