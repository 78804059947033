import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { getDistricts } from "api";
import { useState } from "react";
import { useQuery } from "react-query";
import http from "utils/http";

interface Props {
  district?: string;
  batch?: any;
  setBatch?: any;
  onChange?: (v: string) => void;
  showDistrictFilter?: boolean;
  handleChange?: any;
}

function Header({
  district,
  onChange,
  showDistrictFilter = false,
  batch,
  setBatch,
  handleChange,
}: Props) {
  const [filter, setFilters] = useState<any>();
  const { data, isLoading } = useQuery("districts", getDistricts);

  const { data: batchs, isLoading: batchLdg } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const Currentbatch = batchs?.find((item: any) => item?.currentBatch == true);

  if (isLoading || batchLdg)
    return (
      <Box pt={4} m="auto">
        <CircularProgress></CircularProgress>
      </Box>
    );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      pt={4}
      pb={2}
      alignItems="center"
      sx={{
        "@media (max-width:900px)": {
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          "& .MuiTypography-root": { color: "#0E125E !important" },
        }}
      >
        <Typography
          variant="h6"
          color="primary"
          sx={{
            fontSize: "18px",
            fontWeight: "700",
            "@media (max-width:900px)": {
              fontSize: "14px",
              fontWeight: "700",
            },
          }}
        >
          SETWIN Skill Upgradation Training to TBOCWWB Workers and their
          Dependents
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          "@media (max-width:900px)": {
            mt: "10px",
            width: "100%",
            justifyContent: "flex-end",
          },
        }}
      >
        <Box>
          <TextField
            onChange={handleChange}
            select
            value={batch}
            label={batch ? "Select Batch" : Currentbatch?.batch!}
            size="small"
            sx={{
              minWidth: 200,
              marginRight: 1,
              "@media (max-width:600px)": {
                minWidth: 125,
              },
            }}
          >
            <MenuItem value={"ALL"}>All</MenuItem>
            {batchs?.map((item: any, index: number) => (
              <MenuItem key={index} value={item?.batch}>
                {item?.batch}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box
          minWidth="250px"
          sx={{
            "@media (max-width:900px)": {
              justifyContent: "flex-end",
            },
            "@media (max-width:600px)": {
              minWidth: 155,
            },
          }}
        >
          {showDistrictFilter && (
            <Autocomplete
              value={data?.data?.find((i: any) => i?.id == district) || ""}
              options={data?.data || ""}
              getOptionLabel={(option: any) => option?.name || ""}
              onChange={(event, value: any) => {
                setFilters(value);
                onChange && onChange(value?.id);
              }}
              isOptionEqualToValue={(option, value) => {
                return option?.id == value;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="All Districts"
                />
              )}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
