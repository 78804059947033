import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Box, Button, Stack, Typography } from '@mui/material';
import { getOneFeedbackDetails } from 'api';
import Heading from 'components/Heading';
import { StyledFeedBackComponent, StyledFeedbackStackComponent } from 'layout/styles';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const ViewComponent = () => {
  const navigate=useNavigate()
  const handleBackClick = () => navigate(-1);
  let { id } = useParams();

  const {data,isLoading}=useQuery(['get-one-feedback',id],getOneFeedbackDetails) 

  const getDate=(timestamp:any)=>{
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); 
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;

  }
  const getTime=(timestamp:any)=>{
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const milliseconds = date.getMilliseconds().toString().padStart(3, "0");

    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; 

    return `${hours}:${minutes} ${period}`;

  }

  const sections = [
    {
      title: 'PARTICIPANT DEMOGRAPHICS',
      fields: [
        { label: 'Mobile Number', value: data?.data?.form?.mobile },
        { label: 'Full Name Of The participant', value: data?.data?.form?.name },
        { label: 'SETWIN Training ID', value: data?.data?.form?.formId },
        { label: 'District', value: data?.data?.form?.district },
        { label: 'Age Of The Participant', value: data?.data?.age },
        { label: 'Gender', value: data?.data?.gender },
        { label: 'Employment Status Prior To Training', value: data?.data?.priorEmployment },
        { label: 'Current Employment Status', value: data?.data?.currentEmploymentStatus },
        { label: 'Social Group', value: data?.data?.socialGroup },
        { label: 'Is Your Parents/father A Construction worker?', value: data?.data?.isConstructionWorker ? "Yes" : "No" },
      ],
    },
    {
      title: 'PRE-TRAINING INFORMATION',
      fields: [
        { label: 'How Did You Hear About The Training Program?', value:data?.data?.source },
        { label: 'Was This your First Skill Training Program?', value: data?.data?.isFirstTraining ? "Yes" : "No" },
      ],
    },
    {
      title: 'TRAINING DETAILS',
      fields: [
        { label: 'Name Of The Training Course Attended', value: data?.data?.form?.course?.name  },
        { label: 'Duration Of The Course', value: `${data?.data?.form?.course?.duration } Months` },
        { label: 'Quality Of Training Materials Provided', value: data?.data?.qualityOfTraining },
        { label: 'Relevance Of The Training Content To Your Expected Job Role', value: data?.data?.relevanceOfTraining },
        { label: 'Instructors Teaching Method Effectiveness', value: data?.data?.instructorEffectiveness },
        { label: 'Assessment Methods Used During The Training', value: data?.data?.assessmentMethod },
      ],
    },
    {
      title: 'TRAINING EVALUATION',
      fields: [
        { label: 'Certification Received', value: data?.data?.isCertificationReceived ? "Yes" : "No" },
        { label: 'How Did You Feel Immediately After Completing Training', value: data?.data?.postTrainingConfidence },
        { label: 'Opportunities For Career Advancement Post- Training', value: data?.data?.careerOpportunities },
        { label: 'Additional Training Or Skills Needed', value: data?.data?.additionalTrainingRequired },
        { label: 'Recommendations For Others Considering Similar Training', value: data?.data?.recommendation },
        { label: 'Overall Opinion About The Programme', value: data?.data?.overallOpinion },
        { label: 'Form Submitted On The Date', value: getDate(data?.data?.createdAt) },
        { label: 'Form Submitted On The Time', value: getTime(data?.data?.createdAt) },
      ],
    },
  ];

  return (
    <>
      <Box sx={{ paddingTop: "2%" }}>
        <Button
        className='print-hide'
          variant='outlined'
          sx={{ width: "15%" }}
          startIcon={<ArrowBackOutlinedIcon />}
          onClick={handleBackClick}
        >
          Back
        </Button>
      </Box>
      <StyledFeedBackComponent>
        <Typography
          fontWeight='600'
          sx={{ marginTop: { xs: "15px", md: "30px", lg: "40px" }, fontSize: { xs: "18px", md: "28px" },color:"#1E4B8F" }}
        >
          SETWIN Online Training Feedback
        </Typography>
        {sections.map((section) => (
          <Stack key={section.title}>
            <Heading value={section.title} />
            {section.fields.map((field, index) => (
              index % 2 === 0 && (
                <StyledFeedbackStackComponent key={field.label}>
                  <PreviewComponent label={section.fields[index].label} value={section.fields[index].value} />
                  {section.fields[index + 1] && (
                    <PreviewComponent label={section.fields[index + 1].label} value={section.fields[index + 1].value} />
                  )}
                </StyledFeedbackStackComponent>
              )
            ))}
          </Stack>
        ))}
        <Box>
          <Button onClick={()=>{window.print()}} className='print-hide' variant='outlined' sx={{fontWeight:"600",border:"2px solid #1E4B8F"}}>Download Form</Button>
        </Box>
        
        
      </StyledFeedBackComponent>
    </>
  );
};

export default ViewComponent;

const PreviewComponent = ({label,value}:any) => {
  return (
    <Box sx={{ gap:"0px", width: { xs: '90%', sm: '60%', md: '40%' } }}>
      <Typography sx={{ my: '5px',fontSize:"16px",marginTop: '15px',fontWeight:"500",opacity:"60%" }}>{label}</Typography>
      <Typography  sx={{color:"#020202",opacity:"100%",fontWeight:"600",fontSize:"18px"}}>{value}</Typography>
    </Box>
  )
}
