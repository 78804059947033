import { Box, Button, CircularProgress, Link } from "@mui/material";
import { bulkUpdateCertifiedTrainees, bulkUpdateFeedbackData, exportFeedbackData, getFeedbacks } from "api";
import { useFullPageLoader } from "components/FullPageLoader";
import ReactTable from "components/ReactTable";
import { UserProfileStore } from "layout/AppRouter";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import Filters from "components/Filters";
import Actions from "../../components/Actions";

const filters = {
  perPage: 500,
  page: 1,
  search: "",
  // course: {
  //   id: 0,
  //   name: null,
  //   courseId: "",
  //   courseBatchNumber: null,
  // },
  courseId:"",
  education: "",
  district: "",
};

interface Props {
  batch?: string;
  distCode?: string;
  setDistrict?: any;
}

function Table(props: Props) {
  const [filter, setFilter] = useState(filters);
  const role = UserProfileStore.useState((s) => s.state.role);
  const { setLoading } = useFullPageLoader();
  const queryClient = useQueryClient();
  const [exportfeed,setExportfeed]=useState(false);


  const {data:feedbackData,isLoading:isFeedbackLoading}=useQuery(
    [
      "feedback-usersdata",
      {
        search:filter.search,
        batch:props.batch,
        districtId:props.distCode,
        education:filter.education,
        courseId:filter.courseId,
        limit:filter.perPage,
        offset:(filter.page - 1) * filter.perPage,
      }
    ],
    getFeedbacks,
  )
  console.log("Feedback data",feedbackData);

  const { data:exportFeedback } = useQuery([
    "export-feedback-data",
    {
      search:filter.search,
      batch:props.batch,
      districtId:props.distCode,
      education:filter.education,
      courseId:filter.courseId,
      limit:filter.perPage,
      offset:(filter.page - 1) * filter.perPage,
    }
  ],
  exportFeedbackData, 
  {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res?.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "Feedback Data.xlsx";
      link.click();
      toast.success("Exported successfully");
      setExportfeed(false);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
      setExportfeed(false);
    },
    onSettled: () => {
      setLoading(false);
    },
    enabled:exportfeed,
  });

  const {mutate:bulkUpdate}=useMutation(bulkUpdateFeedbackData,{
    onSuccess: (res: any) => {
      
      toast.success("Imported successfully");
      queryClient.invalidateQueries("feedback-usersdata");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
      setLoading(false);
    },
    onSettled: () => {
      setLoading(false);
    },
  })

  const handleImportOnClick = async () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) return;
      const formData = new FormData();
      formData.append("file", file);
      setLoading(true);
      bulkUpdate(formData);
    };

    input.click();
  };

  const handleExport = () => {
    setLoading(true);
    setExportfeed(true)
    queryClient.invalidateQueries("export-feedback-data");
  };

  function handlePage(index: any) {
    setFilter((prev) => ({ ...prev, page: prev.page + index }));
  }

  return (
    <>
      <Filters
        filters={filter}
        setFilters={setFilter}
        distCode={props.distCode}
        setDistrict={props.setDistrict}
      />
      <div className="flex gap-2 items-center justify-end">
        {(role === "SUPER_ADMIN" || role==='FEEDBACK_ADMIN') && (
            <Button
              variant="contained"
              startIcon={<img src="/images/bulkupload.svg" width="16px" />}
              style={{
                backgroundColor: "#F2F2F2",
                color: "#1C1C1C",
                marginBottom: "5px",
                padding: "5px 10px",
                fontSize: "14px",
              }}
              onClick={handleImportOnClick}>
              Bulk Update
            </Button>
          )}
        {(role === "ADMINISTRATOR" ||
          role === "SUPER_ADMIN" ||
          role === "SME_ADMIN" || role==='FEEDBACK_ADMIN') && (
          <Button
            variant="contained"
            startIcon={<img src="/images/export_execl.svg" width="16px" />}
            style={{
              backgroundColor: "#F2F2F2",
              color: "#1C1C1C",
              margin: "0px 10px",
              padding: "5px 10px",
              fontSize: "14px",
            }}
            onClick={handleExport}
          >
            Export to Excel
          </Button>
        )}
        {(role === "ADMINISTRATOR" ||
          role === "SUPER_ADMIN" ||
          role === "SME_ADMIN"|| role==='FEEDBACK_ADMIN' ) && (
          <Link
            underline="none"
            style={{
              margin: "0px 5px",
              padding: "0px",
            }}
            href={`/feedback/download-forms?${new URLSearchParams({
              ...filter,
              courseId:
                (filter?.courseId == ""
                  ? ""
                  : filter?.courseId) == undefined
                  ? ""
                  : filter?.courseId,
              district: props?.distCode,
              batch: props?.batch,
            } as any).toString()}&category=feedbackData`}
            target={"_blank"}
          >
            <Button
              variant="contained"
              startIcon={<img src="/images/download.png" width="16px" />}
              style={{
                backgroundColor: "#F2F2F2",
                color: "#1C1C1C",
                padding: "5px 10px",
                fontSize: "14px",
              }}
            >
              Download Feedback Forms
            </Button>
          </Link>
        )}
      </div>
      <Box ></Box>
      {isFeedbackLoading && <CircularProgress />}
      {feedbackData && (
        <ReactTable
          columns={COLUMNS}
          data={feedbackData?.data?.data}
          handleButton={handlePage}
          page={filter.page}
          setFilter={setFilter}
          pageCount={Math.ceil(
            feedbackData?.data?.count / filter.perPage
          )}
          perPage={filter.perPage}
          bgHeaderColor="#20a87d"
          pageLimit={[500,1000,2000,5000,10000,15000]}
        />
      )}
    </>
  );
}

const COLUMNS = [
  {
    header: "Sr.No",
    accessor: "id",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "BOCW ID",
    accessor: "form.bocRegNo",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Trainee Name",
    accessor: "form.name",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Mobile Number",
    accessor: "form.mobile",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Setwin ID",
    accessor: "form.formId",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Course Name",
    accessor: "form.course",
    Cell: ({ value }: { value: any }) => value?.name ?? "",
  },
  {
    header: "Actions",
    accessor: "form.id", 
    Cell: ({ row }: { row: any }) => <Actions row={row} />,
  }
];

export default Table;
