import { Autocomplete, FormControl, FormHelperText, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const AutoComplete = ({ control, years, placeholder, name, label, defaultValue }: any) => {
  return (
    <FormControl sx={{ gap: '0px' }} fullWidth>
      <Stack direction={'row'} gap={0.5} alignItems={'center'}>
        <FormLabel
          sx={{
            my: '5px',
            fontSize: '16px',
            marginTop: '15px',
            fontWeight: '500',
            opacity: '80%'
          }}
        >
          {label}
        </FormLabel>
        
      </Stack>

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue} // Set default value from props
        render={({ field, fieldState }) => (
          <>
            <Autocomplete
              defaultValue={years.find((option: any) => option.label === defaultValue) || null}
              value={years.find((option: any) => option.label === field.value) || null}
              onChange={(_, newValue) => field.onChange(newValue ? newValue.label : '')}
              disablePortal
              id="combo-box-demo"
              options={years}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.label === value?.label}
              sx={{
                borderRadius: '10px',
                color: '#222222',
                opacity: '1',
                fontWeight: '400',
                fontSize: '16px'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      borderRadius: '10px',
                      color: '#222222',
                      opacity: '1',
                      fontWeight: '400',
                      fontSize: '16px'
                    }
                  }}
                  error={!!fieldState.error}
                />
              )}
            />
            {fieldState.error && (
              <FormHelperText sx={{ mt: 1, mb: 2 }} error>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default AutoComplete;
