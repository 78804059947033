import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import http from "utils/http";
import AssignDistricts from "./AssignDistricts";
import CreateUser from "./CreateUser";
import UserDetailsEdit from "./UserDetailsEdit";

function AdminSettings() {
  const {
    data,
    refetch: refetchUsers,
    isLoading,
  } = useQuery("users-data", () => http.get("/user").then((res) => res.data));

  if (isLoading) {
    return (
      <Box p={2}>
        <CircularProgress></CircularProgress>
      </Box>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "2rem 0 1rem",
        }}>
        <Typography sx={{ fontWeight: 700 }}>Users</Typography>
        <CreateUser refetchUsers={refetchUsers} />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ "& .MuiTableCell-head": { fontWeight: 700 } }}>
              <TableCell>Name</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Assign Districts</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.users.map((row: any) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.mobile}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>
                  <UserDetailsEdit id={row.id} refetchUsers={refetchUsers} />
                </TableCell>
                <TableCell>
                  <AssignDistricts id={row.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AdminSettings;
