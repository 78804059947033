import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { Box } from "@mui/material";
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);


function BarChart2d({key,title,data}:any) {
    const chartData=data?.map(({ key, count }: any) => ({
      label: key,
      value: count,
    }))
    
    const chartConfigs = {
      type: "column2d", 
      width: "100%", 
      height: "400", 
      dataFormat: "json", 
      dataSource: {
        chart: {
          caption: `${title}`,
          xAxisName: `${title}`,
          yAxisName: "Number of Participants",
          captionAlignment: "left",
          captionOnTop: "1",
          captionHorizontalPadding: "10",
          legendposition: "right",
        //   legendWidth: "250",
        //   legendAlign: "start",
        //   legendVerticalAlign: "start",
        //   numberSuffix: "K",
          theme: "fusion"
        },
        data: chartData
      }
    };
    return (
        <Box
            sx={{
            width: "100%",
            height: "450px",
            borderRadius: "20px",
            marginBottom: "20px",
            boxShadow: "0px 6px 16px #0000001A",
            "& .fusioncharts-container": {
                width: "100%",
                height: "100%",
            },
            }}
        >
        <ReactFC {...chartConfigs} />
    </Box>
    );
}

export default BarChart2d;