import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IProps {
  label: string;
}

const GoBackButton = ({ label }: IProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div className="flex items-center gap-3 font-semibold text-lg  text-primary">
      <Button
        startIcon={<ArrowBackIosIcon />}
        size="large"
        className="text-primary flex"
        onClick={handleClick}>
        {label}
      </Button>
    </div>
  );
};

export default GoBackButton;
