import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { getDistricts } from "api";
import Loader from "components/Loader";
import Layout from "layout/Layout";
import { AlertDialog } from "pages/traineefollow-up/TraineeComponents";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import http from "utils/http";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

interface Idist {
  id: number;
  name: string;
  code: string;
  orgId: number;
}

function ViewDistrictWorkshop() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [unit, setUnit] = useState(0);
  const [url, setUrl] = useState("");
  const { id } = useParams();
  const [title, setTitle] = useState(null);
  const [category, setCategory] = useState("");
  const [district, setDistrict] = useState<Idist>();
  const [customData, setCustomData] = useState([]);

  const handlePrev = () => {
    setUnit(unit - 1);
  };

  const handleNext = () => {
    setUnit(unit + 1);
  };

  const { data, isLoading } = useQuery(
    ["workshopbyid", title],
    () => http.get(`/workshop/${title}`).then((res) => res.data),
    {
      enabled: !!title,
    }
  );

  const principal = data?.media?.filter(
    (item: any) => item?.type === "PRINCIPAL_SPEECH"
  );

  const feedbackArry = data?.media?.filter(
    (item: any) => item.type === "TRAINEE_FEEDBACK"
  );

  const newcoverageArry = data?.media?.filter(
    (item: any) => item.type === "NEWSPAPER_COVERAGE"
  );
  const workshopArry = data?.media?.filter(
    (item: any) => item.type === "WORKSHOP"
  );

  const workshopmainArray = data?.media?.filter(
    (item: any) => item.type == "WORKSHOP_MAIN"
  );

  const style = {
    "@media (max-width: 1200px)": {
      display: "none",
    },
  };

  const handleimagePreview = (url: any) => {
    setUrl(url);
    setOpen(true);
  };

  const handleClick = () => {
    navigate(-1);
  };
  const [batch, setBatch] = useState("");

  const handleChange = (e: any) => {
    setBatch(e.target.value);
  };

  const { data: batchs, isLoading: batchLdg } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const { data: DistrictData, isLoading: districtsLoding } = useQuery(
    "districts",
    getDistricts
  );

  const { data: Categories, isLoading: CategoriesLdg } = useQuery(
    "workshopcategory",
    () => http.get("/workshop/category").then((res) => res.data)
  );

  const { data: districtwiseData, isLoading: districtLoding } = useQuery(
    ["workshopDistrictwise", district, category, batch],
    () =>
      http
        .get(
          `/workshop/district-wise?batch=${batch}&districtId=${district?.id}&category=${category}`
        )
        .then((res) => res.data)
  );

  const [loaderState, setloaderState] = useState(false);

  useEffect(() => {
    if (districtLoding) {
      setloaderState(districtLoding);
    } else {
      setloaderState(false);
    }
    if (districtwiseData) {
      setTitle(districtwiseData[0]?.id);
      setCustomData(districtwiseData);
    }
  }, [districtLoding]);

  useEffect(() => {
    if (isLoading) {
      setloaderState(isLoading);
    } else {
      setloaderState(false);
    }
  }, [isLoading]);

  if (isLoading) return <Loader />;

  return (
    <Box>
      <Layout header="true" />
      <Box style={{ backgroundImage: "url(/images/workshop-background.png)" }}>
        <Box
          display="flex"
          justifyContent="flex-end"
          pt={3}
          mr={2}
          gap={2}
          mb={2}
        >
          <Box>
            <TextField
              onChange={handleChange}
              select
              value={batch}
              label={"Select Batch"}
              size="small"
              sx={{
                maxWidth: "300px",
                minWidth: "200px",
                bgcolor: "#FFF",
                borderRadius: "5px",
              }}
            >
              <MenuItem value={""}>All</MenuItem>
              {batchs?.map((item: any, index: number) => (
                <MenuItem key={index} value={item?.batch}>
                  {item?.batch}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box minWidth="200px" maxWidth="250px">
            <Autocomplete
              sx={{
                bgcolor: "#FFF",
                borderRadius: "5px",
              }}
              fullWidth
              value={district}
              options={DistrictData?.data || []}
              getOptionLabel={(option: any) => option?.name || ""}
              filterSelectedOptions
              onChange={(event, value: any) => {
                setDistrict(value);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" label="District" />
              )}
            />
          </Box>
          <Box>
            <TextField
              sx={{
                maxWidth: "250px",
                minWidth: "200px",
                bgcolor: "#FFF",
                borderRadius: "5px",
              }}
              select
              value={category}
              label="Select Category"
              size="small"
              onChange={(e: any) => setCategory(e.target.value)}
            >
              {Categories?.map((item: any, index: number) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <TextField
              sx={{
                maxWidth: "300px",
                minWidth: "200px",
                bgcolor: "#FFF",
                borderRadius: "5px",
              }}
              select
              value={title}
              label={title ? "" : "Select Workshop"}
              size="small"
              onChange={(e: any) => setTitle(e.target.value)}
            >
              {customData?.map((item: any, index: number) => (
                <MenuItem key={index} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <Box p={2} sx={{ m: 0 }}>
          <Box display="flex" justifyContent="space-between">
            <Button
              startIcon={<ArrowBackIosIcon />}
              size="large"
              onClick={handleClick}
              style={{ fontWeight: "600" }}
            >
              View Workshop
            </Button>
          </Box>

          <Grid
            container
            p="24px 24px"
            columnSpacing={{ xs: "24px", sm: "24px", md: "24px" }}
          >
            <Grid item xs={8}>
              <Box
                sx={{
                  backgroundImage: `url(${"/images/laptop.png"})`,
                  backgroundSize: "100% 550px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "600px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {workshopmainArray?.length > 0 ? (
                  <Swiper
                    autoplay={{
                      delay: 1500,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                    style={{
                      width: "78.6%",
                      height: "86%",
                      borderRadius: "10px",
                      paddingTop: "50px",
                    }}
                  >
                    {workshopmainArray?.map((i: any) =>
                      i.subtype == "IMAGE" ? (
                        <SwiperSlide>
                          <img
                            src={i?.url}
                            alt=""
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "10px",
                            }}
                          />
                        </SwiperSlide>
                      ) : i?.url.includes("youtube.com") ? (
                        <SwiperSlide>
                          <iframe
                            src={(i?.url).replace("watch?v=", "embed/")}
                            style={{
                              borderRadius: "10px",
                              width: "100%",
                              height: "100%",
                            }}
                          ></iframe>
                        </SwiperSlide>
                      ) : i?.url.includes("vimeo.com") ? (
                        <SwiperSlide>
                          <iframe
                            src={i?.url}
                            style={{
                              borderRadius: "10px",
                              width: "100%",
                              height: "100%",
                            }}
                            allow="autoplay; fullscreen; picture-in-picture"
                          ></iframe>
                        </SwiperSlide>
                      ) : (
                        <SwiperSlide>
                          <video
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "fill",
                              borderRadius: "10px",
                              margin: "15px 15px",
                            }}
                            controls
                          >
                            <source src={i?.url} type="video/mp4" />
                          </video>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                ) : (
                  <Card
                    sx={{
                      backgroundColor: "#F2F2F2",
                      borderRadius: "10px",
                      width: "78%",
                      height: "78%",
                      marginTop: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <CardMedia
                        sx={{ height: 200, width: "200px" }}
                        image="/images/workshopempty.png"
                        title="green iguana"
                      />
                      <Typography
                        style={{
                          color: "red",
                          marginTop: "15px",
                          marginLeft: "45px",
                        }}
                      >
                        No Records Found
                      </Typography>
                    </Box>
                  </Card>
                )}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box
                sx={{
                  backgroundImage: `url(${"/images/tab.png"})`,
                  backgroundSize: "100% 550px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "600px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "45px",
                    width: "92%",
                  }}
                >
                  {workshopArry?.length > 0 ? (
                    <Swiper
                      spaceBetween={30}
                      slidesPerView={1.5}
                      centeredSlides={true}
                      style={{
                        height: "480px",
                        backgroundColor: "#FFF",
                      }}
                      speed={2500}
                      direction="vertical"
                      loop
                      autoplay={{
                        delay: 0.1,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay, Pagination]}
                    >
                      {workshopArry?.map((item: any) => (
                        <SwiperSlide>
                          <Box onClick={() => handleimagePreview(item?.url)}>
                            <img
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                objectFit: "cover",
                              }}
                              src={item?.url}
                            />
                          </Box>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <Box
                      sx={{
                        bgcolor: "#FFF",
                        display: "flex",
                        height: "450px",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginTop: "30px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CardMedia
                          sx={{ height: 200, width: "200px" }}
                          image="/images/workshopempty.png"
                          title="green iguana"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography style={{ color: "red", marginTop: "10px" }}>
                          No Records Found
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {principal?.length > 0 && (
        <TitleCard
          title="Nodal Officer / ALO /Principal Speech"
          color="#2D5696"
        />
      )}
      <SectionCard
        dataArray={principal}
        title="Nodal Officer / ALO /Principal Speech"
        handleimagePreview={handleimagePreview}
      />
      {feedbackArry?.length > 0 && (
        <TitleCard title="Trainees Feedabck" color="#2D5696" />
      )}
      <SectionCard
        title="Trainees Feedabck"
        dataArray={feedbackArry}
        handleimagePreview={handleimagePreview}
      />
      {newcoverageArry?.length > 0 && (
        <TitleCard title="News paper Coverage" color="#2D5696" />
      )}
      <SectionCard
        title="News paper Coverage"
        dataArray={newcoverageArry}
        handleimagePreview={handleimagePreview}
      />
      <AlertDialog open={open} setOpen={setOpen} url={url} />
    </Box>
  );
}

const TitleCard = ({ title, color }: any) => {
  return (
    <Box mt={2} ml={2} maxWidth="400px">
      <Box display="flex" justifyContent="space-between">
        <Typography fontWeight="600">{title}</Typography>
      </Box>

      <Box
        sx={{
          mt: "2px",
          bgcolor: ` ${color}`,
          height: "6px",
          width: "40px",
          borderRadius: "5px",
        }}
      ></Box>
    </Box>
  );
};

const SectionCard = ({ dataArray, title, handleimagePreview }: any) => {
  return (
    <Box display="flex" ml={2}>
      {dataArray?.length > 0 &&
        dataArray.map((item: any) => (
          <Box minWidth="350px" minHeight="200px" mt="15px" m={1} p={1}>
            <Card sx={{ maxWidth: 380 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "350px",
                  height: "200px",
                }}
              >
                {item?.subtype === "VIDEO" &&
                  (item?.url?.includes("youtube.com") ? (
                    <iframe
                      title="youtube"
                      width="380px"
                      height="200px"
                      style={{ borderRadius: "10px" }}
                      src={item?.url.replace("watch?v=", "embed/")}
                    ></iframe>
                  ) : item?.url?.includes("vimeo.com") ? (
                    <iframe
                      title="viemo"
                      src={item?.url}
                      width="380px"
                      height="200px"
                      style={{ borderRadius: "10px" }}
                      allow="autoplay; fullscreen; picture-in-picture"
                    ></iframe>
                  ) : (
                    <video width="380px" height="120px" controls>
                      <source src={item?.url} type="video/mp4" />
                    </video>
                  ))}
                {item?.subtype === "IMAGE" && (
                  <Box onClick={() => handleimagePreview(item?.url)}>
                    <img
                      src={item?.url}
                      object-fit="fill"
                      alt="tarinee img"
                      style={{ width: "380px", height: "200px" }}
                    />
                  </Box>
                )}
              </Box>
              <Typography m="10px 0px 10px 6px">{title}</Typography>
            </Card>
          </Box>
        ))}
    </Box>
  );
};

export default ViewDistrictWorkshop;
