import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { fetchApplications } from "..";
import "./pdf-print.css";

export const parseParams = (querystring: string) => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj: any = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

export default function PrintPdfPage() {
  const location = useLocation();
  const params = parseParams(location.search);

  const { isLoading, data } = useQuery(["applications"], () =>
    fetchApplications({
      ...params,
      district:
        params.district == "undefined" || undefined ? "" : params?.district,
      course: params.courseId,
      offset: (+params.page - 1) * +params.limit,
    })
  );

  if (isLoading) return <div className="p-5">Loading...</div>;
  return (
    <Box>
      {data?.result?.map((item: any, index: number) => (
        <PdfTemplate data={item} key={index} />
      ))}
    </Box>
  );
}

const PdfTemplate = ({ data }: { data: any }) => {
  if (!data) return null;
  return (
    <>
      <div className="pdf-template">
        <header>
          <div className="title">
            <h1>
              Telangana Building And Other Construction Workers Welfare Board
            </h1>
            <h2>
              SETWIN Application form for Online Skill Upgradation Training
              Programme
              <br />
              for Registered BOC Workers and their Dependents
            </h2>
          </div>
          <div className="passport-photo">
            <img
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              src={data?.applicantPhotoUrl}
              alt="passport size photo"
            />
          </div>
        </header>
        <main className="page">
          <div className="field-section">
            <section className="details">
              <ol>
                <div className="form-control">
                  <li>S.no</li>
                  <p>{data?.id}</p>
                </div>
                <div className="form-control">
                  <li>Batch Number</li>
                  <p>{data?.batchNumber}</p>
                </div>
                <div className="form-control">
                  <li>Name of Worker</li>
                  <p>{data?.workerName}</p>
                </div>
                <div className="form-control">
                  <li>BOCW CARD Number</li>
                  <p>{data?.bocRegNo}</p>
                </div>
                <div className="form-control">
                  <li>Name of Worker/Dependent</li>
                  <p>{data?.name}</p>
                </div>
                <div className="form-control">
                  <li>Dependent Mother Name</li>
                  <p>{data?.motherName}</p>
                </div>
                <div className="form-control">
                  <li>Education Qualification</li>
                  <p>{data?.education}</p>
                </div>
                <div className="form-control">
                  <li>Course Selected</li>
                  <p>
                    Course {data?.courseName?.courseId} -{" "}
                    {data?.courseName?.name}
                  </p>
                </div>
                <div className="form-control">
                  <li>Mobile Number of Dependent</li>
                  <p>{data?.mobile}</p>
                </div>
                <div className="form-control">
                  <li>District</li>
                  <p>{data?.district?.name}</p>
                </div>
                <div className="form-control">
                  <li>ID generated by SETWIN</li>
                  <p>{data?.setwinId}</p>
                </div>
                <div className="form-control">
                  <li>Trainee Aadhar Number</li>
                  <p>{data?.aadharNumber}</p>
                </div>
              </ol>
            </section>
          </div>

          <div className="aadhar-section">
            <img
              src={data?.aadharFileUrl}
              alt="Aadhar Card"
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
            />
          </div>
        </main>
        <footer>
          <div className="signs">
            <div className="candidate-sign">
              <img
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
                src={data.signUrl}
                alt="Signature of candidate"
              />
              <p style={{ textAlign: "center", paddingTop: "10px" }}>
                Signature of the Candidate
              </p>
            </div>
            <div className="alo-sign">
              <div
                style={{
                  height: "100px",
                  width: "200px",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                }}></div>
              <p style={{ textAlign: "center", paddingTop: "10px" }}>
                Signature of ALO
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
