import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export default function SearchField({
  onSearch,
  onClear,
  title,
}: {
  onSearch: (v: any) => void;
  onClear: () => void;
  title?: string;
}) {
  const [search, setSearch] = useState("");

  const searchHandler = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (!search.length) onClear();
  }, [search]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSearch(search);
      }}
      className="flex gap-2 items-center">
      <TextField
        fullWidth
        size="small"
        placeholder={title ? title : "Search"}
        value={search}
        onChange={searchHandler}
      />
      <Button type="submit" variant="outlined" size="small" color="primary">
        Submit
      </Button>
    </form>
  );
}
