import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Typography,
} from "@mui/material";
import { getCourses } from "api";
import SearchField from "components/SearchField";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import AddCourses from "./add-course";
import EditCourses from "./edit-courses";

function Courses() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");

  const { data, isLoading } = useQuery(
    ["district-course-details", search],
    getCourses
  );

  const handleFiltersChange = (name: any, value: string) => {
    setSearch(value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          columnGap: "1rem",
          paddingTop: "45px",
          paddingBottom: "20px",
        }}
      >
        <FormControl sx={{ minWidth: "350px" }}>
          <SearchField
            title="Search By Course"
            onClear={() => {
              handleFiltersChange("search", "");
            }}
            onSearch={(v) => {
              handleFiltersChange("search", v);
            }}
          />
        </FormControl>
      </div>
      <Box className=" flex justify-end gap-4 items-center" mr={2} mb={1}>
        <Link to="?addOpen=true">
          <Button
            variant="contained"
            style={{
              backgroundColor: "#F2F2F2",
              color: "#1C1C1C",
              margin: "0px",
              padding: "5px 10px",
              fontSize: "14px",
            }}
          >
            Add
          </Button>
        </Link>
      </Box>
      {isLoading && <CircularProgress />}
      {data && (
        <Box
          sx={{
            overflowX: "auto",
            borderRadius: "4px",
            "& table": {
              width: "100%",
              border: "1px solid #2d5696",
            },
            "& table thead": {
              background: "#2d5696",
              color: "white",
            },
            "& th, & td": {
              border: "1px solid #2d5696",
              padding: 1,
            },
          }}
        >
          <table>
            <thead>
              <tr>
                {[
                  "Id",
                  "Name",
                  "CourseId",
                  "Duration",
                  "Minimum Course Time",
                  "Edit",
                ].map((item, index) => (
                  <th key={index}>
                    <Typography fontSize={14} fontWeight={600}>
                      {item}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.data?.length ? (
                <>
                  {data?.data?.map((item: any, index: number) => (
                    <tr key={index}>
                      <td align="center">
                        <Typography variant="body2">{item?.id}</Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">{item?.name}</Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.courseId}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.duration}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.minimumCourseTime}
                        </Typography>
                      </td>
                      <td align="center">
                        <Link
                          className="hover:underline text-primary font-medium"
                          to={`?editOpen=true&selected=${item?.id}`}
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <section>No Records Found</section>
              )}
            </tbody>
          </table>
        </Box>
      )}

      <EditCourses
        item={searchParams?.get("selected")}
        open={!!searchParams.get("editOpen")}
        setOpen={(v) => setSearchParams({})}
      />
      <AddCourses
        open={!!searchParams.get("addOpen")}
        setOpen={(v) => setSearchParams({})}
      />
    </>
  );
}

export default Courses;
