import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { TitleCard } from "pages/storage/workshop/WorkshopStorage";
import { useNavigate } from "react-router-dom";

type Props = {
  data: any;
  xl?: number;
  lg?: number;
  district?: boolean;
  file?: boolean;
  traineefollowup?: boolean;
  workshop?: boolean;
  filters?: string;
  setFilters?: any;
};

function Folders({
  data,
  district,
  file,
  xl,
  lg,
  traineefollowup,
  filters,
  setFilters,
  workshop,
}: Props) {
  let navigate = useNavigate();

  const handleClick = (item: any) => {
    if (workshop) {
      return navigate(`/workshop/storage/${item?.uid}`);
    }
    if (file) {
      return;
    }
    if (traineefollowup) {
      return navigate(`/trainee-follow-up/storage/${item?.uid}`);
    }
    if (district == true) {
      return navigate(`/storage/batch/${item?.uid}`);
    }
    navigate(`/storage/${item?.uid}`);
  };

  return (
    <Box mt={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
      >
        <TitleCard title={file ? "File" : "Folder"} color="#002058" />
      </Box>

      <Grid container spacing={1}>
        {data.map((item: any) => (
          <Grid item key={item?.id}>
            <Box
              sx={{
                borderRadius: "10px",
                cursor: "pointer",
                display: "flex",
                gap: 2,
                alignItems: "center",
                backgroundColor: "rgb(24, 47, 83, 0.2)",
                padding: "15px 20px",
              }}
              onClick={() => handleClick(item)}
            >
              <img src="/images/folder.png" />
              <Typography>{item.name}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Folders;
