import {
  Card,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { getStatistics } from "api";
import BarChart3d from "components/BarChart3d";
import { AppTraineeLoader } from "components/CounterLoader";
import { GridContainer, GridContext } from "components/Grid";
import Header from "components/Header";
import { secondsToHours } from "date-fns";
import { ApplicationContent } from "pages/stats";
import { useState } from "react";
import { useQuery } from "react-query";
import theme from "styles/theme";
import http from "utils/http";
import Table from "./Table";

const themeOption = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: "#20a87d",
    },
  },
});

function TimeStatistics() {
  const [district, setDistrict] = useState("");
  const [batch, setBatch] = useState("");

  const { data: batchs, isLoading: batchLdg } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const { data: TimeStats, isLoading: TimestatsLdg } = useQuery(
    [
      "statistics",
      {
        batch,
        districtId: district,
        type: "TIME_STATISTICS",
        educationData: true,
      },
    ],
    getStatistics
  );

  const handleChange = (e: any) => {
    setBatch(e.target.value);
  };

  return (
    <>
      <ThemeProvider theme={themeOption}>
        <Header
          showDistrictFilter
          batch={batch}
          handleChange={handleChange}
          district={district}
          onChange={(v: string) => setDistrict(v)}
        />
        <GridContainer>
          <Card
            sx={{
              boxShadow: "0px 6px 16px #2146831f",
              borderRadius: "5px",
              marginBottom: "2rem",
              width: "100%",
              height: 400,
            }}
          >
            {TimeStats?.data?.educationData ? (
              <BarChart3d
                data={TimeStats?.data?.educationData.map(
                  ({ educationName, timeSpent }: any) => ({
                    label: educationName,
                    value: secondsToHours(timeSpent),
                    color: (() => {
                      switch (educationName) {
                        case "10th":
                          return "#2d5696";
                        case "Inter":
                          return "#FFA500";
                        case "ITI":
                          return "#FF69B4";
                        case "Polytechnic":
                          return "#0c9fe4";
                        case "Degree":
                          return "#20a87d";
                        case "Engineering":
                          return "#7461c9";
                      }
                    })(),
                  })
                )}
                xAxisName={"Education Qualification"}
                yAxisName={"Time(Hrs) spent by the Trainees"}
                caption={"Time Statistics"}
                unitY={"Hrs"}
                barColor={"#20a87d"}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress sx={{ marginTop: "160px" }} />
              </div>
            )}
          </Card>
          <GridContext>
            <ApplicationContent
              title={"Time Statistics"}
              count={TimeStats ? TimeStats?.data?.count : <AppTraineeLoader />}
              src={"/images/time-statistics.gif"}
              alt={"tickIcon"}
              color={"#20a87d"}
            />
          </GridContext>
        </GridContainer>
        <Table distCode={district} batch={batch} setDistrict={setDistrict} />
      </ThemeProvider>
    </>
  );
}

export default TimeStatistics;
