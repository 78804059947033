import { Add } from "@mui/icons-material";
import { Box, Fab, Grid, IconButton, Typography } from "@mui/material";
import { getStorage, getTraineeStorage } from "api";
import { useState } from "react";
import { useQuery } from "react-query";
import Addbatches from "../../certificateupload/AddBatch";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";

function TraineeStorage() {
  const [filters, setFilters] = useState("");
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useQuery(
    ["traineefollowupstorage", filters],
    getTraineeStorage
  );

  if (isLoading) return <Loader />;

  return (
    <Box
      sx={{
        height: "90vh",
      }}
    >
      <Fab
        size="medium"
        color="secondary"
        sx={{ position: "fixed", bottom: 30, right: 30, borderRadius: "8px" }}
        aria-label="add"
      >
        <Box
          sx={{
            borderRadius: "8px",
            backgroundColor: "#2d5696",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => setOpen(true)} size="large">
            <Add sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
      </Fab>
      {data?.data?.result?.length > 0 ? (
        <Box>
          <FoldersTraineeStorage data={data?.data?.result} />
        </Box>
      ) : (
        <Box
          mt={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="85vh"
          alignItems="center"
        >
          <img src="/images/workshopempty.png" width="200px" height="200px" />
          <Typography mt={2}>No batches Found</Typography>
        </Box>
      )}

      <Addbatches open={open} setOpen={setOpen} traineefollowup={true} />
    </Box>
  );
}

type Props = {
  data: any;
  district?: boolean;
  file?: boolean;
  category?: boolean;
};

export function FoldersTraineeStorage({
  data,
  file,
  district,
  category,
}: Props) {
  let navigate = useNavigate();

  const handleClick = (item: any) => {
    if (district) {
      return navigate(`/trainee-follow-up/storage/batch/${item?.uid}`);
    } else if (category) {
      return navigate(`/trainee-follow-up/storage/batch/district/${item?.uid}`);
    }
    return navigate(`/trainee-follow-up/storage/${item?.uid}`);
  };

  return (
    <Box mt={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography sx={{ fontSize: "18px", mt: 2 }}>
          {file ? "File" : "Folder"}
        </Typography>
      </Box>

      <Grid container spacing={1}>
        {data.map((item: any) => (
          <Grid item key={item?.id}>
            <Box
              sx={{
                borderRadius: "10px",
                cursor: "pointer",
                display: "flex",
                gap: 2,
                alignItems: "center",
                backgroundColor: "rgb(24, 47, 83, 0.2)",
                padding: "15px 20px",
              }}
              onClick={() => handleClick(item)}
            >
              <img src="/images/folder.png" />
              <Typography>{item.name}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default TraineeStorage;
