import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  createWorkshop,
  getDistricts,
  uploadMedia,
  uploadWorkshopMedia,
} from "api";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import http from "utils/http";

const filters = {
  districtId: "",
  batchId: "",
  category: "",
  imageKey: "",
  title: "",
};

export function CreateWorkshop({ open, setOpen }: any) {
  const [value, setValue] = useState("");
  const [batch, setBatch] = useState("");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any>(null);
  const [imagekey, setImagekey] = useState("");
  const [category, setCategory] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [filter, setFilters] = useState<any>();
  const [error, setError] = useState(filters);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (files != null) {
      onSubmit();
    }
  }, [files, setFiles]);

  const onSubmit = () => {
    let formData = new FormData();
    formData.append("file", files);
    formData.append("type", "WORKSHOP_COVER");
    formData.append("subtype", "IMAGE");

    mutate(formData);
  };

  const { mutate } = useMutation(uploadWorkshopMedia, {
    onSuccess: (res: any) => {
      setImageURL(res?.data?.url);
      setImagekey(res?.data?.key);
    },

    onError: (err: any) => {},
  });

  const { mutate: workshopmutate } = useMutation(createWorkshop, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("getworkshopstoragebyid");
      queryClient.invalidateQueries("workshopCategory");
      setValue("");
      setBatch("");
      setFiles(null);
      setImagekey("");
      setFilters(null);
      setCategory("");
      setImageURL("");

      setOpen(false);
    },
  });

  function handleClick(e: any) {
    e.preventDefault();
    setError(filters);

    let Data = {
      districtId: filter?.id,
      batchId: batch,
      category: category,
      imageKey: imagekey,
      title: value,
    };

    if (!Data?.districtId) {
      setError((prev) => ({
        ...prev,
        districtId: "district field is required",
      }));
    }
    if (!Data?.batchId) {
      setError((prev) => ({ ...prev, batchId: "batch field is required" }));
    }
    if (!Data?.category) {
      setError((prev) => ({ ...prev, category: "category field is required" }));
    }
    if (!Data?.imageKey) {
      setError((prev) => ({ ...prev, imageKey: "image field is required" }));
    }
    if (!Data?.title) {
      setError((prev) => ({ ...prev, title: "title field is required" }));
    }

    if (error == filters) workshopmutate(Data);
  }

  const { data: Categories, isLoading: CategoriesLdg } = useQuery(
    "workshopcategory",
    () => http.get("/workshop/category").then((res) => res.data)
  );

  const { data: districtData, isLoading } = useQuery("districts", getDistricts);

  const handleChange = (e: any) => {
    setFiles(e.target.files[0]);
  };

  const handleClose = () => {
    setValue("");
    setBatch("");
    setFiles(null);
    setImagekey("");
    setFilters(null);
    setImageURL("");
    setOpen(false);
  };
  const { data: batchs, isLoading: batchLdg } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const handletitle = (e: any) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setLoading(!loading);
  }, [isLoading, CategoriesLdg, batchLdg]);

  // if (isLoading || CategoriesLdg || batchLdg) return <Loader />;

  return (
    <Dialog open={open} maxWidth="sm" onClose={handleClose}>
      {isLoading || CategoriesLdg || batchLdg ? (
        <Loader />
      ) : (
        <>
          <DialogTitle>Create Workshop</DialogTitle>
          <DialogContent>
            <Box mt="5px"></Box>
            <TextField
              fullWidth
              label="Workshop Title"
              variant="outlined"
              size="small"
              value={value}
              onChange={handletitle}
            />
            {error?.title && (
              <Typography sx={{ color: "red" }}>{error?.title}</Typography>
            )}
            <Box mb={2}></Box>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Select Batch
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={batch}
                label="Select Batch"
                onChange={(e: any) => setBatch(e.target.value)}
              >
                {batchs?.map((item: any, index: number) => (
                  <MenuItem key={index} value={item?.id}>
                    {item?.batch}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {error?.batchId && (
              <Typography sx={{ color: "red" }}>{error?.batchId}</Typography>
            )}
            <Box mt={2}></Box>
            <Autocomplete
              fullWidth
              options={districtData?.data || []}
              getOptionLabel={(option: any) => option?.name || ""}
              onChange={(event, value: any) => {
                setFilters(value);
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select District"
                />
              )}
            />
            {error?.districtId && (
              <Typography sx={{ color: "red" }}>{error?.districtId}</Typography>
            )}
            <FormControl fullWidth size="small" sx={{ marginTop: "20px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Select Category"
                onChange={(e: any) => setCategory(e.target.value)}
              >
                {Categories?.map((item: any, index: number) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {error?.category && (
              <Typography sx={{ color: "red" }}>{error?.category}</Typography>
            )}
            {imageURL === "" ? (
              <Box width="400px" marginTop="20px">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleChange}
                  multiple={false}
                  name="file"
                  id="upload"
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="upload"
                  style={{
                    display: "inline",
                    width: "400px",
                    height: "150px",
                    backgroundColor: "red",
                    zIndex: 1,
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "400px",
                      minHeight: "150px",
                      border: "1px dashed grey",
                      backgroundColor: "#F2F2F2",
                      borderRadius: "10px",
                      display: "flex",
                      position: "relative",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/images/image-icon.png"
                      width="40px"
                      style={{ marginLeft: "20px" }}
                    ></img>
                    <Typography sx={{ fontSize: "14px", opacity: 0.5 }}>
                      Upload image
                    </Typography>
                  </Box>
                </label>
              </Box>
            ) : (
              <Box
                sx={{
                  marginTop: "20px",
                  width: "400px",
                  minHeight: "150px",
                  border: "1px dashed grey",
                  backgroundColor: "#2222221A",
                  borderRadius: "10px",
                  display: "flex",
                  position: "relative",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={imageURL}
                  width="320px"
                  style={{ height: "180px" }}
                ></img>
              </Box>
            )}
            {error?.imageKey && (
              <Typography sx={{ color: "red" }}>{error?.imageKey}</Typography>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                gap: "1rem",
                marginTop: "2rem",
              }}
            >
              <Button
                style={{ backgroundColor: "rgb(9, 12, 65)" }}
                color="primary"
                variant="contained"
                onClick={handleClick}
              >
                Submit
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
