import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { getDistricts } from "api";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import http from "utils/http";
import Loader from "./Loader";
import SearchField from "./SearchField";

export default function Filters({
  filters,
  setFilters,
  distCode,
  setDistrict,
}: {
  filters: any;
  setFilters: any;
  distCode?: any;
  setDistrict?: any;
}) {
  const [courses, setCourses] = useState<any[]>([]);

  const { data: districtData, isLoading: districtLoading } = useQuery(
    "districts",
    getDistricts
  );

  const { data: educationData, isLoading } = useQuery("education", () =>
    http.get("/education").then((res) => res.data)
  );


  const handleFiltersChange = (name: any, value: string | number) => {
    setFilters((prev: any) => ({
      ...prev,
      [name]: value,
      page: 1,
    }));
  };

  const resetFilters = () => {
    setDistrict("");
    setFilters({
      page: 1,
      limit: 500,
      search: "",
      district: "",
      course: "",
      education: "",
      order: "DESC",
      orderBy: "",
      perPage: 500,
    });
  };

  useEffect(() => {
    setCourses(
      educationData?.find((item: any) => item.name === filters?.education)
        ?.courses || []
    );
  }, [filters.education]);

  const handleCourseChange = (event: any, value: any) => {
    setFilters((prev: any) => ({
      ...prev,
      courseId: value?.id || "",
      page: 1,
    }));
  };

  const handleChange = (e: any) => {
    setFilters({ ...filters, education: e.target.value, page: 1 });
    setCourses(
      educationData.find((item: any) => item.name === e.target.value)
        ?.courses || []
    );
  };

  if (isLoading || districtLoading) return <Loader />;

  return (
    <div>
      <div
        style={{
          display: "grid",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            columnGap: "1rem",
          }}
        >
          <FormControl fullWidth>
            <SearchField
              onClear={() => {
                handleFiltersChange("search", "");
              }}
              onSearch={(v) => {
                handleFiltersChange("search", v);
              }}
            />
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "1rem", flexDirection: "row" }}>
          <FormControl fullWidth>
            <FormLabel>Education Qualification</FormLabel>

            <Select
              size="small"
              displayEmpty
              value={filters.education}
              onChange={handleChange}
            >
              <MenuItem disabled value="">
                <Typography sx={{ opacity: 0.5 }}>
                  Select Education Qualification
                </Typography>
              </MenuItem>
              {educationData?.map((item: any) => (
                <MenuItem value={item?.name}>{item?.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <FormControl fullWidth>
            <FormLabel>Course</FormLabel>
            <Autocomplete
              fullWidth
              value={filters.course || ""}
              options={courses || []}
              getOptionLabel={(option: any) => option?.name || ""}
              onChange={(event, value: any) => {
                setFilters((prev: any) => ({
                  ...prev,
                  course: value,
                  page: 1,
                }));
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select Course"
                />
              )}
            />
          </FormControl> */}
          <FormControl fullWidth>
          <FormLabel>Course</FormLabel>
          <Autocomplete
            fullWidth
            value={courses.find((course) => course.id === filters.courseId) || null}
            options={courses || []}
            getOptionLabel={(option: any) => option?.name || ""}
            onChange={handleCourseChange}
            isOptionEqualToValue={(option, value) => {
              return option.id === value?.id;
            }}
            renderInput={(params) => (
              <TextField
                name="course"
                {...params}
                size="small"
                placeholder="Select Course"
              />
            )}
          />
        </FormControl>

        </div>
      </div>
      <div className="flex gap-4 items-center" style={{ margin: "1rem 0" }}>
        <h3 className="text-primary font-medium text-sm text-opacity-80">
          Selected Filters
        </h3>
        <div className="">
          <span className="text-gray-500">District: </span>
          <span>
            {distCode ? (
              <Chip
                label={
                  districtData?.data?.find((item: any) => item?.id == distCode)
                    ?.name
                }
                onDelete={() => {
                  setDistrict("");
                  setFilters((prev: any) => ({
                    ...prev,
                    page: 1,
                  }));
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        <div>
          <span className="text-gray-500">Education: </span>
          <span>
            {filters.education ? (
              <Chip
                label={filters.education}
                onDelete={() => {
                  setFilters({ ...filters, education: "", page: 1 });
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        <div>
          <span className="text-gray-500">Course: </span>
          <span>
            {filters.courseId ? (
              <Chip
                label={
                   courses.find((course) => course?.id === filters?.courseId)?.name || ""
                  }
                onDelete={() => {
                  setFilters({
                    ...filters,
                    courseId: "",
                    page: 1,
                  });
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        {filters?.search && (
          <div className="">
            <span className="text-gray-500">Search: </span>
            <Chip
              label={filters?.search}
              onDelete={() => {
                setFilters({ ...filters, search: "", page: 1 });
              }}
            />
          </div>
        )}
        <Button
          size="small"
          variant="outlined"
          onClick={resetFilters}
          sx={{
            fontWeight: 600,
            fontSize: 13,
            padding: "0 6px",
          }}
        >
          Reset Filters
        </Button>
      </div>
    </div>
  );
}
