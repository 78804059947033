import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import { Box, CardMedia, Typography } from "@mui/material";
import { AlertDialog } from "pages/traineefollow-up/TraineeComponents";
import { useEffect } from "react";

export default function AutoPlaySwiper(WorkshopArray: any) {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");

  const handlePreview = (url: string) => {
    setOpen(true);
    setUrl(url);
  };
  return (
    <Box
      sx={{
        backgroundImage: `url(${"/images/tab.png"})`,
        backgroundSize: "100% 550px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "600px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <AlertDialog open={open} setOpen={setOpen} url={url} />
      <Box
        sx={{
          paddingTop: "45px",
          width: "92%",
        }}
      >
        {WorkshopArray?.workshopArray?.length > 0 ? (
          <Swiper
            style={{
              height: "480px",
              backgroundColor: "#FFF",
            }}
            spaceBetween={20}
            slidesPerView={1}
            speed={1000}
            centeredSlides={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop
            direction="vertical"
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {WorkshopArray?.workshopArray?.map((item: any) => (
              <SwiperSlide>
                <Box
                  sx={{ width: "100%", height: "100%" }}
                  onClick={() => handlePreview(item?.url)}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={item?.url}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "30px",
              height: "450px",
              bgcolor: "#FFF",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CardMedia
                sx={{ height: 200, width: "200px" }}
                image="/images/workshopempty.png"
                title="green iguana"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography style={{ color: "red", marginTop: "10px" }}>
                No Records Found
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
