import { Box, Card, Typography } from "@mui/material";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteConfirmDialog from "./DeleteConfirmDialog";

export type Position = {
  mouseX: number;
  mouseY: number;
};

function File(data: any, actions: any) {
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const [menu, setMenu] = useState(false);
  const [dialogBox, setDialogBox] = useState(false);

  const handleView = () => {
    navigate(`/workshop/view/${data?.data.id}`);
  };

  const handleEdit = () => {
    navigate(`/workshop/edit/${data?.data?.id}`);
  };

  const handleDelete = () => {
    setDialogBox(true);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function showStringUptoLength(str: any, maxLength: number) {
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.slice(0, maxLength) + "...";
    }
  }

  return (
    <Card elevation={2} sx={{ height: "100%", borderRadius: "10px" }}>
      <DeleteConfirmDialog
        open={dialogBox}
        setOpen={setDialogBox}
        data={data?.data}
      />
      <Box onClick={handleView} sx={{ cursor: "pointer" }}>
        <img
          src={
            data?.data?.url == null
              ? "/images/workshopempty.png"
              : data?.data?.url
          }
          style={{ width: "100%", height: "180px" }}
          object-fit="cover"
          alt=""
        />
      </Box>

      <Box ml="6px">
        <Typography
          bgcolor=" #002058"
          display="inline-block"
          mt="4px"
          p="6px 8px"
          borderRadius="10px"
          sx={{
            mt: "8px",
            color: "#FFF",
            fontWeight: 600,
            fontSize: "14px",
          }}
        >
          {data?.data?.district?.name}
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            mt: "10px",
            ml: "2px",
            maxWidth: "320px",
          }}
        >
          {data?.data?.title?.length > 32
            ? showStringUptoLength(data?.data?.title, 32)
            : data?.data?.title}
        </Typography>

        <Box display="flex" justifyContent="space-between" margin="4px 4px">
          <Typography
            sx={{ color: "#2D5696", mt: "8px", mb: "10px", ml: "2px" }}
          >
            {data?.data?.batch?.batch}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mr: 2,
            }}
          >
            <Box onClick={handleEdit} sx={{ cursor: "pointer" }}>
              <DriveFileRenameOutlineRoundedIcon color="primary" />
            </Box>
            <Box onClick={handleDelete} sx={{ cursor: "pointer" }}>
              <img src="/images/delete.png" style={{ width: "16px" }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default File;

const StyledFile = styled("div")<{ dragging?: number }>(({ dragging }) => ({
  border: `1px solid ${dragging ? "red" : "#DDDDDD"}`,
  borderRadius: "4px",
  overflow: "hidden",
  height: "220px",
  display: "flex",
  flexDirection: "column",
}));

const StyledSingleLineContainer = styled("div")(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: "vertical",
}));
