// STEP 1 - Include Dependencies
// Include react
// Include the fusioncharts library
import FusionCharts from "fusioncharts";
// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";
// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";
// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

export default function BarChart3d({
  xAxisName,
  yAxisName,
  caption,
  unitY,
  data,
  barColor,
}: any) {
  const chartConfigs = {
    type: "column3d", // The chart type
    width: "100%", // Width of the chart
    height: "400", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        //Set the chart caption
        caption: caption,
        //Set the chart subcaption
        // subCaption: "In MMbbl = One Million barrels",
        //Set the x-axis name
        xAxisName: xAxisName,
        //Set the y-axis name
        yAxisName: yAxisName,
        numberSuffix: unitY,
        //Set the theme for your chart
        theme: "fusion",
        // bgColor: "#d3d3d3",
      },
      // Chart Data
      data: data,
    },
  };
  return <ReactFC {...chartConfigs} />;
}
