import { Box, Dialog } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fileLink: string;
}

const ViewFile = ({ open, setOpen, fileLink }: IProps) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <div className="">
        <img src={fileLink} alt="File" />
      </div>
    </Dialog>
  );
};

export default ViewFile;

export const WorkshopPreview = ({ open, setOpen, fileLink }: any) => {
  return (
    <Dialog maxWidth="md" open={open} onClose={() => setOpen("")}>
      <Box display="flex" justifyContent="flex-end">
        <Box p={1.75} onClick={() => setOpen("")}>
          <CloseIcon fontSize="large" />
        </Box>
      </Box>
      <Box
        p="0px 45px 45px 45px"
        minHeight="400px"
        minWidth="800px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img src={fileLink} alt="File" />
      </Box>
    </Dialog>
  );
};
