import { Avatar, Box, Button, Typography } from "@mui/material";
import Layout from "layout/Layout";
import UpdateRegisteredTrainee from "pages/registered-trainees/UpdateRegisteredTrainee";
import { useQuery } from "react-query";
import { Link, useParams, useSearchParams } from "react-router-dom";
import http from "utils/http";
import { CustomCards } from "./TraineeComponents";

function ViewTraineeFollowup() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useQuery("traineefollowupbyid", () =>
    http.get(`/trainee-followup/${id}`).then((res) => res.data)
  );

  const handleClick = () => {};

  const courseArray = [
    data?.traineeFollowup?.COURSE_PROGRESS_RECORD ?? "",

    data?.traineeFollowup?.COURSE_PROGRESS_SCREENSHOT ?? "",
    data?.traineeFollowup?.DATA_COMPENSATION_SCREENSHOT ?? "",
  ];

  const appinsallArray = [
    data?.traineeFollowup?.REGISTRATION_APP_RECORD ?? "",
    data?.traineeFollowup?.REGISTRATION_APP_SCREENSHOT ?? "",
  ];

  const CertificationArray = [
    data?.traineeFollowup?.CERTIFICATE_SCREENSHOT ?? "",
    data?.traineeFollowup?.CERTIFICATE_VIDEO ?? "",
    data?.traineeFollowup?.EXAM_MODULE_SCREENSHOT ?? "",
    data?.traineeFollowup?.EXAM_MODULE_RECORD ?? "",
  ];

  const feedbackArray = [
    data?.traineeFollowup?.FEEDBACK_VIDEO ?? "",
    data?.traineeFollowup?.PLACEMENT_OFFER ?? "",
  ];

  return (
    <Box px={1}>
      <Layout header="true" />
      <Box
        mb={"40px"}
        mt={2}
        mr="10px"
        display="flex"
        justifyContent="flex-end"
      >
        <Link to={"/trainee-followup"}>
          <Typography color="#2D5696" fontSize="18px" fontWeight="600">
            {"< "}
            View Trainee Follow Up
          </Typography>
        </Link>
      </Box>
      <Avatar
        src={data?.applicantFileUrl}
        sx={{
          height: "200px",
          width: "200px",
          position: "absolute",
          top: "70px",
          left: "70px",
        }}
      />
      <a href={`?editOpen=true&selected=${data?.id}`}>
        <Button
          style={{
            backgroundColor: "#FFFFFF",
            color: "#092F6A",
            position: "absolute",
            top: "285px",
            left: "100px",
          }}
          variant="contained"
          startIcon={<img src="/images/downloadreport.png" width="20px" />}
          onClick={handleClick}
        >
          Edit Profile
        </Button>
      </a>
      <Box sx={{ backgroundColor: "#092F6A", mt: 6 }} minHeight="330px">
        <Box
          ml={40}
          mt={2}
          p="20px 0px"
          display="flex"
          justifyContent="space-between"
        >
          <Box>
            <Typography fontWeight="bold" fontSize="35px" color="#FFFFFF">
              {data?.name}
            </Typography>
            <Typography sx={{ pt: "5px", color: "#FFFFFF", opacity: 0.6 }}>
              {data?.bocRegNo}
            </Typography>
          </Box>
          {data?.ipAddress && (
            <Box mr={8}>
              <ViewDetails title="IP Address" value={data?.ipAddress} />
            </Box>
          )}
        </Box>

        <Box ml={30} p="0px 0px" display="flex" justifyContent="space-around">
          <ViewDetails title="District" value={data?.district} />
          <ViewDetails mleft="true" title="Batch Number" value={data?.batch} />
          <ViewDetails
            mleft="true"
            title="Education Qualification"
            value={data?.higherEducation}
          />
        </Box>

        <Box
          ml={14}
          mr={4}
          pt="20px"
          display="flex"
          justifyContent="space-between"
        >
          <ViewDetails title="Course Name" value={data?.course?.name} />
          <ViewDetails
            mleft="true"
            title="Course Start Date"
            value={"11-04-2023"}
          />
        </Box>
        <Box
          ml={8}
          pb={2}
          pt="18px"
          display="flex"
          justifyContent="space-around"
        >
          <ViewDetails title="Min Course Duration" value={10} />
          <ViewDetails mleft="true" title="Total Course Time" value={"60h"} />
          <ViewDetails
            mleft="true"
            title="50% Course Completion Status"
            value={"In Progress"}
          />
          <ViewDetails mleft="true" title="Certified Status" value={"Yes"} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <CustomCards
            imageURL="/images/appinstall.png"
            title="App Instalation"
            color="#092F6A"
            dataArray={appinsallArray}
          ></CustomCards>
        </Box>
        <Box>
          <CustomCards
            color="#7461c9"
            title="Course Progress"
            imageURL="/images/courseprogress.png"
            dataArray={courseArray}
          ></CustomCards>
        </Box>
        <Box>
          <CustomCards
            color="#20a87d"
            title="Certification"
            imageURL="/images/certificate.png"
            dataArray={CertificationArray}
          ></CustomCards>
        </Box>
        <Box>
          <CustomCards
            color="#0c9fe4"
            title="Feedback"
            imageURL="/images/feedback.png"
            dataArray={feedbackArray}
          ></CustomCards>
        </Box>
      </Box>

      <UpdateRegisteredTrainee
        data={data}
        open={!!searchParams.get("editOpen")}
        setOpen={(v) => setSearchParams({})}
      />
    </Box>
  );
}

function ViewDetails({ mleft, title, value }: any) {
  return (
    <Box
      sx={{
        marginLeft: mleft === "true" ? 8 : "0px",
      }}
    >
      <Typography fontSize="14px" sx={{ opacity: 0.6, color: "#FFFFFF" }}>
        {title}
      </Typography>
      <Typography fontSize="18px" color="#FFFFFF" maxWidth="65vw">
        {value}
      </Typography>
    </Box>
  );
}

export default ViewTraineeFollowup;
