import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import http from "utils/http";

import { bulkUpload, getStoragefolder } from "api";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

function Uploadfile() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { folderId } = useParams();
  const id = folderId;
  const [state, setState] = useState(false);
  const [filters, setFilters] = useState("");
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState<any[]>([]);
  const [message, setMessage] = useState<any[]>([]);
  const [submited, setSubmited] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const { data, isLoading } = useQuery(
    ["storage", id, page, filters],
    getStoragefolder
  );

  const handleSubmit = async () => {
    await files.map(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("type", "FILE");
      formData.append("folderId", id!);
      formData.append("batch", data?.data?.breadCrumbs[0].name);
      formData.append("district", data?.data?.breadCrumbs[1].name);
      try {
        let res = await http.post(`storage/upload-file`, formData);
        status.push("Success");
        message.push(res?.data?.message);
      } catch (err: any) {
        status.push("Failed");
        message.push(err?.response?.data?.message);
      } finally {
        setStatus([...status]);
        setMessage([...message]);
        setSubmited(true);
        setState(!state);
      }

      // mutate(formData);
    });
  };

  const handleChange = (e: any) => {
    setFiles(Array.from(e.target.files));
  };

  const searchHandler = (e: any) => {
    if (e.target.value == "") {
      setFilters("");
    }
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    setFilters(search);
  };

  const { mutate, isLoading: loading } = useMutation(bulkUpload, {
    onSuccess: (res: any) => {
      setStatus((prev) => [...prev, res?.data?.status]);
      setMessage((prev) => [...prev, res?.data?.message]);
      setSubmited(true);
    },
    onError: (error: any) => {
      setSubmited(true);
      setStatus((prev) => [...prev, error.response.data?.error]);
      setMessage((prev) => [...prev, error?.response?.data?.message]);
    },
  });

  const handlenavBack = () => {
    navigate(-1);
  };

  const handlenavHome = () => {
    navigate(-2);
  };

  const handleUpload = () => {
    setFiles([]);
    setStatus([]);
    setMessage([]);
    setSubmited(false);
  };

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  if (isLoading)
    return (
      <Box
        p={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="95vh"
      >
        <CircularProgress></CircularProgress>
      </Box>
    );

  return (
    <Box mt={2}>
      {submited && (
        <Box>
          <Typography>
            Total Failed : {status.filter((f) => f == "Failed").length}
          </Typography>
          <Typography>
            Total Success : {status.filter((f) => f == "Success").length}
          </Typography>
        </Box>
      )}

      {files.length > 0 ? (
        <Box mt={4}></Box>
      ) : (
        <Box display="flex" justifyContent="flex-end" mb={2} mt={3}>
          <Box sx={{ display: "flex" }}>
            <TextField
              sx={{ width: "250px" }}
              size="small"
              placeholder="Search"
              value={search}
              onChange={searchHandler}
            />
            <Button
              onClick={handleSearch}
              sx={{ ml: 1 }}
              variant="outlined"
              size="small"
              color="primary"
            >
              Submit
            </Button>
          </Box>
        </Box>
      )}

      {files.length > 0 ? (
        <Box>
          <Box
            sx={{
              overflowX: "auto",
              borderRadius: "4px",
              mt: 2,
              "& table": {
                width: "100%",
                border: "1px solid #2d5696",
              },
              "& table thead": {
                background: "#2d5696",
                color: "white",
              },
              "& th, & td": {
                border: "1px solid #2d5696",
                padding: 1,
              },
            }}
          >
            <table>
              <thead>
                <tr>
                  {["Name", "Type", "Size", "Status", "Message"].map(
                    (item, index) => (
                      <th key={index}>
                        <Typography fontSize={14} fontWeight={600}>
                          {item}
                        </Typography>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {files?.map((item: any, index: any) => (
                  <tr>
                    <td align="center">
                      <Typography variant="body2">{item?.name}</Typography>
                    </td>
                    <td align="center">
                      <Typography variant="body2">{item?.type}</Typography>
                    </td>
                    <td align="center">
                      <Typography variant="body2">
                        {bytesToSize(item?.size)}
                      </Typography>
                    </td>

                    <td align="center">
                      {status[index] == "Success" && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <img src="/images/greentick.png" width="14px" />
                          <Typography sx={{ color: "#57CA22", ml: 1 }}>
                            Success
                          </Typography>
                        </Box>
                      )}
                      {status[index] == "Failed" && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <img src="/images/redexclamation.png" width="14px" />
                          <Typography sx={{ color: "#a93e20", ml: 1 }}>
                            Failed
                          </Typography>
                        </Box>
                      )}
                      {status[index] == undefined && submited && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {/* <img src="/images/greentick.png" width="14px" /> */}
                          <Typography sx={{ color: "#262924", ml: 1 }}>
                            ...Loading
                          </Typography>
                        </Box>
                      )}
                    </td>
                    <td align="center">
                      {status[index] == undefined && submited ? (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {/* <img src="/images/greentick.png" width="14px" /> */}
                          <Typography sx={{ color: "#262924", ml: 1 }}>
                            ...Loading
                          </Typography>
                        </Box>
                      ) : (
                        <Typography variant="body2">
                          {message[index]}
                        </Typography>
                      )}
                    </td>

                    {/*                     
                    {true ? (
                      <td align="center">
                        {status[index] == "success" ? (
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <img src="/images/greentick.png" width="14px" />
                            <Typography sx={{ color: "#57CA22", ml: 1 }}>
                              Success
                            </Typography>
                          </Box>
                        ) : status.length > 0 ? (
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <img
                              src="/images/redexclamation.png"
                              width="14px"
                            />
                            <Typography sx={{ color: "#D2232E", ml: 1 }}>
                              Failed
                            </Typography>
                          </Box>
                        ) : (
                          <Box></Box>
                        )}
                      </td>
                    ) : (
                      <td>
                        <Typography>...loading</Typography>
                      </td>
                    )}
                    {true ? (
                      <td align="center">
                        <Typography variant="body2">
                          {message[index]}
                        </Typography>
                      </td>
                    ) : (
                      <td>
                        <Typography></Typography>
                      </td>
                    )} */}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            {submited === false ? (
              <Button
                style={{
                  marginTop: "8px",
                  backgroundColor: "#2d5696",
                  color: "#FFFFFF",
                }}
                onClick={handleSubmit}
              >
                submit
              </Button>
            ) : status.every((item: any) => item == "Bad Request") ? (
              <Button
                style={{
                  marginTop: "8px",
                  backgroundColor: "#2d5696",
                  color: "#FFFFFF",
                }}
                onClick={handleUpload}
              >
                Reupload
              </Button>
            ) : (
              <Button
                style={{
                  marginTop: "8px",
                  backgroundColor: "#2d5696",
                  color: "#FFFFFF",
                }}
                onClick={handleUpload}
              >
                Upload
              </Button>
            )}
          </Box>
        </Box>
      ) : data?.data?.result.length > 0 ? (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                onClick={handlenavHome}
              >
                Home
              </Typography>
              <Typography m="0px 5px">/</Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                onClick={handlenavBack}
              >
                {data?.data?.breadCrumbs[0].name}
              </Typography>
              <Typography m="0px 5px">/</Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                }}
              >
                {data?.data?.breadCrumbs[1].name}
              </Typography>
            </Box>
            <Box width="130px" height="35px" mb={2}>
              <input
                type="file"
                onChange={handleChange}
                multiple={true}
                accept=".pdf"
                name="file"
                id="upload"
                style={{ display: "none" }}
              />
              <label
                htmlFor="upload"
                style={{
                  display: "inline",
                  width: "200px",
                  height: "150px",
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#2D5696",
                    color: "#FFFFFF",
                    borderRadius: "4px",
                    padding: "5px 14px",
                  }}
                >
                  Upload Files
                </Box>
              </label>
            </Box>
          </Box>

          <Grid container spacing={1}>
            {data?.data?.result?.map((item: any) => (
              <Grid item key={item?.id}>
                <Box
                  sx={{
                    borderRadius: "10px",
                    cursor: "pointer",
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    backgroundColor: "rgb(24, 47, 83, 0.2)",
                    padding: "15px 10px",
                    minWidth: "280px",
                  }}
                >
                  <img src="/images/pdf.png" width="30px" />
                  <Typography>{item?.name}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          {data?.data?.count > 500 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pt: 2,
                pb: 1,
                alignItems: "center",
              }}
            >
              <Typography mr={2}>Page: {page}</Typography>
              <Pagination
                count={Math.round(data?.data?.count / 500)}
                page={page}
                onChange={handlePagination}
              />
            </Box>
          )}
        </Box>
      ) : (
        files?.length == 0 && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={4}
            height="80vh"
          >
            <img src="/images/workshopempty.png" width="200px" height="200px" />
            <Typography mt={2}>No Files Available</Typography>

            <Box width="85px" height="35px" mt={2}>
              <input
                type="file"
                onChange={handleChange}
                multiple={true}
                name="file"
                id="upload"
                accept=".pdf"
                style={{ display: "none" }}
              />
              <label
                htmlFor="upload"
                style={{
                  display: "inline",
                  width: "400px",
                  height: "150px",
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#2D5696",
                    color: "#FFFFFF",
                    borderRadius: "4px",
                    padding: "5px 14px",
                  }}
                >
                  Upload
                </Box>
              </label>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
}

export default Uploadfile;

export function bytesToSize(bytes: number): string {
  const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i: number = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)).toString()
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
}
