import { Add } from "@mui/icons-material";
import { Box, Fab, IconButton, Typography } from "@mui/material";
import { getStoragefolder } from "api";
import Loader from "components/Loader";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Adddistricts from "./Adddistricts";
import Folders from "./Folder";

function Districtsfolder() {
  const navigate = useNavigate();
  const [filters, setFilters] = useState("");

  const [open, setOpen] = useState(false);

  const { id } = useParams();

  const { data, isLoading } = useQuery(
    ["storage", id, 1, filters],
    getStoragefolder
  );
  const handlenavBack = () => {
    navigate(-1);
  };

  if (isLoading) return <Loader />;

  return (
    <Box
      sx={{
        height: "84vh",
      }}
    >
      <Fab
        size="medium"
        color="secondary"
        sx={{ position: "fixed", bottom: 30, right: 30, borderRadius: "8px" }}
        aria-label="add"
      >
        <Box
          sx={{
            borderRadius: "8px",
            backgroundColor: "#2d5696",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => setOpen(true)} size="large">
            <Add sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
      </Fab>
      <Box mt={4} display="flex">
        <Typography
          sx={{
            cursor: "pointer",
          }}
          onClick={handlenavBack}
        >
          Home
        </Typography>{" "}
        <Typography m="0px 5px">/</Typography>
        <Typography
          sx={{
            cursor: "pointer",
          }}
        >
          {data?.data?.breadCrumbs[0]?.name}
        </Typography>
      </Box>

      {data?.data?.result?.length > 0 ? (
        <Box>
          <Folders district={true} data={data?.data?.result} />
        </Box>
      ) : (
        <Box
          mt={4}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <img src="/images/workshopempty.png" width="200px" height="200px" />
          <Typography mt={2}>No Districts Found</Typography>
        </Box>
      )}

      <Adddistricts open={open} setOpen={setOpen} />
    </Box>
  );
}

export default Districtsfolder;
