import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  createTheme,
  Drawer,
  IconButton,
  TextField,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { getCourses, updateCourse } from "api";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import theme from "styles/theme";

const themeOption = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: "#2d5696",
    },
  },
});

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  item?: any;
}

function EditCourses({ open, setOpen, item }: Props) {
  const [state, setState] = useState<any>({});
  const [value, setValue] = useState<Dayjs | null>(null);
  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateCourse, {
    onSuccess: () => {
      toast.success("Updated");
      queryClient.invalidateQueries("district-course-details");
      setOpen(false);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { data, isLoading } = useQuery(["district-course-details"], getCourses);

  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    mutate({
      id: state?.id,
      data: {
        id: state?.id,
        name: state?.name,
        courseId: state?.courseId,
        duration: state?.duration,
        minimumCourseTime: state?.minimumCourseTime,
      },
    });
  };

  useEffect(() => {
    setState(data?.data?.find((data: any) => data?.id == item));
  }, [data, isLoading, item]);

  if (isLoading) return <CircularProgress />;

  return (
    <ThemeProvider theme={themeOption}>
      <Drawer
        anchor="right"
        sx={{ zIndex: 1202 }}
        PaperProps={{ sx: { width: 400 } }}
        open={open}
        onClose={() => setOpen(false)}>
        <AppBar position="sticky">
          <Toolbar
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography variant="subtitle1"> Edit Course</Typography>
            <IconButton onClick={() => setOpen(false)} sx={{ color: "white" }}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box p={2}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              onChange={handleChange}
              disabled
              variant="outlined"
              size="small"
              label="Id"
              name="id"
              value={state?.id}
            />
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              onChange={handleChange}
              variant="outlined"
              size="small"
              label="Course Name"
              name="name"
              value={state?.name}
            />
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              onChange={handleChange}
              variant="outlined"
              size="small"
              label="CourseId"
              name="courseId"
              value={state?.courseId}
            />
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              onChange={handleChange}
              variant="outlined"
              size="small"
              label="Duration"
              name="duration"
              value={state?.duration}
            />
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              onChange={handleChange}
              variant="outlined"
              size="small"
              label="Minimum Course Time"
              name="minimumCourseTime"
              value={state?.minimumCourseTime}
            />

            <Box mt={2} textAlign="right">
              <Button
                style={{ backgroundColor: "#f50000" }}
                variant="contained"
                color="secondary"
                type="submit">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
}

export default EditCourses;
