import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Button, ListItemIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import AutoComplete from './FormFields/AutoComplete';
import FormSelect from './FormFields/FormSelect';
import Heading from './Heading';
import { updateFeedbackDetails } from 'api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

type Anchor = 'right';
const gender = ['Male', 'Female', 'Other'];
const years = Array.from({ length: 45 - 14 + 1 }, (_, i) => ({ label: `${14 + i} Years` }));
const priorEmployment = ['Student', 'Unemployed', 'Employed Part-Time', 'Employed Full-Time', 'Self-Employed', 'Other'];
const employmentStatus = ['Student', 'Unemployed', 'Employed Part-Time', 'Employed Full-Time', 'Self-Employed', 'Other'];
const socialGroup = ['General (Forward Caste)', 'OBC', 'SC', 'ST', 'Minority'];
const source = [
  'Phone Call From SETWIN',
  'Online Advertisement',
  'Social Media',
  'Friend Or Family Referral',
  'Job Center',
  'Local Community Board',
  'Newspaper Or Magazine',
  'Radio Or TV',
  'Other',
];
const quality = ['Excellent', 'Good', 'Adequate', 'Poor', 'Not Applicable'];
const trainingRelevance = ['Highly Relevant', 'Somewhat Relevant', 'Barely Relevant', 'Not Relevant'];
const instructorEffectiveness = ['Extremely Effective', 'Moderately Effective', 'Slightly Effective', 'Ineffective', 'Not Applicable'];
const assessmentMethod = ['End-Of-Course Exam', 'Continuous Assessment', 'Practical Assignments', 'No Formal Assessment', 'Other'];
const postTrainingConfidence = ['Very Confident', 'Somewhat Confident', 'Neutral', 'Somewhat Unconfident', 'Not Confident At All'];
const careerOpportunities = ['Many Opportunities', 'Some Opportunities', 'Few Opportunities', 'No Opportunities', 'Not Applicable'];
const additionalTrainingNeeded = ['Possibly In The Future', 'No Additional Training Needed', 'Yes, Immediately', 'Yes, In The Near Future'];
const recommendation = ['Highly Recommend', 'Recommend', 'Neutral', 'Would Not Recommend', 'Strongly Do Not Recommend'];
const overallOpinion = ['Useful', 'Not Useful', 'Not Sure'];

const schema = yup.object().shape({
  age: yup.string().required(),
  gender: yup.string().required(),
  priorEmployment: yup.string().required(),
  currentEmploymentStatus: yup.string().required(),
  socialGroup: yup.string().required(),
  isConstructionWorker: yup.string().required(),
  source: yup.string().required(),
  isFirstTraining: yup.string().required(),
  qualityOfTraining: yup.string().required(),
  relevanceOfTraining: yup.string().required(),
  instructorEffectiveness: yup.string().required(),
  assessmentMethod: yup.string().required(),
  isCertificationReceived: yup.boolean().required(),
  postTrainingConfidence: yup.string().required(),
  careerOpportunities: yup.string().required(),
  additionalTrainingRequired: yup.string().required(),
  recommendation: yup.string().required(),
  overallOpinion: yup.string().required(),
});

export default function Actions({ row }: any) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const methods = useForm({});
  const { handleSubmit, control,formState:{errors} } = methods;
  const queryClient = useQueryClient();
  const {mutate}=useMutation(updateFeedbackDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries("feedback-usersdata");
      toggleDrawer(false)
      setDrawerOpen(false)
      toast.success("Feedback data updated successfully");
      
      
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message?.[0]);
    },
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleView = () => {
    navigate(`${row?.original?.id}`);
  };
  
  const handleEdit = () => {
    setDrawerOpen(true);
    handleClose();
  };
  
  const handleDelete = () => {
    // Add your delete logic here
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const onSubmit = async (data: any) => {
    let res={
      ...data,
      formId: row?.original?.form?.formId,
      isConstructionWorker: data?.isConstructionWorker=== "Yes" ? true : false,
      isFirstTraining : data?.isFirstTraining=== "Yes" ? true : false,
      isCertificationReceived: data?.isCertificationReceived=== "Yes" ? true : false,
    }
    mutate({id:row?.original?.id,body:res})
    
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 450,zIndex: (theme)=>30000 }}
      role="presentation"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent:"space-between",
          padding: '16px',
          borderBottom: '1px solid #ddd',
          backgroundColor:"#1E4B8F",
          color:"#FFFFFF"
        }}
      >
        <Typography variant="h6" noWrap>
          Edit Feedback Details
        </Typography>
        <Button onClick={handleClose} sx={{ color: '#FFFFFF' }}>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon sx={{ color: '#FFFFFF' }}/>
          </IconButton>
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          padding: '16px',
          display:"flex",
          flexDirection:"column"
        }}
      >
        <Heading value={'PARTICIPANT DEMOGRAPHICS'} />
        <AutoComplete control={control} years={years} name={'age'} defaultValue={row?.original?.age} label={'Age Of The Participant'} placeholder="Enter Age Of The Participant" />
        <FormSelect data={gender} control={control} defaultValue={row?.original?.gender} name={'gender'} label={"Gender"} />
        <FormSelect data={priorEmployment} control={control} defaultValue={row?.original?.priorEmployment} name={'priorEmployment'} label={"Employment Status Prior To Training"} />
        <FormSelect data={employmentStatus} control={control} defaultValue={row?.original?.currentEmploymentStatus} name={'currentEmploymentStatus'} label={"Current Employment Status"}/>
        <FormSelect data={socialGroup} control={control} defaultValue={row?.original?.socialGroup} name={'socialGroup'} label={"Social Group"} />
        <FormSelect data={["Yes", "No"]} control={control} defaultValue={row?.original?.isConstructionWorker ? "Yes" : "No"} name={'isConstructionWorker'} label={"Is Your Parents/Father A Construction Worker?"} />
        <Heading value={'PRE-TRAINING INFORMATION'} />
        <FormSelect data={source} control={control} defaultValue={row?.original?.source} name={'source'} label={"How Did You Hear About The Training Program?"} />
        <FormSelect data={["Yes", "No"]} control={control} defaultValue={row?.original?.isFirstTraining ? "Yes" : "No"} name={'isFirstTraining'} label={"Was This Your First Skill Training Program?"} />
        <Heading value={'TRAINING DETAILS'} />
        {/* <FormInput label={'Name Of The Training Course Attended'} value={"hello"} />
        <FormInput label={'Duration Of The Course'} value={`hello`} /> */}
        <FormSelect data={quality} control={control} defaultValue={row?.original?.qualityOfTraining} name={'qualityOfTraining'} label={"Quality Of Training Materials Provided"} />
        <FormSelect data={trainingRelevance} control={control} defaultValue={row?.original?.relevanceOfTraining} name={'relevanceOfTraining'} label={"Relevance Of The Training Content To Your Expected Job Role"} />
        <FormSelect data={instructorEffectiveness} control={control} defaultValue={row?.original?.instructorEffectiveness} name={'instructorEffectiveness'} label={"Instructors Teaching Method Effectiveness"} />
        <FormSelect data={assessmentMethod} control={control} defaultValue={row?.original?.assessmentMethod} name={'assessmentMethod'} label={"Assessment Methods Used During The Training"} />
        <Heading value={'TRAINING EVALUATION'}/>
        <FormSelect data={["Yes","No"]} control={control} defaultValue={row?.original?.isCertificationReceived ? "Yes" : " No"} name={'isCertificationReceived'} label={"Certification Received"} />
        <FormSelect data={postTrainingConfidence} control={control} defaultValue={row?.original?.postTrainingConfidence} name={'postTrainingConfidence'} label={"How Did You Feel Immediately After Completing Training"} />
        <FormSelect data={careerOpportunities} control={control} defaultValue={row?.original?.careerOpportunities} name={'careerOpportunities'} label={"Opportunities For Career Advancement Post- Training"} />
        <FormSelect data={additionalTrainingNeeded} control={control} defaultValue={row?.original?.additionalTrainingRequired} name={'additionalTrainingRequired'} label={"Additional Training Or Skills Needed"} />
        <FormSelect data={recommendation} control={control} defaultValue={row?.original?.recommendation} name={'recommendation'} label={"Recommendations For Others Considering Similar Training"} />
        <FormSelect data={overallOpinion} control={control} defaultValue={row?.original?.overallOpinion} name={'overallOpinion'} label={"Overall Opinion About The Programme"} />
        <Button type='submit' variant='contained' onClick={handleSubmit(onSubmit)} sx={{
          marginTop: "10px",
          width: "40%",
          fontWeight: "600",
          backgroundColor: "#1E4B8F",
          color: "#1E4B8F",
          borderRadius: "8px",
          '&:hover': {
            backgroundColor: "#1E4B8F",
            color: "white"
          }
        }}>
          Submit
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleView}>
          <ListItemIcon>
            <RemoveRedEyeOutlinedIcon />
          </ListItemIcon>
          View Feedback Details
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <ModeEditOutlinedIcon />
          </ListItemIcon>
          Edit Details
        </MenuItem>
        {/* <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteOutlinedIcon />
          </ListItemIcon>
          Delete
        </MenuItem> */}
      </Menu>
      <Drawer
        sx={{ zIndex: 1202 }}
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {list('right')}
      </Drawer>
    </div>
  );
}

