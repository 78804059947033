import { Box, CircularProgress } from "@mui/material";
import { createContext, useContext, useState } from "react";

const FullPageLoaderContext = createContext<any>({});

export default function FullPageLoader({ children }: any) {
  const [loading, setLoading] = useState(false);

  return (
    <FullPageLoaderContext.Provider value={{ setLoading }}>
      {children}
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress style={{ color: "white" }} />
        </Box>
      )}
    </FullPageLoaderContext.Provider>
  );
}

export const useFullPageLoader = () => {
  return useContext(FullPageLoaderContext);
};
