import { Box, styled } from "@mui/material";

export const GridContainer = styled(Box)(({ theme }: any) => ({
  display: "grid",
  gridTemplateColumns: "62% 36%",
  justifyContent: "space-between",
}));

export const GridContext = styled(Box)(({ theme }: any) => ({
  margin: "auto 0",
}));
