import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { getDistricts } from "api";
import Loader from "components/Loader";
import SearchField from "components/SearchField";
import { useState } from "react";
import { useQuery } from "react-query";
import http from "utils/http";

export default function TableFilters({
  filters,
  setFilters,
  refetch,
}: {
  filters: any;
  setFilters: any;
  refetch?: any;
}) {
  const [courses, setCourses] = useState<any[]>([]);
  const { data: districts } = useQuery("districts", getDistricts);

  const { data: education } = useQuery("education", () =>
    http.get("/education").then((res) => res.data)
  );

  const { data: batchs } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const Currentbatch = batchs?.find((item: any) => item?.currentBatch == true);

  const resetFilters = () => {
    setFilters({
      page: 1,
      limit: 500,
      search: "",
      district: "",
      course: "",
      education: "",
      batch: "",
      order: "DESC",
      orderBy: "",
    });
  };

  if (!Currentbatch) <Loader />;

  return (
    <div>
      <div
        className="grid gap-x-4 gap-y-2 mb-3"
        style={{
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
          alignItems: "end",
        }}>
        <FormControl fullWidth>
          <FormLabel>Batch</FormLabel>
          <TextField
            onChange={(e) => {
              setFilters({ ...filters, batch: e.target.value, page: 1 });
            }}
            select
            value={filters.batch || Currentbatch?.batch}
            size="small"
            sx={{
              minWidth: 200,
              marginRight: 1,
              "@media (max-width:600px)": {
                minWidth: 125,
              },
            }}>
            <MenuItem value={"ALL"}>All</MenuItem>
            {batchs?.map((item: any, index: number) => (
              <MenuItem key={index} value={item?.batch}>
                {item?.batch}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>District</FormLabel>
          <Autocomplete
            fullWidth
            value={filters.district}
            options={districts?.data}
            getOptionLabel={(option: any) => option?.name || ""}
            onChange={(event, value: any) => {
              setFilters({
                ...filters,
                district: value,
                page: 1,
              });
            }}
            isOptionEqualToValue={(option, value) => {
              return option.value === value;
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="All Districts" />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>Search</FormLabel>
          <SearchField
            onClear={() => {
              setFilters({
                ...filters,
                search: "",
              });
            }}
            onSearch={(v) => {
              setFilters({
                ...filters,
                search: v,
                page: 1,
              });
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>Education Qualification</FormLabel>

          <Select
            size="small"
            displayEmpty
            value={filters.education}
            onChange={(e) => {
              setFilters({ ...filters, education: e.target.value });
              setCourses(
                education.find((item: any) => item.name === e.target.value)
                  .courses || []
              );
            }}>
            <MenuItem disabled value="">
              <Typography sx={{ opacity: 0.5 }}>
                Select Education Qualification
              </Typography>
            </MenuItem>
            {education?.map((item: any) => (
              <MenuItem value={item?.name}>{item?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>Course</FormLabel>
          <Autocomplete
            fullWidth
            value={filters.course || ""}
            options={courses || []}
            getOptionLabel={(option: any) => option?.name || ""}
            onChange={(event, value: any) => {
              setFilters((prev: any) => ({
                ...prev,
                course: value,
                page: 1,
              }));
            }}
            isOptionEqualToValue={(option, value) => {
              return option.name === value;
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Select Course" />
            )}
          />
        </FormControl>
      </div>
      <div className="flex gap-4 items-center">
        <h3 className="text-primary font-medium text-sm text-opacity-80">
          Selected Filters
        </h3>
        <div className="">
          <span className="text-gray-500">Batch: </span>
          <span>
            {filters.batch ? (
              <Chip
                label={filters.batch}
                onDelete={() => {
                  setFilters({ ...filters, batch: "" });
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        <div className="">
          <span className="text-gray-500">District: </span>
          <span>
            {filters.district ? (
              <Chip
                label={filters.district?.name}
                onDelete={() => {
                  setFilters({ ...filters, district: "" });
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        <div>
          <span className="text-gray-500">Education: </span>
          <span>
            {filters.education ? (
              <Chip
                label={filters.education}
                onDelete={() => {
                  setFilters({ ...filters, education: "" });
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        <div>
          <span className="text-gray-500">Course: </span>
          <span>
            {filters.course ? (
              <Chip
                label={
                  filters?.course?.name
                  // courses?.find((item: any) => item.id === filters.course)?.name
                }
                onDelete={() => {
                  setFilters({ ...filters, course: null });
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        {filters.search && (
          <div className="">
            <span className="text-gray-500">Search: </span>
            <Chip
              label={filters.search}
              onDelete={() => {
                setFilters({ ...filters, search: "" });
              }}
            />
          </div>
        )}
        <Button
          size="small"
          variant="outlined"
          onClick={resetFilters}
          sx={{
            fontWeight: 600,
            fontSize: 13,
            padding: "0 6px",
          }}>
          Reset Filters
        </Button>
      </div>
    </div>
  );
}
