import styled from "@emotion/styled";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import PasswordField from "components/password";
import { useState } from "react";
import http from "../utils/http";

interface ILoginState {
  mobile: string;
  password: string;
}

const Login = () => {
  const [state, setState] = useState<ILoginState>({
    mobile: "",
    password: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    login();
  };

  const login = async () => {
    setLoading(true);
    try {
      const res = await http.post(`/auth/login`, {
        mobile: state.mobile,
        password: state.password,
      });

      localStorage.clear();
      localStorage.setItem("AUTH_TOKEN", res.data.access_token);

      http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.access_token}`;

      window.location.href = "/";
      setLoading(false);
    } catch (err: any) {
      err && setError(err.response.data.message);
      setLoading(false);
    }
  };
  const handleChange = (e: any) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <div className="flex md:flex-col">
        <div className="w-1/2 h-screen flex-grow bg-primary flex-shrink-0 md:hidden">
          {/* <img src="/images/banner.png" className="h-screen w-full" /> */}
          <img src="/images/Banner.png" className="h-screen w-full" />
        </div>
        <div className="flex-grow bg-primary flex-shrink-0 hidden md:inline">
          <Box
            height="30vh"
            sx={{ "@media(max-width:500px)": { height: "42vh" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mt: "35px",
              }}>
              <CustomMainTitle title="SETWIN Online course Registration Form for BOCW" />
              <CustomMainTitle title="Member and their Dependents" />
            </Box>
            {/* <Box display="flex" justifyContent="space-around" mt="24px">
              <Box display="flex" flexDirection="column" alignItems="center">
                <img src="/images/kcr.png" alt="kcr" width="100px" />
                <Box mt="10px">
                  <CustomText title="Sri k. Chandrashekar Rao" />
                  <CustomText title="Honourable Chief Minister" />
                </Box>
              </Box>
              <Box alignItems="center" display="flex" flexDirection="column">
                <img src="/images/minister.png" alt="minister" width="100px" />
                <Box mt="10px">
                  <CustomText title="Sri Ch. Malla Reddy" />
                  <CustomText title=" Honourable Minister, Labour" />
                  <CustomText title="and Employment" />
                </Box>
              </Box>
            </Box> */}

            <Box display="flex" justifyContent="space-around" mt="28px">
              <Box>
                <img
                  src="/images/tsbocwwblogo.png"
                  alt="kcr"
                  width="120px"
                  style={{ marginRight: "20px" }}
                />
                <Box mt="10px">
                  <CustomText title="(Telangana Building and" />
                  <CustomText title="Other Construction Workers" />
                  <CustomText title="Welfare Board)" />
                </Box>
              </Box>
              <FlexContainer>
                <img
                  src="/images/setwinwithtext.png"
                  alt="kcr"
                  width="100px"
                  style={{ marginRight: "20px" }}
                />
                <Box mt="10px">
                  <CustomText title="(Society for Employment" />
                  <CustomText title="Training and Promotion in" />
                  <CustomText title="Twin cities)" />
                </Box>
              </FlexContainer>
            </Box>
          </Box>
        </div>
        <div className="w-1/2   flex justify-center h-screen items-center flex-shrink-0 md:w-full md:h-2/5 md:my-12">
          <form
            className="m-12 gap-4 w-1/2 md:my-0 md:w-4/5"
            onSubmit={handleSubmit}>
            {error && (
              <Alert severity="error" className="mb-5 capitalize">
                {error}
              </Alert>
            )}
            <div className="my-4">
              <label htmlFor="mobile" className="font-bold mb-4">
                Mobile
              </label>
              <TextField
                className="mt-2"
                required
                name="mobile"
                size="medium"
                variant="outlined"
                autoComplete="off"
                fullWidth
                label="Enter Mobile"
                value={state.mobile}
                onChange={handleChange}
              />
            </div>
            <div className="my-5">
              <label htmlFor="loginId" className="font-bold">
                Password
              </label>
              <PasswordField
                value={state.password}
                label="Password"
                sx={{ mt: "8px" }}
                onChange={handleChange}
              />
            </div>
            <Box
              sx={{
                "@media(max-width:600px)": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}>
              <Button
                endIcon={
                  loading ? (
                    <CircularProgress sx={{ color: "white" }} size={24} />
                  ) : null
                }
                size="medium"
                className="mt-4 px-16"
                type="submit"
                variant="contained"
                sx={{ background: "#1E4B8F !important" }}>
                Login
              </Button>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export function CustomText({ title }: any) {
  return (
    <Typography
      fontSize="14px"
      color="#FFF"
      fontWeight="600"
      sx={{
        "@media (max-width:500px)": {
          fontSize: "10px",
        },
      }}>
      {title}
    </Typography>
  );
}

function CustomMainTitle({ title }: any) {
  return (
    <Typography
      color="#FFF"
      fontSize="20px"
      fontWeight="700"
      sx={{
        "@media (max-width:680px)": {
          fontSize: "18px",
        },
        "@media (max-width:500px)": {
          fontSize: "14px",
        },
      }}>
      {title}
    </Typography>
  );
}

export const FlexContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export default Login;
