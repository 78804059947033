import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { taineeFollowUp } from "api";
import { UserProfileStore } from "layout/AppRouter";
import TableFilters from "pages/registered-trainees/components/TableFilters";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import http from "utils/http";

interface IFilters {
  page: number;
  limit: number;
  search?: string;
  district?: any;
  batch?: any;
  course?: any;
  education?: string | number | null;
  order?: "ASC" | "DESC";
  orderBy?: string;
}

function TraineeFollowUp() {
  const role = UserProfileStore.useState((s) => s.state.role);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState<IFilters>({
    page: 1,
    limit: 100,
    search: "",
    district: null,
    course: null,
    education: "",
    batch: "",
    order: "DESC",
    orderBy: "",
  });
  const [batch, setBatch] = useState("");
  const [district, setDistrict] = useState("");

  const { data: batchs } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );
  const { data: districts, isLoading: DistrictLdg } = useQuery(
    "districts",
    () => http.get("/district").then((res) => res.data)
  );

  const { data: TraineeData, isLoading: TraineeLdg } = useQuery(
    [
      "taineefollowup",
      {
        ...filters,
        district: filters?.district?.id,
        course: filters?.course?.id,
      },
    ],
    taineeFollowUp
  );
  const handleFiltersChange = (name: any, value: string) => {
    setSearch(value);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setFilters((prev: any) => ({ ...prev, page: value }));
  };

  const handlePageSize = (e: any) => {
    setFilters((prev: any) => ({ ...prev, limit: e.target.value }));
  };

  const pagecount = Math.ceil(
    TraineeData?.data?.data?.totalCount / filters.limit
  );

  //   if (isLoading) return <CircularProgress />;

  return (
    <>
      <Box sx={{ mt: "2rem" }}></Box>
      <TableFilters filters={filters} setFilters={setFilters} />
      <div
        style={{
          columnGap: "1rem",
          marginTop: "2rem",
        }}
      ></div>
      {TraineeLdg && <CircularProgress />}
      {TraineeData?.data?.data && (
        <Box
          sx={{
            overflowX: "auto",
            borderRadius: "4px",
            position: "relative",
            overflow: "auto",
            whiteSpace: "nowrap",
            "& table": {
              width: "100%",
              border: "1px solid #2d5696",
            },
            "& table thead": {
              background: "#2d5696",
              color: "white",
            },
            "& th, & td": {
              border: "1px solid #2d5696",
              padding: 1,
            },
          }}
        >
          <table>
            <thead>
              <tr>
                {[
                  "Sr.No",
                  "BOCW ID",
                  "Trainee Name",
                  "Mobile Number",
                  "Aadhar Number",
                  "Course Name",
                  "SETWIN ID",
                  "District",
                  "registration app record",
                  "registration app screenshot",
                  "course progress record",
                  "course progress screenshot",
                  "data compensation screenshot",
                  "exam module record",
                  "exam module screenshot",
                  "certificate screenshot",
                  "certificate video",
                  "placement offer",
                  "feedback video",
                ].map((item, index) => (
                  <th key={index}>
                    <Typography fontSize={14} fontWeight={600}>
                      {item}
                    </Typography>
                  </th>
                ))}
                <th
                  style={{
                    minWidth: "150px",
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    background: "#2d5696",
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {TraineeData?.data?.data?.length ? (
                <>
                  {TraineeData?.data?.data?.map((item: any, index: number) => (
                    <tr key={index}>
                      <td align="center">
                        <Typography variant="body2">{item?.id}</Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.bocRegNo}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">{item?.name}</Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">{item?.mobile}</Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.aadharNumber}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.course?.name}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">{item?.formId}</Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.districtBody?.name}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup?.REGISTRATION_APP_RECORD ==
                          null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup?.REGISTRATION_APP_SCREENSHOT ==
                          null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup?.COURSE_PROGRESS_RECORD == null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup?.COURSE_PROGRESS_SCREENSHOT ==
                          null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup
                            ?.DATA_COMPENSATION_SCREENSHOT == null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup?.EXAM_MODULE_RECORD == null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup?.EXAM_MODULE_SCREENSHOT == null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup?.CERTIFICATE_SCREENSHOT == null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup?.CERTIFICATE_VIDEO == null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup?.PLACEMENT_OFFER == null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td align="center">
                        <Typography variant="body2">
                          {item?.traineeFollowup?.FEEDBACK_VIDEO == null
                            ? "No"
                            : "Yes"}
                        </Typography>
                      </td>
                      <td
                        align="center"
                        style={{
                          position: "sticky",
                          right: 0,
                          zIndex: 1,
                          background: "#FDFDFD",
                        }}
                      >
                        <Box display="flex" justifyContent="space-around">
                          <Link
                            className="hover:underline text-primary font-medium"
                            to={`view/${item?.formId}`}
                          >
                            View
                          </Link>
                          {(role == "ADMINISTRATOR" ||
                            "SUPER_ADMIN" ||
                            "SME_ADMIN") && (
                            <Link
                              className="hover:underline text-primary font-medium"
                              to={`edit/${item?.formId}`}
                            >
                              Edit
                            </Link>
                          )}
                        </Box>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <section>No Records Found</section>
              )}
            </tbody>
          </table>

          <Stack
            flexDirection={"row-reverse"}
            alignItems="center"
            marginTop={"1rem"}
            gap={1}
          >
            <Pagination
              count={Math.round(TraineeData?.data?.count / filters.limit)}
              page={filters?.page}
              onChange={handleChange}
            />
            <Typography>Page: {filters?.page}</Typography>
            <FormControl size="small" sx={{ width: "100px" }}>
              <InputLabel id="demo-simple-select-label">Limit</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filters?.limit}
                label="Limit"
                onChange={handlePageSize}
              >
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={250}>250</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default TraineeFollowUp;
