import { Button } from "@mui/material";

export default function ModalButtons({ handleClick, handleClose }: any) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          gap: "1rem",
          marginTop: "1rem",
        }}>
        <Button
          style={{ backgroundColor: "rgb(9, 12, 65)" }}
          color="primary"
          variant="contained"
          onClick={handleClick}>
          Submit
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </div>
    </>
  );
}
