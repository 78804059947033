import { format, secondsToHours } from "date-fns";
import moment from "moment";
import { ReactNode } from "react";

function calculateAge(dob: any) {
  var diff_ms = Date.now() - dob.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const registeredTrainees: {
  accessor: string;
  header: ReactNode;
  Cell?: (value: any) => any;
  sortable?: {
    sort: boolean;
    default: "ASC" | "DESC";
  };
}[] = [
  { accessor: "id", header: "Sr.No." },
  {
    accessor: "bocRegNo",
    header: "BOCW ID",
    sortable: {
      sort: true,
      default: "ASC",
    },
  },
  { accessor: "name", header: "Trainee Name" },
  { accessor: "mobile", header: "Mobile" },
  { accessor: "aadharNumber", header: "aadhaar number" },

  { accessor: "education", header: "Education" },
  {
    accessor: "courseName",
    header: "Course Name",
    Cell: (data: any) => data.name,
  },
  { accessor: "batchNumber", header: "Batch Number" },
  {
    accessor: "courseStartDate",
    header: "course Start Date",
    Cell: (data: any) => (
      <>{data ? moment(new Date(data)).format("yyyy-mm-dd") : "-"}</>
    ),
  },
  { accessor: "setwinId", header: "ID Generated by SETWIN" },

  {
    accessor: "status",
    header: "Status of Application",
    Cell: (data: any) => (
      <>
        {data === true && "Success"}
        {data === false && "Fail"}
        {data === null && "Waiting"}
      </>
    ),
  },
  {
    accessor: "dateOfBirth",
    header: "Age",
    Cell: (data: any) => {
      <>
        {data?.dateOfBirth == null
          ? ""
          : moment(new Date(data?.dateOfBirth)).format("yyyy-mm-dd") ||
            "-"}{" "}
        ,
      </>;
    },
  },
  {
    header: "Gender",
    accessor: "gender",
  },
  { accessor: "workerName", header: "Name of the Worker" },
  {
    accessor: "educationStatus",
    header: "Education Status",
  },
  { accessor: "village", header: "Village" },

  { accessor: "mandal", header: "Mandal" },
  { accessor: "district", header: "District", Cell: (data: any) => data },
];

export const approvedTrainees: {
  accessor: string;
  header: ReactNode;
  Cell?: (value: any) => any;
  sortable?: {
    sort: boolean;
    default: "ASC" | "DESC";
  };
}[] = [
  {
    header: "Sr.No",
    accessor: "id",
  },
  {
    header: "BOCW ID",
    accessor: "bocRegNo",
  },
  {
    header: "Trainee Name",
    accessor: "name",
  },

  {
    header: "Mobile Number",
    accessor: "mobile",
  },
  {
    header: "Education",
    accessor: "higherEducation",
  },
  {
    header: "Course Name",
    accessor: "course.name",
  },
  {
    header: "Batch Number",
    accessor: "batchNumber",
  },
  {
    header: "Course Start Date",
    accessor: "startDate",
  },
  {
    header: "Setwin ID",
    accessor: "formId",
  },
  {
    accessor: "dateOfBirth",
    header: "Age",
    Cell: (data: any) => {
      <>
        {data?.dateOfBirth == null
          ? ""
          : moment(new Date(data?.dateOfBirth)).format("yyyy-mm-dd") ||
            "-"}{" "}
        ,
      </>;
    },
  },
  {
    header: "Gender",
    accessor: "gender",
  },
  {
    header: "Worker/Depedent",
    accessor: "workerName",
  },
  {
    header: "Education Status",
    accessor: "educationStatus",
  },
  {
    header: "Village",
    accessor: "village",
  },
  {
    header: "Mandal",
    accessor: "mandal",
  },
  {
    header: "District",
    accessor: "district",
  },
];

export const timeStatistics = [
  {
    header: "Sr.No",
    accessor: "id",
  },
  {
    header: "BOCW ID",
    accessor: "bocRegNo",
  },
  {
    header: "Trainee Name",
    accessor: "name",
  },

  {
    header: "Mobile Number",
    accessor: "mobile",
  },
  {
    header: "Education Qualification",
    accessor: "higherEducation",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Course Name",
    accessor: "course",
    Cell: (value: any) => value?.name ?? "-",
  },
  {
    header: "Setwin ID",
    accessor: "formId",
  },
  {
    header: "Min Hours to avail 50% bill",
    accessor: "halfCourseTime",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Course Start Date",
    accessor: "startDate",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "50% Course Duration Date",
    accessor: "halfCompletedDate",
    Cell: (value: any) =>
      value ? format(new Date(value), "yyyy-mm-dd") : "null",
  },
  {
    header: (
      <p>
        Actual Hours spent
        <br />
        by the Trainee
      </p>
    ),
    accessor: "timeSpent",
    Cell: (value: any) =>
      (parseInt(value?.timeSpent) / 3600).toFixed(2) ?? "null",
  },
  {
    header: "District",
    accessor: "district",
  },
];

export const courseCompletion = [
  {
    header: "Sr.No",
    accessor: "id",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "BOCW ID",
    accessor: "bocRegNo",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Trainee Name",
    accessor: "name",
    Cell: (value: any) => value ?? "null",
  },

  {
    header: "Mobile Number",
    accessor: "mobile",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Aadhar Number",
    accessor: "aadharNumber",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Education Qualification",
    accessor: "higherEducation",
  },
  {
    header: "Course Name",
    accessor: "course",
    Cell: (value: any) => value?.name ?? "null",
  },
  {
    header: "Setwin ID",
    accessor: "formId",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Min Hours to avail 50% bill",
    accessor: "halfCourseTime",
  },
  {
    header: "Course Start Date",
    accessor: "startDate",
    Cell: (value: any) => {
      return value ? format(new Date(value), "yyyy-MM-dd") : null;
    },
  },
  {
    header: "50% Course Duration Date",
    accessor: "halfCompletedDate",
  },
  {
    header: (
      <p>
        Actual Hours spent
        <br /> by the Trainee
      </p>
    ),
    accessor: "timeSpent",
    Cell: (value: any) =>
      (parseInt(value?.timeSpent) / 3600).toFixed(2) ?? "null",
  },
  {
    header: "District",
    accessor: "district",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "50% Completed",
    accessor: "halfCompleted",
    Cell: (value: any) => value ?? "null",
  },
];

export const certifiedTrainees = [
  {
    header: "Sr.No",
    accessor: "id",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "BOCW ID",
    accessor: "bocRegNo",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Trainee Name",
    accessor: "name",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Mobile Number",
    accessor: "mobile",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Aadhar Number",
    accessor: "aadharNumber",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Education Qualification",
    accessor: "higherEducation",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Course Name",
    accessor: "course",
    Cell: (value: any) => value?.name ?? "null",
  },
  {
    header: "Setwin ID",
    accessor: "formId",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Min Course Duration Hours",
    accessor: "course.minimumCourseTime",
  },
  {
    header: "Course Start Date",
    accessor: "startDate",
  },
  {
    header: "Course End Date",
    accessor: "startDate",
  },
  {
    header: (
      <>
        <p>Actual Hours spent</p>
        <p>by the Trainee</p>
      </>
    ),
    accessor: "timeSpent",
    Cell: (value: any) => secondsToHours(value?.timeSpent) ?? "null",
  },
  {
    header: "District",
    accessor: "district",
    Cell: (value: any) => value ?? "null",
  },
  {
    header: "Certification Status",
    accessor: "certificateStatus",
    Cell: (value: any) => (value ? "Completed" : "In Progress"),
  },
];

export const feedbackData=[
  {
    header: "Sr.No",
    accessor: "form.id",
    // Cell: (value: any) => value ?? "null",
  },
  {
    header: "BOCW ID",
    accessor: "form.bocRegNo",
    // Cell: (value: any) => value ?? "null",
  },
  {
    header: "Trainee Name",
    accessor: "form.name",
    // Cell: (value: any) => value ?? "null",
  },
  {
    header: "Mobile Number",
    accessor: "form.mobile",
    // Cell: (value: any) => value ?? "null",
  },
  {
    header: "Setwin ID",
    accessor: "form.formId",
    // Cell: (value: any) => value ?? "null",
  },
  {
    header: "Course Name",
    accessor: "form.course.name",
  },

];