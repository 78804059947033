import { Box, Button, Dialog, Typography } from "@mui/material";
import { deleteWorkshop } from "api";
import { useMutation, useQueryClient } from "react-query";

function DeleteConfirmDialog({ open, setOpen, data }: any) {
  const queryClient = useQueryClient();

  const handleSure = () => {
    mutate(data?.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { mutate } = useMutation(deleteWorkshop, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("workshopCategory");
    },
    onError: (err: any) => {
      queryClient.invalidateQueries("workshopCategory");
    },
  });

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <Box minWidth="500px" borderRadius="25px">
        <Box display="flex" justifyContent="center" pt={4} pb={2}>
          <img src="/images/delete.gif" alt="" style={{ width: "140px" }} />
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: "center",

          fontSize: "16px",
        }}
      >
        <Typography fontWeight="700">
          Are You Sure You Want To Delete
        </Typography>
        <Typography fontWeight="700">{data?.title}</Typography>
      </Box>

      <Box pt={4} pb={3} display="flex" justifyContent="center" gap={2}>
        <Button variant="outlined" color="info" onClick={handleClose}>
          No, Cancel
        </Button>
        <Button variant="outlined" onClick={handleSure}>
          Yes, I'm Sure
        </Button>
      </Box>
    </Dialog>
  );
}

export default DeleteConfirmDialog;
