import { useEffect, useState } from "react";

export const RegTraineeLoader = () => {
  const [counter, setCounter] = useState(100);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => {
        return prev + 200;
      });
    }, 100);
    return () => clearInterval(timer);
  }, [counter]);
  return <>{counter}</>;
};

export const AppTraineeLoader = () => {
  const [counter, setCounter] = useState(100);
  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => {
        return prev + 50;
      });
    }, 100);
    return () => clearInterval(timer);
  }, []);
  return <>{counter}</>;
};

export const TimeStatsLoader = () => {
  const [counter, setCounter] = useState(50);
  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => {
        return prev + 10;
      });
    }, 100);
    return () => clearInterval(timer);
  }, []);
  return <>{counter}</>;
};
