import {
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect } from "react";
import { usePagination, useSortBy, useTable } from "react-table";

export default function ({
  data,
  page,
  pageCount: noOfPages,
  perPage,
  setFilter,
  columns,
  bgHeaderColor,
  pageLimit
}: any) {
  const headers = React.useMemo(() => columns, []);
  const tableData = React.useMemo(() => {
    return [...data] || [];
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: headers,
      data: tableData,
      initialState: {
        pageIndex: page,
        pageSize: perPage,
      },
      manualPagination: true,
      pageCount: noOfPages,
    },
    useSortBy,
    usePagination
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    gotoPage(value);
    setFilter((prev: any) => ({ ...prev, page: value }));
  };

  const handlePageSize = (e: any) => {
    setPageSize(e.target.value);
    setFilter((prev: any) => ({ ...prev, perPage: e.target.value }));
  };

  useEffect(() => {
    setFilter((prev: any) => ({
      ...prev,
      order: headerGroups[0]?.headers[1]?.isSortedDesc ? "DESC" : "ASC",
    }));
  }, [headerGroups[0]?.headers[1]?.isSortedDesc]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          {...getTableProps()}
          sx={{ border: `1px solid ${bgHeaderColor}` }}
        >
          <TableHead
            sx={{
              "& .MuiTableCell-head": {
                backgroundColor: bgHeaderColor,
              },
            }}
          >
            {headerGroups?.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    sx={{ textAlign: "center", fontWeight: 700 }}
                  >
                    {column.render("header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {data.length == 0 ? (
            <div
              style={{
                textAlign: "center",
                width: "200px",
                padding: "5px",
                position: "relative",
                top: "50%",
                left: "30vw",
                height: "2rem",
              }}
            >
              No Records Found
            </div>
          ) : (
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          sx={{
                            border: `1px solid ${bgHeaderColor}`,
                            textAlign: "center",
                          }}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Stack
        flexDirection={"row-reverse"}
        alignItems="center"
        marginTop={"1rem"}
        gap={1}
      >
        <Pagination
          count={pageCount}
          page={pageIndex}
          onChange={handleChange}
        />
        <Typography>Page: {pageIndex}</Typography>
        <FormControl size="small" sx={{ width: "100px" }}>
          <InputLabel id="demo-simple-select-label">Limit</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pageSize}
            label="Limit"
            onChange={handlePageSize}
          >
            {
              pageLimit ? (pageLimit.map((item:any)=>(
                <MenuItem value={item}>{item}</MenuItem>
              ))
              ):
              [100,250,500].map((item:any)=>(
                <MenuItem value={item}>{item}</MenuItem>
              ))

            }
          </Select>
        </FormControl>
      </Stack>
    </>
  );
}
