import http from "utils/http";

function getDistricts() {
  return http.get(`/user/my-districts`);
}

function getWorkshopDistricts() {
  return http.get(`/workshop/districts`);
}

function getCategory() {
  return http.get(`/trainee-followup/category`);
}

function getStats({ queryKey }: any) {
  return http.get(`/stats`, { params: { ...queryKey[1] } });
}

function getStatistics({ queryKey }: any) {
  console.log("QueryKeyy",queryKey);
  return http.get("/statistics/dashboard", { params: { ...queryKey[1] } });
}

function exportRegisteredUsers(data: any) {
  return http.post(`/registration-form/export`, data);
}

function getEnrolledUsers({ queryKey }: any) {
  return http.get(`/stats/enrolled-users`, { params: { ...queryKey[1] } });
}

function getTimeStatistics({ queryKey }: any) {
  return http.get(`/stats/enrolled-users`, { params: { ...queryKey[1] } });
}

function exportEnrolledUsers(data: any) {
  return http.post(`/stats/enrolled-users/export`, data);
}

function addHoursSpent(data: any) {
  return http.post(`/stats/time-statistics/add`, data);
}

function exportTimeStatistics(data: any) {
  return http.post(`/stats/time-statistics/export`, data);
}

function bulkUpdateTimeStatistics(data: any) {
  return http.post(`/stats/time-statistics/bulk-update`, data);
}

function getHalfCompletedUsers({ queryKey }: any) {
  return http.get(`/stats/half-completed`, { params: { ...queryKey[1] } });
}

function exportHalfCompleted(data: any) {
  return http.post(`/stats/half-completed/export`, data);
}

function getCertifiedTrainees({ queryKey }: any) {
  return http.get(`/stats/certified-trainees`, { params: { ...queryKey[1] } });
}

function exportCertifiedTrainees(data: any) {
  return http.post(`/stats/certified-trainees/export`, data);
}

function bulkUpdateCertifiedTrainees(data: any) {
  return http.post(`/stats/certified-trainees/bulk-update`, data);
}

function uploadCertificate(data: any) {
  return http.post(`/stats/certified-trainees/upload-certificate`, data);
}

function updateRegisteredTrainee({ id, data }: any) {
  return http.patch(`/registration-form/update/${id}`, data);
}

function updateDistrictCourseDetails({ id, data }: any) {
  return http.put(`/batch-course-details/${id}`, data);
}

function createDistrictCourseDetails({ data }: any) {
  return http.post("/batch-course-details", data);
}

function getDistrictCourseDetails({ queryKey }: any) {
  return http.get(`/batch-course-details`, { params: queryKey[1] });
}

function getCourses({ queryKey }: any) {
  return http.get("/course", { params: { name: queryKey[1] } });
}

function updateCourse({ id, data }: any) {
  return http.put(`/course/${id}`, data);
}

function addCourse({ data }: any) {
  return http.post("/course", data);
}

function taineeFollowUp({ queryKey }: any) {
  return http.get("/trainee-followup/users", { params: { ...queryKey[1] } });
}

function uploadMedia(formData: any) {
  return http.post(`/trainee-followup/upload`, formData);
}

function deleteMedia(key: any) {
  return http.delete(`/media-object`, {
    params: { key: key, subtype: "IMAGE" },
  });
}

function UpdateOrder(data: any) {
  return http.put(`/workshop/update-order`, data);
}

function uploadWorkshopMedia(data: any) {
  return http.post("/media-object", data);
}

function deleteWorkshop(id: any) {
  return http.delete(`/workshop/${id}`);
}

function deleteTraineefollowup(id: any) {
  return http.delete(`/trainee-followup/delete/${id}`);
}

function deleteMediaAudio(key: any) {
  http.delete(`/media-object`, { params: { key: key, subtype: "AUDIO" } });
}

function deleteMediaVideo(key: any) {
  return http.delete(`/media-object`, {
    params: { key: key, subtype: "VIDEO" },
  });
}

function workshopUploadMedia(data: any) {
  return http.post(`workshop-storage/upload-file`, data);
}

function workshopUploadVideo(data: any) {
  return http.post(`workshop-storage/add-video-url`, data);
}

function uploadMediaByformId({ data, id }: any) {
  return http.patch(`/trainee-followup/${id}`, data);
}

function createWorkshop(data: any) {
  return http.post("/workshop", data);
}
function uploadMediaWorkshop({ data, id }: any) {
  return http.patch(`/workshop/${id}`, data);
}
function getWorkshop({ queryKey }: any) {
  return http.get(`/workshop`, { params: { ...queryKey[1] } });
}
function bulkUpload(data: any) {
  return http.post(`storage/upload-file`, data);
}

function traineeUploadMedia(data: any) {
  return http.post(`trainee-followup/upload-file`, data);
}

function getFormbyid({ queryKey }: any) {
  return http.get(`/registration-form/${queryKey[1]}`);
}
function getStorage({ queryKey }: any) {
  return http.get(`/storage`, {
    params: {
      search: queryKey[1] || "",
    },
  });
}

function getTraineeStorage({ queryKey }: any) {
  return http.get(`/trainee-followup`, {
    params: {
      folderId: queryKey[1] || "",
      search: queryKey[2] || "",
    },
  });
}

function getWorkshopStorage({ queryKey }: any) {
  return http.get(`/workshop-storage`, {
    params: { folderId: queryKey[1] || "" },
  });
}

function getStorageLog({ queryKey }: any) {
  return http.get("storage-log", {
    params: {
      startDate: queryKey[1] == "Invalid date" ? "" : queryKey[1],
      endDate: queryKey[2] == "Invalid date" ? "" : queryKey[2],
      category: queryKey[3],
      offset: (queryKey[4] - 1) * queryKey[5],
      limit: queryKey[5],
    },
  });
}

function getStoragefolder({ queryKey }: any) {
  return http.get(`/storage`, {
    params: {
      search: queryKey[3] || "",
      folderId: queryKey[1],
      limit: 500,
      offset: queryKey[2] == 1 ? 0 : (queryKey[2] - 1) * 500,
    },
  });
}

function getStorageTraineefolder({ queryKey }: any) {
  return http.get(`/storage`, {
    params: {
      category: "TRAINEE_FOLLOW_UP",
      folderId: queryKey[1],
      limit: 30,
      offset: queryKey[2] == 1 ? 0 : (queryKey[2] - 1) * 30,
    },
  });
}

function getStorageTraineefile({ queryKey }: any) {
  return http.get(`/storage/media`, {
    params: {
      search: queryKey[2],
      folderId: queryKey[1],
    },
  });
}

function createStorage(data: any) {
  return http.post(`/workshop-storage`, data);
}

function CertificateCreateBatch(data: any) {
  return http.post(`storage`, data);
}

function createTraineeFolder(data: any) {
  return http.post(`/trainee-followup`, data);
}

function certifiedDownload(data: any) {
  return http.get("stats/certified-trainees/download-forms", {
    params: {
      ...data,
    },
  });
}

function getFeedbacks({ queryKey }: any) {
  return http.get(`/feedback`, { params: { ...queryKey[1] } });
}

function getOneFeedbackDetails({queryKey}:any){
  return http.get(`/feedback/${queryKey[1]}`);
}

function updateFeedbackDetails({ id, body }: any) {
  return http.put(`/feedback/${id}`, body);
}

function exportFeedbackData({queryKey}: any) {
  return http.get(`/feedback/export`, { params: { ...queryKey[1] } });
}

function getFeedbackStatistics({queryKey}:any) {
  return http.get(`/feedback/statistics`,{ params: { ...queryKey[1] } });
}

function bulkUpdateFeedbackData(data: any) {
  return http.post(`/feedback/bulk-update`, data);
}

export {
  UpdateOrder,
  getWorkshopDistricts,
  certifiedDownload,
  CertificateCreateBatch,
  getTraineeStorage,
  traineeUploadMedia,
  uploadWorkshopMedia,
  createTraineeFolder,
  getStorageTraineefile,
  deleteWorkshop,
  getStorageLog,
  workshopUploadVideo,
  workshopUploadMedia,
  getWorkshopStorage,
  getStorageTraineefolder,
  getCategory,
  createStorage,
  getStoragefolder,
  getStatistics,
  deleteTraineefollowup,
  getStorage,
  deleteMediaAudio,
  getFormbyid,
  deleteMediaVideo,
  deleteMedia,
  bulkUpload,
  uploadMediaWorkshop,
  getWorkshop,
  createWorkshop,
  uploadMediaByformId,
  uploadMedia,
  exportRegisteredUsers,
  getEnrolledUsers,
  getCourses,
  updateCourse,
  getHalfCompletedUsers,
  addCourse,
  createDistrictCourseDetails,
  getCertifiedTrainees,
  exportCertifiedTrainees,
  updateDistrictCourseDetails,
  updateRegisteredTrainee,
  getStats,
  exportEnrolledUsers,
  exportTimeStatistics,
  bulkUpdateTimeStatistics,
  getTimeStatistics,
  addHoursSpent,
  exportHalfCompleted,
  bulkUpdateCertifiedTrainees,
  uploadCertificate,
  getDistricts,
  getDistrictCourseDetails,
  taineeFollowUp,
  getFeedbacks,
  getOneFeedbackDetails,
  updateFeedbackDetails,
  exportFeedbackData,
  getFeedbackStatistics,
  bulkUpdateFeedbackData,
};
