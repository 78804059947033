import { Close } from "@mui/icons-material";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { CertificateCreateBatch, createTraineeFolder, getDistricts } from "api";
import Loader from "components/Loader";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  data?: any;
  trineefollowupStorage?: boolean;
}

const district = {
  id: "",
  name: "",
  code: "",
  orgId: "",
};

function Adddistricts({ open, setOpen, trineefollowupStorage }: Props) {
  const [state, setState] = useState<any>();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data: districtData, isLoading } = useQuery("districts", getDistricts);

  const { mutate } = useMutation(CertificateCreateBatch, {
    onSuccess: () => {
      queryClient.invalidateQueries("storage");
      setOpen(false);
      setState("");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: mutateTraineefollowup } = useMutation(createTraineeFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries("traineefollowupstorage");
      setOpen(false);
      setState("");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleChange = (e: any) => {
    setState(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const data = {
      name: state?.name,
      parentId: id,
      type: "FOLDER",
    };
    if (trineefollowupStorage) {
      mutateTraineefollowup(data);
    } else {
      mutate(data);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Drawer
      anchor="right"
      sx={{ zIndex: 1202 }}
      PaperProps={{ sx: { width: 400 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <AppBar position="sticky">
        <Toolbar
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1"> Add Districts Folder</Typography>
          <IconButton onClick={() => setOpen(false)} sx={{ color: "white" }}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            fullWidth
            options={districtData?.data || []}
            getOptionLabel={(option: any) => option?.name || ""}
            onChange={(event, value: any) => {
              setState(value);
            }}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select District"
              />
            )}
          />

          <Box mt={2} textAlign="right">
            <Button
              style={{ backgroundColor: "#f50000" }}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
}

export default Adddistricts;
