// @ts-nocheck
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  deleteMedia,
  deleteMediaAudio,
  deleteMediaVideo,
  deleteTraineefollowup,
  uploadMedia,
} from "api";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function UploadTraineeFiles({
  title,
  state,
  handleRadio,
  type,
  mediaName,
  setKeys,
  URL,
}: any) {
  const queryClient = useQueryClient();
  const [files, setFiles] = useState<File | null>(null);
  const [vediourl, setVediourl] = useState("");
  const [err, setErr] = useState("");
  const params = useParams();
  const { id } = params;

  const handleChange = (e: any) => {
    setFiles(e.target.files[0]);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", type);
    formData.append("subtype", state);
    formData.append("formId", id);

    mutate(formData);
  };

  const imageURL = URL?.type == "IMAGE" ? URL?.url : null;
  const vedioURL = URL?.type == "VIDEO" ? URL?.url : null;
  const audioURL = URL?.type == "AUDIO" ? URL?.url : null;

  const radio =
    imageURL != null ? imageURL : audioURL != null ? audioURL : vedioURL;

  useEffect(() => {
    if (imageURL != null) {
      handleRadio("IMAGE");
    } else if (audioURL != null) {
      handleRadio("AUDIO");
    } else if (vedioURL != null) {
      handleRadio("VIDEO");
    }
  }, [imageURL, audioURL, vedioURL]);

  function isUrlValid(userInput) {
    var res = userInput.match(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    );
    if (res == null) return false;
    else return true;
  }

  const { mutate } = useMutation(uploadMedia, {
    onSuccess: (res: any) => {
      toast.success("Media Uploaded successfully ");
      if (state === "VIDEO") {
        if (isUrlValid(vediourl)) {
          setKeys((prev) => [...prev, res.data?.id]);
          setErr("");
        } else {
          setErr("Invalid URL");
        }
      } else {
        setKeys((prev) => [...prev, res.data?.mediaObject?.id]);
      }
      queryClient.invalidateQueries("traineefollowupbyid");
    },

    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: deletemutate } = useMutation(deleteTraineefollowup, {
    onSuccess: (res: any) => {
      toast.error("Media Deleted successfully ");
      queryClient.invalidateQueries("traineefollowupbyid");
    },

    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleDelete = () => {
    deletemutate(URL?.id);
  };

  const handleDeleteAudio = () => {
    deletemutate(URL?.id);
  };

  const handleDeleteVideo = () => {
    deletemutate(URL?.id);
  };

  const handleVedio = () => {
    let formData = {
      url: vediourl,
      type: type,
      subtype: state,
      formId: id,
    };

    mutate(formData);
  };

  return (
    <>
      <Box mt={4} maxWidth="400px">
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight="600">{title}</Typography>
        </Box>
        <Box
          sx={{
            mt: 1,
            border: "1px solid #2D5696",
            maxHeight: "2px",
            maxWidth: "250px",
          }}
        ></Box>
      </Box>

      <Typography
        sx={{ mt: 1, color: "#222222", opacity: 0.5, fontSize: "14px" }}
      >
        Select Media
      </Typography>
      <Box>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={state}
            onChange={(e: any) => handleRadio(e.target.value)}
          >
            <FormControlLabel
              disabled={radio == null ? false : true}
              value="IMAGE"
              control={<Radio />}
              label="Image"
            />
            <FormControlLabel
              disabled={radio == null ? false : true}
              value="AUDIO"
              control={<Radio />}
              label="Audio"
            />
            <FormControlLabel
              disabled={radio == null ? false : true}
              value="VIDEO"
              control={<Radio />}
              label="Video"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {state === "IMAGE" &&
        (imageURL == null ? (
          <Box width="400px">
            <input
              type="file"
              onChange={handleChange}
              multiple={false}
              name="file"
              accept="image/jpg, image/jpeg, image/png"
              id={type}
              style={{ display: "none" }}
            />
            <label
              htmlFor={type}
              style={{
                display: "inline",
                width: "400px",
                height: "150px",
                backgroundColor: "red",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  maxWidth: "400px",
                  minHeight: "150px",
                  border: "1px dashed grey",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "10px",
                  display: "flex",
                  position: "relative",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/images/image-icon.png"
                  width="40px"
                  style={{ marginLeft: "20px" }}
                ></img>
                <Typography sx={{ fontSize: "14px", opacity: 0.5 }}>
                  Upload image
                </Typography>
              </Box>
            </label>
          </Box>
        ) : (
          <Box minWidth="350px" mt="15px">
            <Card sx={{ maxWidth: 380 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {imageURL && (
                  <CardMedia
                    sx={{
                      height: 200,
                      width: "200px",
                    }}
                    image={imageURL}
                    title="green iguana"
                  />
                )}
              </Box>

              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography>{type}</Typography>
                  <Box mr={2}>
                    <button onClick={handleDelete}>
                      <img
                        src="/images/delete.png"
                        width="15px"
                        height="15px"
                      />
                    </button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        ))}
      {state == "AUDIO" &&
        (audioURL == null ? (
          <Box width="400px">
            <input
              type="file"
              onChange={handleChange}
              accept=".mp3,audio/*"
              name="upload"
              id="upload"
              style={{ display: "none" }}
            />
            <label htmlFor="upload">
              <Box
                sx={{
                  maxWidth: "400px",
                  minHeight: "150px",
                  border: "1px dotted grey",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src="/images/audio.png" width="24px"></img>
                <Typography
                  sx={{ fontSize: "14px", opacity: 0.5, paddingTop: "4px" }}
                >
                  Upload Audio file
                </Typography>
              </Box>
            </label>
          </Box>
        ) : (
          <Box minWidth="350px" mt="15px">
            <Card sx={{ maxWidth: 380 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "25px",
                }}
              >
                {audioURL && (
                  <audio controls>
                    <source src={audioURL} type="audio/mpeg" width="100px" />
                  </audio>
                )}
              </Box>

              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography>{type}</Typography>
                  <Box mr={2}>
                    <button onClick={handleDeleteAudio}>
                      <img
                        src="/images/delete.png"
                        width="15px"
                        height="15px"
                      />
                    </button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        ))}
      {state == "VIDEO" &&
        (vedioURL == null ? (
          <Box
            sx={{
              maxWidth: "400px",
              minHeight: "150px",
              border: "1px dotted grey",
              backgroundColor: "#F2F2F2",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                pt: "30px",
                pl: "30px",
                display: "flex",
              }}
            >
              <Box>
                <img src="/images/vedio.png" width="20px"></img>
              </Box>
              <Typography
                sx={{ fontSize: "14px", opacity: 0.5, paddingLeft: "15px" }}
              >
                Enter Video URL
              </Typography>
            </Box>
            <Box display="flex">
              <TextField
                sx={{ pl: "30px", pr: "25px" }}
                id="standard-basic"
                fullWidth
                variant="standard"
                onChange={(e: any) => setVediourl(e.target.value)}
              />
            </Box>
            <Button
              style={{
                margin: "20px",
                backgroundColor: "#2D5696",
                color: "#FFFFFF",
                padding: "0px 10px",
              }}
              onClick={handleVedio}
            >
              submit
            </Button>
          </Box>
        ) : (
          <Box minWidth="350px" mt="15px">
            <Card sx={{ maxWidth: 380 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {vedioURL &&
                  (vedioURL?.includes("youtube.com") ? (
                    <iframe
                      width="380px"
                      height="250px"
                      style={{ borderRadius: "10px" }}
                      src={vedioURL.replace("watch?v=", "embed/")}
                    ></iframe>
                  ) : vedioURL?.includes("vimeo.com") ? (
                    <iframe
                      src={vedioURL}
                      width="380px"
                      height="250px"
                      style={{ borderRadius: "10px" }}
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  ) : (
                    <video width="380px" height="120px" controls>
                      <source src={vedioURL} type="video/mp4" />
                    </video>
                  ))}
              </Box>

              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography>{type}</Typography>
                  <Box mr={2}>
                    <button onClick={handleDeleteVideo}>
                      <img
                        src="/images/delete.png"
                        width="15px"
                        height="15px"
                      />
                    </button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        ))}
      {err != "" && <Typography style={{ color: "red" }}>{err}</Typography>}
      {title == "Feedback" && <Box mb="50px"></Box>}
    </>
  );
}

export const StyledFileChip = styled("div")(() => ({
  background: "rgba(0,0,0,0.2)",
  borderRadius: "2px",
  display: "flex",
  alignItems: "center",
  gap: 10,
  padding: "0.3rem 0.6rem",
}));
