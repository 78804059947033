import {
  Box,
  Button,
  Card,
  CircularProgress,
  createTheme,
  ThemeProvider
} from "@mui/material";
import { getStatistics } from "api";
import BarChart3d from "components/BarChart3d";
import { AppTraineeLoader } from "components/CounterLoader";
import { GridContainer, GridContext } from "components/Grid";
import Header from "components/Header";
import { ApplicationContent } from "pages/stats";
import { useState } from "react";
import { useQuery } from "react-query";
import theme from "styles/theme";
import http from "utils/http";
import Table from "./Table";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from "react-router-dom";

const themeOption = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: "#0c9fe4",
    },
  },
});

const FeedbackStatistics = () => {
  const [district, setDistrict] = useState("");
  const [batch, setBatch] = useState("ALL");
  const navigate=useNavigate()

  const { data: batchs } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const { data: StatisticsData, isLoading: StatisticsLoading } = useQuery(
    [
      "feedbackstatistics",
      {
        batch,
        districtId: district,
        type: "FEEDBACK",
        educationData: true,
      },
    ],
    getStatistics
  );


  const handleChange = (e: any) => {
    setBatch(e.target.value);
  };
  const handleViewStatistics=()=>{
    navigate("view-statistics")

  }

  return (
    <ThemeProvider theme={themeOption}>
      <Header
        batch={batch}
        showDistrictFilter
        district={district}
        setBatch={setBatch}
        handleChange={handleChange}
        onChange={(v: string) => setDistrict(v)}
      />
      <GridContainer>
        <Card
          sx={{
            boxShadow: "0px 6px 16px #2146831f",
            borderRadius: "5px",
            marginBottom: "2rem",
            width: "100%",
            height: 450,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop:"10px",
              paddingRight:"5px"
            }}
          >
            <Button
              variant="outlined"
              sx={{ color: "#1E4B8F", border: "1px solid #1E4B8F",marginBottom:"10px",fontWeight:"600"}}
              startIcon={<RemoveRedEyeIcon />}
              onClick={handleViewStatistics}
            >
              View Statistics
            </Button>
          </Box>
          {StatisticsData ? (
            <Box sx={{ flexGrow: 1 }}>
              <BarChart3d
                data={StatisticsData?.data?.educationData.map(
                  ({ educationName, userCount }: any) => ({
                    label: educationName,
                    value: userCount,
                    color: (() => {
                      switch (educationName) {
                        case "10th":
                          return "#2d5696";
                        case "Inter":
                          return "#FFA500";
                        case "ITI":
                          return "#FF69B4";
                        case "Polytechnic":
                          return "#0c9fe4";
                        case "Degree":
                          return "#20a87d";
                        case "Engineering":
                          return "#7461c9";
                        default:
                          return "#000000"; 
                      }
                    })(),
                  })
                )}
                xAxisName={"Education Qualification"}
                yAxisName={"Feedback Given By Students"}
                caption={"Feedback Given By Students"}
                unitY={""}
                barColor={"#20a87d"}
              />
            </Box>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />{" "}
            </div>
          )}
        </Card>
        <GridContext>
          <ApplicationContent
            title={"Feedback Given Trainees"}
            count={
              StatisticsData ? (
                StatisticsData?.data?.count
              ) : (
                <AppTraineeLoader />
              )
            }
            src={`/images/feedback-survey.gif`}
            alt={"feedback-survey-icon"}
            color={"#1E4B8F"}
          />
        </GridContext>
      </GridContainer>
      <Table distCode={district} batch={batch} setDistrict={setDistrict} />
    </ThemeProvider>
  );
};

export default FeedbackStatistics;


