import { Box, CircularProgress, Typography } from "@mui/material";
import { getWorkshopDistricts, getWorkshopStorage } from "api";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import "swiper/css";
import "swiper/css/pagination";

import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { getDistricts } from "api";
import { AlertDialog } from "pages/traineefollow-up/TraineeComponents";
import { CreateWorkshop } from "pages/workshop/CreateWorkshopDilog";
import File from "pages/workshop/File";
import { useNavigate, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import http from "utils/http";
import AddWorkshopDialog from "./AddworkshopDialog";

interface Idist {
  id: number;
  name: string;
  code: string;
  orgId: number;
}

function Workshop() {
  const [batch, setBatch] = useState("");
  const [district, setDistrict] = useState<Idist>();
  const [category, setCategory] = useState("CAREER GUIDANCE AND RESUME MAKING");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [unit, setUnit] = useState(0);
  const [url, setUrl] = useState("");
  const [workshopstate, setWorkshopState] = useState("");
  const { id } = useParams();
  const [openworkshop, setOpenWorkshop] = useState(false);

  const handleChange = (e: any) => {
    setBatch(e.target.value);
  };

  const { data: batchs, isLoading: batchLdg } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const { data: DistrictData, isLoading: districtsLoding } = useQuery(
    "workshop-districts",
    getWorkshopDistricts
  );

  const Currentbatch = batchs?.find((item: any) => item?.currentBatch == false);

  const {
    data: WorkshopSubData,
    isLoading: LdgWorkshop,
    isFetching,
  } = useQuery(
    ["workshopCategory", batch, category, district?.id],
    () =>
      http.get(
        `/workshop?batch=${batch}&category=${category}&district=${
          district?.id || ""
        }`
      ),
    {
      enabled: batch == "" ? false : true,
    }
  );

  const { data: Categories, isLoading: CategoriesLdg } = useQuery(
    "workshopcategory",
    () => http.get("/workshop/category").then((res) => res.data)
  );

  const style = {
    "@media (max-width: 1200px)": {
      display: "none",
    },
  };

  useEffect(() => {
    setBatch(Currentbatch?.batch || "");
  }, [Currentbatch]);

  useEffect(() => {
    if (WorkshopSubData) {
      setWorkshopState(WorkshopSubData?.data?.data[0]?.id || "");
    }
  }, [WorkshopSubData]);

  if (LdgWorkshop || CategoriesLdg) return <CircularProgress />;

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        mb={2}
        mt={4}
        mr={2}
        gap={2}
      >
        <Box>
          <TextField
            onChange={handleChange}
            select
            value={batch}
            label={Currentbatch?.batch || "Select Batch"}
            size="small"
            sx={{
              minWidth: 200,
              bgcolor: "#FFF",
              borderRadius: "5px",
              marginRight: 1,
              "@media (max-width:600px)": {
                minWidth: 125,
              },
            }}
          >
            <MenuItem value={""}>All</MenuItem>
            {batchs?.map((item: any, index: number) => (
              <MenuItem key={index} value={item?.batch}>
                {item?.batch}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box minWidth="200px">
          <Autocomplete
            sx={{
              bgcolor: "#FFF",
              borderRadius: "5px",
            }}
            fullWidth
            value={district}
            options={DistrictData?.data || []}
            getOptionLabel={(option: any) => option?.name || ""}
            filterSelectedOptions
            onChange={(event, value: any) => {
              setDistrict(value);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="District" />
            )}
          />
        </Box>
        <Box>
          <TextField
            sx={{ minWidth: "200px", bgcolor: "#FFF", borderRadius: "5px" }}
            select
            value={category}
            label="Select Category"
            size="small"
            onChange={(e: any) => setCategory(e.target.value)}
          >
            {Categories?.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Button
          style={{ color: " #FFF", backgroundColor: "  #002058" }}
          onClick={() => setOpenWorkshop(true)}
        >
          Add Workshop
        </Button>
      </Box>

      {isFetching ? (
        <Loader />
      ) : WorkshopSubData?.data?.data?.length > 0 ? (
        <Box mt={4}>
          <Grid container spacing={2}>
            {WorkshopSubData?.data?.data?.map((item: any) => (
              <Grid item key={item?.id} xs={4}>
                <File data={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{}}>
            <img
              src="/images/workshopempty.png"
              style={{ width: "250px", height: "160px", objectFit: "fill" }}
            />
            <Typography
              style={{ color: "red", marginTop: "10px", paddingLeft: "45px" }}
            >
              No Records Found
            </Typography>
          </Box>
        </Box>
      )}

      <AlertDialog open={open} setOpen={setOpen} url={url} />
      <CreateWorkshop open={openworkshop} setOpen={setOpenWorkshop} />
    </Box>
  );
}

export const TitleCard = ({ title, color }: any) => {
  return (
    <Box mb={2} maxWidth="400px">
      <Box display="flex" justifyContent="space-between">
        <Typography fontWeight="600" fontSize="18px">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: "2px",
          bgcolor: ` ${color}`,
          height: "6px",
          width: "40px",
          borderRadius: "5px",
        }}
      ></Box>
    </Box>
  );
};

function WorkshopStorage() {
  return (
    <Box>
      <Workshop />
    </Box>
  );
}

export default WorkshopStorage;
