import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Stack, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import { useQuery } from "react-query";
import http from "utils/http";
import ModalButtons from "./components/ModalButtons";

const initState = {
  username: "",
  name: "",
  mobile: "",
  email: "",
  password: "",
  role: "",
  profileImage: null,
};

export default function UserDetailsEdit({ id, refetchUsers }: any) {
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(initState);

  const { data: userRoles } = useQuery(
    "user-roles",
    () => http.get("user/roles").then((res) => res.data),
    {
      enabled: !!open,
    }
  );
  const { data: userById } = useQuery(
    ["single-user", open],
    () =>
      http.get(`user/${id}`).then((res) => {
        setUser({ ...res.data, password: null });
        return res.data;
      }),
    {
      enabled: !!open,
    }
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleChange(e: any) {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }

  function handleClick(e: any) {
    e.preventDefault();
    http
      .put(`/user/${id}`, {
        ...user,
      })
      .then((res) => {
        refetchUsers();
        handleClose();
      })
      .catch((err) => {});
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen}>
        <EditIcon></EditIcon>
      </IconButton>
      <Dialog open={open} fullWidth onClose={handleClose}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <Stack spacing={2} padding={2}>
            <StyledTextField
              label="Username"
              value={user.username}
              name="username"
              onChange={handleChange}
            />
            <StyledTextField
              label="Name"
              value={user.name}
              name="name"
              onChange={handleChange}
            />
            <StyledTextField
              label="Mobile"
              value={user.mobile}
              name="mobile"
              onChange={handleChange}
            />
            <StyledTextField
              label="Email"
              value={user.email}
              name="email"
              onChange={handleChange}
            />
            <StyledTextField
              label="New Password"
              name="password"
              onChange={handleChange}
            />
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small">Role</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={user.role}
                label="Role"
                onChange={handleChange}
                name="role"
              >
                {userRoles?.map((role: any) => (
                  <MenuItem value={role}>{role.split("_").join(" ")}</MenuItem>
                ))}
              </Select>
              <ModalButtons
                handleClick={handleClick}
                handleClose={handleClose}
              />
            </FormControl>
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const StyledTextField = (props: any) => (
  <TextField {...props} variant="outlined" size="small" />
);
