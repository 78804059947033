import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import http from "utils/http";
import ModalButtons from "./components/ModalButtons";

const initState = [];
export default function AssignDistricts({ id }: any) {
  const [open, setOpen] = React.useState(false);
  const [selectedDist, setSelectedDist] = useState<object[]>([]);
  const { data: allDistricts } = useQuery(
    ["districts", open],
    () => http.get("/user/my-districts").then((res) => res.data),
    {
      enabled: !!open,
    }
  );

  const { data } = useQuery(
    ["userDists", open],
    () => http.get(`/user/${id}/districts`).then((res) => res.data),
    {
      onSuccess: (data) => {
        setSelectedDist(data);
      },
      enabled: !!open,
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleClick() {
    http
      .post("/user/assign-districts", {
        userId: id,
        districtCodes: selectedDist.map((dist: any) => dist.code),
      })
      .then((res) => {
        return res.data;
      })
      .finally(() => {
        handleClose();
      });
  }

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <AssignmentIcon></AssignmentIcon>
      </IconButton>
      <Dialog open={open} fullWidth onClose={handleClose}>
        <DialogTitle>Assign Districts</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <FormLabel>District</FormLabel>
            <Autocomplete
              fullWidth
              multiple
              value={selectedDist || []}
              options={allDistricts || []}
              getOptionLabel={(option: any) => option?.name || ""}
              filterSelectedOptions
              onChange={(event, value: any) => {
                setSelectedDist([...value]);
              }}
              isOptionEqualToValue={(option, value) => {
                return option.code === value.code;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" placeholder="District" />
              )}
            />
            <ModalButtons handleClick={handleClick} handleClose={handleClose} />
          </FormControl>
        </DialogContent>
      </Dialog>
    </>
  );
}
