import { Close } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createDistrictCourseDetails } from "api";
import { Dayjs } from "dayjs";
import moment from "moment";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import http from "utils/http";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  data?: any;
}

function AddDistrictCourse({ open, setOpen, data }: Props) {
  const [state, setState] = useState<any>({});
  const [value, setValue] = useState<Dayjs | null>(null);
  const queryClient = useQueryClient();

  const { data: districts } = useQuery("district", () =>
    http.get("/district").then((res) => res.data)
  );

  const { data: batchs } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const { mutate } = useMutation(createDistrictCourseDetails, {
    onSuccess: () => {
      toast.success("Created Succesfully");
      queryClient.invalidateQueries("district-course-details");
      setOpen(false);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    mutate({
      data: { ...state, startDate: moment(`${value}`).format("YYYY-MM-DD") },
    });
  };

  return (
    <Drawer
      anchor="right"
      sx={{ zIndex: 1202 }}
      PaperProps={{ sx: { width: 400 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <AppBar position="sticky">
        <Toolbar
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">
            {" "}
            Add District Course Details
          </Typography>
          <IconButton onClick={() => setOpen(false)} sx={{ color: "white" }}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            size="small"
            name="district"
            label="District"
            value={state?.district}
            select
            onChange={handleChange}
          >
            {districts?.map((item: any) => (
              <MenuItem value={item.name}>{item?.name}</MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            size="small"
            name="batch"
            label="Batch"
            value={state?.batch}
            select
            onChange={handleChange}
          >
            {batchs?.map((item: any) => (
              <MenuItem value={item?.batch}>{item?.batch}</MenuItem>
            ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => (
                <TextField sx={{ mt: 2 }} size="small" fullWidth {...params} />
              )}
            />
          </LocalizationProvider>
          <Box mt={2} textAlign="right">
            <Button
              style={{ backgroundColor: "#f50000" }}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
}

export default AddDistrictCourse;
