import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const StyledCards = styled(Box)(({ theme }) => ({
  boxShadow: "0px 6px 16px #2146831f",
  borderRadius: "5px",
  marginBottom: "2rem",
  display: "flex",
  alignItems: "center",
  // padding: "0.5rem",
  justifyContent: "space-evenly",
}));
