import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  Typography,
} from "@mui/material";
import AWS from "aws-sdk";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default function TraineeComponents() {
  return <></>;
}

export function CustomMedia({ type, url }: any) {
  const [audio, setAudio] = useState(false);
  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState(false);
  const handleimagePreview = (url: any) => {
    setOpen(true);
  };

  const handleaudioPreview = (url: any) => {
    setAudio(true);
  };
  const handlevideoPreview = () => {
    setVideo(true);
  };

  let a = url?.replace("watch?v=", "embed/");
  if (a.search("&")) {
    a = a.split("&")[0].trim();
  }

  return (
    <>
      {type == "IMAGE" ? (
        <Box
          maxWidth="300px"
          height="270px"
          onClick={() => handleimagePreview(url)}
        >
          <img
            style={{ height: "100%", width: "100%", borderRadius: "10px" }}
            src={url}
            alt="app instalation"
          />
        </Box>
      ) : type == "AUDIO" ? (
        <Box
          onClick={() => handleaudioPreview(url)}
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxWidth="300px"
          height="270px"
          border="1px solid #C1BBBA"
          borderRadius="15px"
        >
          <audio controls>
            <source
              src={url}
              type="audio/mpeg"
              style={{ width: "100%", height: "100%" }}
            />
          </audio>
        </Box>
      ) : (
        type == "VIDEO" && (
          <Box
            height="270px"
            maxWidth="300px"
            zIndex={1}
            onClick={() => handlevideoPreview()}
          >
            {url?.includes("youtube") ? (
              <iframe
                src={a}
                style={{
                  borderRadius: "10px",
                  width: "100%",
                  height: "220px",
                }}
              ></iframe>
            ) : url?.includes("vimeo.com") ? (
              <iframe
                src={url}
                width="250px"
                height="220px"
                style={{ borderRadius: "20px" }}
                allow="autoplay; fullscreen; picture-in-picture"
              ></iframe>
            ) : (
              <video width="260px" height="220px" controls>
                <source src={url} type="video/mp4" />
              </video>
            )}
          </Box>
        )
      )}
      <AlertDialog open={open} setOpen={setOpen} url={url} />
      <AudioDilog open={audio} setOpen={setAudio} url={url} />
      <VideoDilog open={video} setOpen={setVideo} url={url} />
    </>
  );
}

export function VideoDilog({ open, setOpen, url }: any) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Box
        sx={{
          display: "flex",
          m: "20px 0px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {url.includes("youtube.com") ? (
          <iframe
            width="550px"
            height="450px"
            style={{ borderRadius: "20px" }}
            src={url.replace("watch?v=", "embed/")}
          ></iframe>
        ) : url?.includes("vimeo.com") ? (
          <iframe
            src={url}
            width="380px"
            height="250px"
            style={{ borderRadius: "20px" }}
            allow="autoplay; fullscreen; picture-in-picture"
          ></iframe>
        ) : (
          <video width="300px" controls>
            <source src={url} type="video/mp4" />
          </video>
        )}
      </Box>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <a href={url} target="_blank" style={{ margin: "0px 14px" }}>
          <DownloadIcon></DownloadIcon>
        </a>
      </DialogActions>
    </Dialog>
  );
}

export function AudioDilog({ open, setOpen, url }: any) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Box
        sx={{
          display: "flex",
          m: "150px 0px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <audio controls>
          <source src={url} type="audio/mpeg" width="100px" />
        </audio>
      </Box>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <a href={url} target="_blank" style={{ margin: "0px 14px" }}>
          <DownloadIcon></DownloadIcon>
        </a>
      </DialogActions>
    </Dialog>
  );
}

export function AlertDialog({ open, setOpen, url }: any) {
  const handleClose = () => {
    setOpen(false);
  };

  let forceDownload = (url: any) => {
    let urlArray = url.split("/");
    let bucket = urlArray[3];
    let key = `${urlArray[4]}/${urlArray[5]}`;
    let s3 = new AWS.S3({ params: { Bucket: bucket } });
    let params = { Bucket: bucket, Key: key };
    s3.getObject(params, (err, data: any) => {
      let blob = new Blob([data?.Body], { type: data.ContentType });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = url;
      link.click();
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Box
        sx={{
          display: "flex",
          m: "20px 0px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={url}
          alt="workshopimage"
          style={{
            objectFit: "contain",
            width: "550px",
            height: "550px",
          }}
        />
      </Box>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <a href={url} download target="_blank" style={{ margin: "0px 14px" }}>
          <DownloadIcon></DownloadIcon>
        </a>
      </DialogActions>
    </Dialog>
  );
}

export function CustomCards({ title, color, imageURL, dataArray }: any) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box display="flex" justifyContent="center">
        <img
          src="/images/imagepointing.svg"
          style={{ width: "22px", height: "80px" }}
        />
      </Box>
      <Box>
        <Card
          sx={{
            border: "1.5px solid #C1BBBA",
            borderRadius: "18px",
            height: "380px",
          }}
        >
          <Box
            sx={{
              backgroundColor: color,
              borderRadius: "8px",
              display: "flex",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "60px",
                height: "60px",
                margin: "12px",
                borderRadius: "20px",
              }}
            >
              <img
                style={{ marginLeft: "13px", marginTop: "13px" }}
                src={imageURL}
                width="34px"
                height="34px"
              />
            </Box>
            <Typography
              sx={{
                marginTop: "30px",
                width: "180px",
                color: "#FFFFFF",
                fontSize: "18px",
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            {dataArray.every((item: any) => item == "") ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "30px",
                  marginBottom: "25px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CardMedia
                    sx={{ height: 150, width: "180px" }}
                    image="/images/workshopempty.png"
                    title="green iguana"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ color: "red", marginTop: "10px" }}>
                    No Records Found
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Swiper
                spaceBetween={20}
                slidesPerView={1}
                centeredSlides={true}
                style={{
                  height: "290px",
                }}
                speed={2500}
                direction="vertical"
                loop
                autoplay={{
                  disableOnInteraction: false,
                  delay: 0.1,
                  pauseOnMouseEnter: true,
                }}
                modules={[Autoplay, Pagination]}
              >
                {dataArray?.map(
                  (item1: any) =>
                    item1 != "" && (
                      <SwiperSlide>
                        <Box>
                          <CustomMedia
                            type={item1?.type}
                            url={item1?.url}
                          ></CustomMedia>
                        </Box>
                      </SwiperSlide>
                    )
                )}
              </Swiper>
            )}
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
