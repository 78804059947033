import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { getDistricts, getWorkshopDistricts } from "api";
import Loader from "components/Loader";
import Layout from "layout/Layout";
import { AlertDialog } from "pages/traineefollow-up/TraineeComponents";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import http from "utils/http";
import { CreateWorkshop } from "./CreateWorkshopDilog";
import { styled } from "@mui/material/styles";
import { WorkshopPreview } from "pages/registered-trainees/components/ViewFile";
import WorkshopCoverImgs, { WorkshopCards } from "./WorkshopCoverImgs";

interface Idist {
  id: number;
  name: string;
  code: string;
  orgId: number;
}

function Workshop() {
  const [batch, setBatch] = useState("");
  const [district, setDistrict] = useState<Idist>();
  const [category, setCategory] = useState("CAREER GUIDANCE AND RESUME MAKING");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [unit, setUnit] = useState(0);
  const [url, setUrl] = useState("");
  const [workshopstate, setWorkshopState] = useState("");
  const { id } = useParams();
  const [openworkshop, setOpenWorkshop] = useState(false);
  const [subheading, setSubHeading] = useState(1);
  const [img, setImg] = useState("");

  const handleChange = (e: any) => {
    setBatch(e.target.value);
  };

  const handleCategories = (e: any) => {
    setCategory(e.target.value);
  };

  const handleWorkshop = (e: any) => {
    setWorkshopState(e.target.value);
  };

  const { data: batchs, isLoading: batchLdg } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const Currentbatch = batchs?.find((item: any) => item?.currentBatch == false);

  const { data: WorkshopSubData, isLoading: LdgWorkshop } = useQuery(
    ["workshopCategory", batch, category, district?.id],
    () =>
      http.get(
        `/workshop?batch=${batch}&category=${category}&district=${
          district?.id || ""
        }`
      )
  );

  const { data: workshop1, isLoading: isLoding1 } = useQuery(
    ["getworkshop1", workshopstate],
    () => http.get(`workshop/${workshopstate}`),
    {
      enabled: !!workshopstate,
    }
  );

  const WorkshopMain = workshop1?.data?.media.filter(
    (i: any) => i.type == "WORKSHOP_MAIN"
  );

  const WorkshopSilder = workshop1?.data?.media.filter(
    (i: any) => i.type == "WORKSHOP"
  );

  const trainee_feedback = workshop1?.data?.media.filter(
    (i: any) => i.type == "TRAINEE_FEEDBACK"
  );

  const principal_speech = workshop1?.data?.media.filter(
    (i: any) => i.type == "PRINCIPAL_SPEECH"
  );

  const newspaper_coverage = workshop1?.data?.media.filter(
    (i: any) => i.type == "NEWSPAPER_COVERAGE"
  );

  const placement = workshop1?.data?.media?.filter(
    (item: any) => item?.type == "PLACEMENT_DRIVE"
  );

  const offerLetter = workshop1?.data?.media?.filter(
    (item: any) => item?.type == "OFFER_LETTER"
  );
  const mediaCoverage = workshop1?.data?.media?.filter(
    (item: any) => item?.type == "MEDIA_COVERAGE"
  );

  const { data: Categories, isLoading: CategoriesLdg } = useQuery(
    "workshopcategory",
    () => http.get("/workshop/category").then((res) => res.data)
  );

  const style = {
    "@media (max-width: 1200px)": {
      display: "none",
    },
  };

  const handleimagePreview = (url: any) => {
    setUrl(url);
    setOpen(true);
  };

  const handleClick = () => {
    navigate(-1);
  };

  const { data: DistrictData, isLoading: districtsLoding } = useQuery(
    "workshop-districts",
    getWorkshopDistricts
  );

  const handleViewWorkshop = (id: any) => {
    navigate(`/workshop/view/${id}`);
  };

  useEffect(() => {
    setBatch(Currentbatch?.batch || "");
  }, [Currentbatch]);

  useEffect(() => {
    if (WorkshopSubData) {
      setWorkshopState(WorkshopSubData?.data?.data[0]?.id || "");
    }
  }, [WorkshopSubData]);

  if (isLoding1 && LdgWorkshop) return <Loader />;

  return (
    <Box>
      <Layout header="true" />
      <WorkshopPreview open={img} setOpen={setImg} fileLink={img} />
      <Box
        sx={{
          paddingTop: "25px",
        }}
        style={{ backgroundImage: "url(images/workshop-background.png)" }}
      >
        <Box display="flex" justifyContent="flex-end" mt={3} mr={2} gap={2}>
          <Box>
            <TextField
              onChange={handleChange}
              select
              value={batch}
              label={Currentbatch?.batch || "Select Batch"}
              size="small"
              InputProps={{
                style: {
                  borderColor: "red",
                },
              }}
              sx={{
                minWidth: "200px",
                // backgroundColor: "#FFF",
                color: "#FFF",
                borderRadius: "5px",
              }}
            >
              <MenuItem value={""}>All</MenuItem>
              {batchs?.map((item: any, index: number) => (
                <MenuItem key={index} value={item?.batch}>
                  {item?.batch}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box minWidth="200px">
            <Autocomplete
              sx={{
                minWidth: "250px",
                // backgroundColor: "#FFF",
                color: "#FFF",
                borderRadius: "5px",
              }}
              fullWidth
              value={district}
              options={DistrictData?.data || []}
              getOptionLabel={(option: any) => option?.name || ""}
              filterSelectedOptions
              onChange={(event, value: any) => {
                setDistrict(value);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" label="District" />
              )}
            />
          </Box>
          <Box>
            <TextField
              sx={{
                minWidth: "200px",
                color: "#FFF",
                borderRadius: "5px",
              }}
              select
              value={category}
              label="Select Category"
              size="small"
              onChange={handleCategories}
            >
              {Categories?.map((item: any, index: number) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <TextField
              onChange={handleWorkshop}
              select
              value={workshopstate}
              label="Select Workshop"
              size="small"
              InputProps={{
                style: {
                  borderColor: "red",
                },
              }}
              sx={{
                minWidth: "200px",
                // backgroundColor: "#FFF",
                color: "#FFF",
                borderRadius: "5px",
              }}
            >
              {WorkshopSubData?.data?.data?.map((item: any, index: number) => (
                <MenuItem key={index} value={item?.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>

        <WorkshopCoverImgs
          WorkshopMainArray={WorkshopMain}
          WorkshopArray={WorkshopSilder}
        />
      </Box>
      <Box mt={2}></Box>

      {workshop1?.data?.category == "PLACEMENT DRIVE" ? (
        <>
          <Box
            sx={{
              bgcolor: "#8F00FF",
              width: "94%",
              height: "50px",
              m: "0px auto",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                width: "100%",
                m: "0px auto",
                gridTemplateColumns: "1fr 1fr 1fr",
                alignItems: "center",
                // justifyItems: "center",
                gap: 2,
                height: "100%",
              }}
            >
              <WorkshopCardTitle
                title="Placement Drive Highlights"
                subheading={subheading == 1}
                setSubHeading={setSubHeading}
                value={1}
              />
              <WorkshopCardTitle
                title="Trainees with offer letter"
                subheading={subheading == 2}
                setSubHeading={setSubHeading}
                value={2}
              />
              <WorkshopCardTitle
                title="Media Coverage"
                subheading={subheading == 3}
                setSubHeading={setSubHeading}
                value={3}
              />
            </Box>
          </Box>
          <Box
            sx={{
              // display: "grid",
              width: "88%",
              m: "0px auto 30px auto",
              // pb: 3,
              // gridTemplateColumns: "1fr 1fr 1fr",
              gap: 2,
            }}
          >
            {subheading == 1 ? (
              <WorkshopCards
                Array={placement}
                subheading={subheading == 1}
                setImg={setImg}
              />
            ) : subheading == 2 ? (
              <WorkshopCards
                Array={offerLetter}
                subheading={subheading == 2}
                setImg={setImg}
              />
            ) : (
              <WorkshopCards
                Array={mediaCoverage}
                subheading={subheading == 3}
                setImg={setImg}
              />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              bgcolor: "#8F00FF",
              width: "94%",
              height: "50px",
              m: "0px auto",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                width: "100%",
                m: "0px auto",
                gridTemplateColumns: "1fr 1fr 1fr",
                alignItems: "center",
                // justifyItems: "center",
                gap: 2,
                height: "100%",
              }}
            >
              <WorkshopCardTitle
                title="Nodal Officer/ALO Speech"
                subheading={subheading == 1}
                setSubHeading={setSubHeading}
                value={1}
              />
              <WorkshopCardTitle
                title="Trainess Feedback"
                subheading={subheading == 2}
                setSubHeading={setSubHeading}
                value={2}
              />
              <WorkshopCardTitle
                title="Media Coverage"
                subheading={subheading == 3}
                setSubHeading={setSubHeading}
                value={3}
              />
            </Box>
          </Box>
          <Box
            sx={{
              // display: "grid",
              width: "88%",
              m: "0px auto 30px auto",
              // pb: 3,
              // gridTemplateColumns: "1fr 1fr 1fr",
              gap: 2,
            }}
          >
            {subheading == 1 ? (
              <WorkshopCards
                Array={principal_speech}
                subheading={subheading == 1}
                setImg={setImg}
              />
            ) : subheading == 2 ? (
              <WorkshopCards
                Array={trainee_feedback}
                subheading={subheading == 2}
                setImg={setImg}
              />
            ) : (
              <WorkshopCards
                Array={newspaper_coverage}
                subheading={subheading == 3}
                setImg={setImg}
              />
            )}
          </Box>
        </>
      )}

      <AlertDialog open={open} setOpen={setOpen} url={url} />
      <CreateWorkshop open={openworkshop} setOpen={setOpenWorkshop} />
    </Box>
  );
}

const WorkshopCardTitle = ({
  subheading,
  setSubHeading,
  title,
  value,
}: any) => {
  return (
    <Typography
      sx={{
        width: "100%",
        textAlign: "center",
        borderRight: "2px solid #FFF",
        ml: 2,
        color: subheading ? "#FFF" : "#000",
        fontWeight: "600",
        cursor: "pointer",
      }}
      onClick={() => setSubHeading(value)}
    >
      {title}
    </Typography>
  );
};

const SectionCard = ({ dataArray, title, handleimagePreview }: any) => {
  return (
    <Box display="flex" ml={2}>
      {dataArray?.length > 0 &&
        dataArray.map((item: any) => (
          <Box minWidth="350px" minHeight="200px" mt="15px" m={1} p={1}>
            <Card sx={{ maxWidth: 380 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "350px",
                  height: "200px",
                }}
              >
                {item?.subtype === "VIDEO" &&
                  (item?.url?.includes("youtube.com") ? (
                    <iframe
                      allow="fullscreen;"
                      title="youtube"
                      width="380px"
                      height="200px"
                      style={{ borderRadius: "20px" }}
                      src={item?.url.replace("watch?v=", "embed/")}
                    ></iframe>
                  ) : item?.url?.includes("vimeo.com") ? (
                    <iframe
                      title="viemo"
                      src={item?.url}
                      width="380px"
                      height="200px"
                      style={{ borderRadius: "20px" }}
                      allow="autoplay; fullscreen; picture-in-picture"
                    ></iframe>
                  ) : (
                    <video width="380px" height="120px" controls>
                      <source src={item?.url} type="video/mp4" />
                    </video>
                  ))}
                {item?.subtype === "IMAGE" && (
                  <Box onClick={() => handleimagePreview(item?.url)}>
                    <img
                      src={item?.url}
                      object-fit="cover"
                      alt="tarinee img"
                      style={{ width: "380px", height: "200px" }}
                    />
                  </Box>
                )}
              </Box>
              <CardContent>
                <Typography>{title}</Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
    </Box>
  );
};

export type Position = {
  mouseX: number;
  mouseY: number;
};

function File(data: any, actions: any) {
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const [menu, setMenu] = useState(false);

  const handleView = () => {
    navigate(`/workshop/view/${data?.data.id}`);
  };

  const handleEdit = () => {
    navigate(`/workshop/edit/${data?.data?.id}`);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function mouseEnter() {
    setMenu(true);
  }
  function onMouseLeave() {
    setMenu(false);
  }

  function showStringUptoLength(str: any, maxLength: number) {
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.slice(0, maxLength) + "...";
    }
  }

  return (
    <Card elevation={2} sx={{ height: "100%", borderRadius: "10px" }}>
      <img
        src={
          data?.data?.url == null
            ? "/images/workshopempty.png"
            : data?.data?.url
        }
        style={{ width: "100%", height: "180px" }}
        object-fit="cover"
        alt=""
      />
      <Box ml="6px">
        <Typography
          bgcolor=" #002058"
          display="inline-block"
          mt="4px"
          p="6px 8px"
          borderRadius="10px"
          sx={{
            mt: "8px",
            color: "#FFF",
            fontWeight: 600,
            fontSize: "14px",
          }}
        >
          {data?.data?.district?.name}
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            mt: "10px",
            ml: "2px",
            maxWidth: "320px",
          }}
        >
          {data?.data?.title?.length > 32
            ? showStringUptoLength(data?.data?.title, 32)
            : data?.data?.title}
        </Typography>

        <Box display="flex" justifyContent="space-between" margin="4px 4px">
          <Typography
            sx={{ color: "#2D5696", mt: "8px", mb: "10px", ml: "2px" }}
          >
            {data?.data?.batch?.batch}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default Workshop;
