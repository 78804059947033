import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import http from "utils/http";
import ModalButtons from "./components/ModalButtons";

const initState = {
  username: "",
  name: "",
  mobile: "",
  email: "",
  password: "",
  role: "",
  profileImage: null,
};

function CreateUser({ refetchUsers }: any) {
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = useState(initState);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const { data: rolesData } = useQuery(
    "user-roles",
    () => http.get("/user/roles").then((res) => res.data),
    {
      enabled: !!open,
    }
  );

  const handleClose = () => {
    setOpen(false);
  };

  function handleChange(e: any) {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }
  function handleClick(e: any) {
    e.preventDefault();

    http
      .post("/user", {
        ...user,
      })
      .then((res) => {
        refetchUsers();
        handleClose();
      })
      .catch((err) => {});
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create User
      </Button>
      <Dialog open={open} fullWidth onClose={handleClose}>
        <DialogTitle>Create User</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Stack spacing={2} padding={1}>
              <StyledTextField
                label="Username"
                value={user.username}
                name="username"
                onChange={handleChange}
              />
              <StyledTextField
                label="Name"
                value={user.name}
                name="name"
                onChange={handleChange}
              />
              <StyledTextField
                label="Mobile"
                value={user.mobile}
                name="mobile"
                onChange={handleChange}
              />
              <StyledTextField
                label="Email"
                value={user.email}
                name="email"
                onChange={handleChange}
              />
              <StyledTextField
                label="Password"
                value={user.password}
                name="password"
                onChange={handleChange}
              />
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small">Role</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={user.role}
                  label="Role"
                  name="role"
                  onChange={handleChange}>
                  {rolesData?.map((role: any) => (
                    <MenuItem value={role}>
                      {role.split("_").join(" ")}
                    </MenuItem>
                  ))}
                </Select>
                <ModalButtons
                  handleClick={handleClick}
                  handleClose={handleClose}
                />
              </FormControl>
            </Stack>
          </FormControl>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateUser;

const StyledTextField = (props: any) => (
  <TextField {...props} variant="outlined" size="small" />
);
