import { Autocomplete, Button, Chip, FormControl, FormLabel, MenuItem, Select, TextField } from '@mui/material';
import { getDistricts } from 'api';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import http from 'utils/http';
import Loader from './Loader';

const StatisticsFilter = ({ filters, setFilters }: any) => {
  const questions=[
    {id:"age",value:"Age Of The Participant"},
    {id:"gender",value:"Gender"},
    {id:"priorEmployment",value:"Employment Status Prior To Training"},
    {id:"currentEmploymentStatus",value:"Current Employment Status"},
    {id:"socialGroup",value:"Social Group"},
    {id:"isConstructionWorker",value:"Is Your Parents/Father A Construction Worker"},
    {id:"source",value:"How Did You Hear About The Training Program?"},
    {id:"isFirstTraining",value:"Was This Your First Skill Training Program?"},
    {id:"qualityOfTraining",value:"Quality Of Training Materials Provided"},
    {id:"relevanceOfTraining",value:"Relevance Of The Training Content To Your Expected Job Role"},
    {id:"instructorEffectiveness",value:"Instructors Teaching Method Effectiveness"},
    {id:"assessmentMethod",value:"Assessment Methods Used During The Training"},
    {id:"isCertificationReceived",value:"Certification Received"},
    {id:"postTrainingConfidence",value:"How Did You Feel Immediately After Completing Training"},
    {id:"careerOpportunities",value:"Opportunities For Career Advancement Post- Training"},
    {id:"additionalTrainingRequired",value:"Additional Training Or Skills Needed"},
    {id:"recommendation",value:"Recommendations For Others Considering Similar Training"},
    {id:"overallOpinion",value:"Overall Opinion About The Programme"},
    
  ]
  const [courses, setCourses] = useState<any[]>([]);
  const { data: districtData, isLoading: districtLoading } = useQuery(
    "districts",
    getDistricts
  );

  const { data: educationData, isLoading } = useQuery("education", () =>
    http.get("/education").then((res) => res.data)
  );

  useEffect(() => {
    setCourses(
      educationData?.find((item: any) => item.name === filters?.education)
        ?.courses || []
    );
  }, [filters.education, educationData]);

  const handleBatchChange = (e: any) => {
    setFilters((prev: any) => ({
      ...prev,
      batch: e.target.value,
      page: 1,
    }));
  };

  const handleDistrictChange = (e: any) => {
    setFilters((prev: any) => ({
      ...prev,
      districtId: e.target.value,
      page: 1,
    }));
  };

  const handleEducationChange = (e: any) => {
    setFilters((prev: any) => ({
      ...prev,
      education: e.target.value,
      page: 1,
    }));
    setCourses(
      educationData.find((item: any) => item.name === e.target.value)
        ?.courses || []
    );
  };

  const handleCourseChange = (event: any, value: any) => {
    setFilters((prev: any) => ({
      ...prev,
      courseId: value?.id || "",
      page: 1,
    }));
  };

  const handleQuestionChange = (event: React.ChangeEvent<{}>, value: any) => {
    setFilters((prev: any) => ({
      ...prev,
      questionId: value?.id || "",
      page: 1,
    }));
  };

  const resetFilters = () => {
    setFilters({
      courseId: "",
      batch: "",
      districtId: "",
      education: "",
      questionId:"",
    });
  };

  if (isLoading || districtLoading) return <Loader />;
  
  const distCode = filters.districtId;

  return (
    <div className='print-hide' style={{ display: "flex", gap: "1rem", flexDirection: "column", width: "100%" }}>
      <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "20px" }}>
        <FormControl fullWidth>
          <FormLabel>Batch</FormLabel>
          <Select
            size="small"
            name='batch'
            displayEmpty
            placeholder='Select Batch'
            value={filters.batch}
            onChange={handleBatchChange}
          >
            <MenuItem value="">
              All
            </MenuItem>
            {["ONB2022", "ONB2023"].map((item: any) => (
              <MenuItem key={item} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>District</FormLabel>
          <Select
            placeholder='Select District'
            size="small"
            name="district"
            displayEmpty
            value={filters.districtId}
            onChange={handleDistrictChange}
          >
            <MenuItem value="">
              All
            </MenuItem>
            {districtData?.data?.map((item: any) => (
              <MenuItem key={item?.name} value={item?.name}>{item?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "20px" }}>
        <FormControl fullWidth>
          <FormLabel>Education Qualification</FormLabel>
          <Select
            size="small"
            displayEmpty
            name="education"
            placeholder='Select Education Qualification'
            value={filters.education}
            onChange={handleEducationChange}
          >
            <MenuItem value="">
              All
            </MenuItem>
            {educationData?.map((item: any) => (
              <MenuItem key={item?.name} value={item?.name}>{item?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>Course</FormLabel>
          <Autocomplete
            fullWidth
            value={courses.find((course) => course.id === filters.courseId) || null}
            options={courses || []}
            getOptionLabel={(option: any) => option?.name || ""}
            onChange={handleCourseChange}
            isOptionEqualToValue={(option, value) => {
              return option.id === value?.id;
            }}
            renderInput={(params) => (
              <TextField
                name="course"
                {...params}
                size="small"
                placeholder="Select Course"
              />
            )}
          />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth>
          <FormLabel>Feedback Question</FormLabel>
          <Autocomplete
            fullWidth
            value={questions.find((question) => question.id === filters.questionId) || null}
            options={questions || []}
            getOptionLabel={(option: any) => option?.value || ""}
            onChange={handleQuestionChange}
            isOptionEqualToValue={(option, value) => {
              return option.id === value?.id;
            }}
            renderInput={(params) => (
              <TextField
                name="question"
                {...params}
                size="small"
                placeholder="Select Feedback Question"
              />
            )}
          />
        </FormControl>

      
      </div>
      <div className="flex gap-4 items-center" style={{ margin: "0.5rem 0" }}>
        <h3 className="text-primary font-medium text-sm text-opacity-80">
          Selected Filters
        </h3>
        <div>
          <span className="text-gray-500">Batch: </span>
          <span>
            {filters.batch ? (
              <Chip
                label={filters.batch}
                onDelete={() => {
                  setFilters({ ...filters, batch: "", page: 1 });
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        <div>
          <span className="text-gray-500">District: </span>
          <span>
            {filters.districtId  ? (
              <Chip
                label={
                  districtData?.data?.find((item: any) => item?.name == filters?.districtId)
                    ?.name
                }
                onDelete={() => {
                  setFilters((prev: any) => ({
                    ...prev,
                    districtId: "",
                    page: 1,
                  }));
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        <div>
          <span className="text-gray-500">Education: </span>
          <span>
            {filters.education ? (
              <Chip
                label={filters.education}
                onDelete={() => {
                  setFilters({ ...filters, education: "", page: 1 });
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        <div>
          <span className="text-gray-500">Course: </span>
          <span>
            {filters.courseId ? (
              <Chip
                label={
                  courses.find((course) => course.id === filters.courseId)?.name
                }
                onDelete={() => {
                  setFilters({
                    ...filters,
                    courseId: "",
                    page: 1,
                  });
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        <div>
          <span className="text-gray-500">Feedback Question: </span>
          <span>
            {filters.questionId ? (
              <Chip
                label={
                  questions.find((question) => question.id === filters.questionId)?.value
                }
                onDelete={() => {
                  setFilters({
                    ...filters,
                    questionId: "",
                    page: 1,
                  });
                }}
              />
            ) : (
              "All"
            )}
          </span>
        </div>
        <Button
          size="small"
          variant="outlined"
          onClick={resetFilters}
          sx={{
            fontWeight: 600,
            fontSize: 13,
            padding: "0 6px",
          }}
        >
          Reset Filters
        </Button>
      </div>
    </div>
  )
}

export default StatisticsFilter;
