import { Close } from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { UpdateOrder } from "api";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

function SortDialog({ open, setOpen, Array }: any) {
  const queryClient = useQueryClient();
  const [sort, setSort] = useState<any>([]);
  const [oderValue, setOderValue] = useState(false);
  const [positive, setPositive] = useState(false);
  const [state, setState] = useState(false);

  const onlyPositive = /^[1-9][0-9]*$/;

  useEffect(() => {
    setSort(Array);
  }, [Array]);

  const { mutate } = useMutation(UpdateOrder, {
    onSuccess: (res: any) => {
      toast.success("Sort Order Updated Successfully");
      queryClient.invalidateQueries("workshopbyid");
      setOpen(false);
    },
    onError: (err: any) => {},
  });

  const handleSave = () => {
    let a = sort?.filter((i: any) =>
      i?.sortOrder != "NAN" ? !onlyPositive.test(String(i?.sortOrder)) : false
    );

    if (a?.length > 0) {
      setPositive(true);
      return;
    }
    setPositive(false);
    setOderValue(false);

    for (let i = 0; i < sort?.length; i++) {
      for (let j = i + 1; j < sort?.length; j++) {
        if (sort[i]?.sortOrder == sort[j]?.sortOrder) {
          setOderValue(true);
          return;
        }
      }
    }

    setPositive(false);

    mutate(sort);
  };

  const handleSort = (item: any, e: any) => {
    setSort([
      ...sort.filter((i: any) => i.id != item?.id),
      { id: item?.id, sortOrder: parseInt(e.target.value) },
    ]);
  };

  if (!sort) return <Loader />;

  return (
    <Drawer
      anchor="right"
      sx={{ zIndex: 1202 }}
      PaperProps={{ sx: { width: 480 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <AppBar position="sticky">
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="subtitle1">Arrange Sort Oder</Typography>
          <IconButton onClick={() => setOpen(false)} sx={{ color: "white" }}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box pt={3} pb={3}>
        {Array?.map((item: any) => (
          <Box display="flex" gap={1} pl={4} pt={2} pb={2}>
            {item?.subtype === "IMAGE" ? (
              <img
                style={{
                  objectFit: "fill",
                  height: "200px",
                  width: "200px",
                  display: "inline",
                  borderRadius: "20px",
                }}
                draggable={false}
                src={item?.url}
                alt=""
              />
            ) : (
              <iframe
                width="200px"
                height="200px"
                style={{ borderRadius: "20px" }}
                src={(item?.url).replace("watch?v=", "embed/")}
              ></iframe>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Enter Order"
                variant="outlined"
                size="small"
                value={
                  sort?.filter((i: any) => i?.id == item?.id)[0]?.sortOrder ||
                  ""
                }
                type="number"
                inputProps={{ min: 1 }}
                onChange={(e) => handleSort(item, e)}
              />
            </Box>
          </Box>
        ))}

        <Box sx={{ maxWidth: "400px", m: "auto" }}>
          {positive && (
            <Alert severity="error">Only Positive Numbers are allowed!</Alert>
          )}
          {oderValue && (
            <Alert severity="error">No Two Order value should be same!</Alert>
          )}
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            style={{
              margin: "20px",
              backgroundColor: "#2D5696",
              color: "#FFFFFF",
              padding: "2px 10px",
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default SortDialog;
