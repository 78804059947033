import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { createTraineeFolder } from "api";
import Loader from "components/Loader";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "utils/http";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  data?: any;
}

function Addcategories({ open, setOpen }: Props) {
  const [state, setState] = useState("");
  const queryClient = useQueryClient();
  const { id } = useParams();

  const { data: categories, isLoading } = useQuery("traineecategories", () =>
    http.get("/trainee-followup/category").then((res) => res.data)
  );

  const { mutate } = useMutation(createTraineeFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries("traineefollowupstorage");
      setOpen(false);
      setState("");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleChange = (e: any) => {
    setState(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      name: state,
      type: "FOLDER",
      parentId: id,
    };

    mutate(data);
  };

  if (isLoading) return <Loader />;

  return (
    <Drawer
      anchor="right"
      sx={{ zIndex: 1202 }}
      PaperProps={{ sx: { width: 400 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <AppBar position="sticky">
        <Toolbar
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1"> Add categories Folder</Typography>
          <IconButton onClick={() => setOpen(false)} sx={{ color: "white" }}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            size="small"
            name="batch"
            label="Select Categories"
            value={state}
            select
            onChange={(e: any) => setState(e.target.value)}
          >
            {categories?.map((item: any) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </TextField>

          <Box mt={2} textAlign="right">
            <Button
              style={{ backgroundColor: "#f50000" }}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
}

export default Addcategories;
