import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, IconButton, alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { UserProfileStore } from "./AppRouter";
import { CustomLink, CustomSubLink } from "./Layout";

type Anchor = "top" | "left" | "bottom" | "right";

export default function TemporaryDrawer({ open, setOpen }: any) {
  const [openarrow, setArrowOpen] = React.useState<boolean>(false);
  const role = UserProfileStore.useState((s) => s.state.role);

  const handleMenuClick = () => {
    setArrowOpen(!openarrow);
  };

  const menu = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "/images/dashboard.png",
    },
    {
      title: "Registered Trainees",
      path: "/registered-trainees",
      icon: "/images/registered-trainees.png",
    },
    {
      title: "Approved Trainees",
      path: "/approved-trainees",
      icon: "/images/approved-trainees.png",
    },
    {
      title: "Time Statistics",
      path: "/time-statistics",
      icon: "/images/time-statistics.png",
    },
    {
      title: "50% Course Completion",
      path: "/course-completion",
      icon: "/images/course-completion.png",
    },
    {
      title: "Certified Trainees",
      path: "/certified-trainees",
      icon: "/images/certified-trainees.png",
    },
    {
      title: "Trainee Follow Up",
      path: "/trainee-followup",
      icon: "/images/traineefollow-up.png",
    },

    {
      title: "Workshop & Placement Drive",
      path:
        role === "SUPER_ADMIN" || role == "ADMINISTRATOR" || role == "SME_ADMIN"
          ? "/workshop"
          : "/workshop/district-view",
      icon: "/images/workshop.png",
    },
  ];

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  const list = (anchor: Anchor) => (
    <>
      {
        role === "FEEDBACK_ADMIN" ? 
        (
          <Box sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }} role="presentation">
            <Box sx={{ overflow: "auto", mt: "56px" }}>
              <List>
                <CustomSubLink to="/dashboard" marginL="false">
                  <IconButton>
                    <img
                      src="/images/dashboard.png"
                      style={{ width: "24px" }}
                      alt="Storage"
                    />
                  </IconButton>
                  <ListItemText
                    sx={{
                      color: "#0E125E",
                      "& .MuiTypography-root": {
                        fontWeight: 600,
                      },
                    }}
                    primary="Dashboard"
                  />
                </CustomSubLink>
                <CustomSubLink to="/feedback" marginL="false">
                  <IconButton>
                    <img
                      src="/images/feedbackLogo.png"
                      style={{ width: "24px" }}
                      alt="Storage"
                    />
                  </IconButton>
                  <ListItemText
                    sx={{
                      color: "#0E125E",
                      "& .MuiTypography-root": {
                        fontWeight: 600,
                      },
                    }}
                    primary="FeedBack"
                  />
                </CustomSubLink>
              </List>
            </Box>
          </Box>
        )
        :
        (
          <Box sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }} role="presentation">
            <Box sx={{ overflow: "auto", mt: "56px" }}>
              <List>
                {menu
                  .filter((item) => {
                    if (item.title == "Admin Settings") {
                      if (role == "SUPER_ADMIN") {
                        return item;
                      }
                    } else if (item.title === "District Course Details") {
                      if (role == "SUPER_ADMIN") {
                        return item;
                      }
                    } else if (item.title == "Courses") {
                      if (role == "SUPER_ADMIN") {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  })
                  .map((item, index) => (
                    <CustomLink to={item.path} key={index} marginL="flase">
                      <IconButton>
                        <img
                          src={item.icon}
                          style={{ width: "24px" }}
                          alt={item.title}
                        />
                      </IconButton>
                      <ListItemText
                        sx={{
                          color: "#0E125E",
                          "& .MuiTypography-root": {
                            fontWeight: 600,
                          },
                        }}
                        primary={item.title}
                      />
                    </CustomLink>
                  ))}
                <Box onClick={handleMenuClick}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    height="60px"
                    alignItems="center"
                    sx={{
                      "&:hover": {
                        background: true
                          ? alpha("#67B8FB", 0.6)
                          : alpha("#67B8FB", 0.6),
                      },
                    }}>
                    <IconButton>
                      <img
                        src="/images/certificateupload.png"
                        style={{ width: "24px" }}
                        alt="Storage"
                      />
                    </IconButton>
                    <ListItemText
                      sx={{
                        color: "#0E125E",
                        "& .MuiTypography-root": {
                          fontWeight: 600,
                        },
                      }}
                      primary="TBOCWWB Storage"
                    />
                    {openarrow ? (
                      <ExpandLess sx={{ color: "primary", mr: 5 }} />
                    ) : (
                      <ExpandMore sx={{ color: "primary", mr: 5 }} />
                    )}
                  </Box>
                </Box>
                <Collapse in={openarrow} timeout="auto" unmountOnExit>
                  <Box>
                    <List component="div" disablePadding>
                      {submenu
                        ?.filter((i: any) => {
                          if (i.title == "Certificate Upload") {
                            if (
                              role == "SUPER_ADMIN" ||
                              "ADMINISTRATOR" ||
                              "SME_ADMIN"
                            ) {
                              return i;
                            }
                          } else {
                            return i;
                          }
                        })
                        .map((item, index) => (
                          <CustomSubLink to={item.path} key={index} marginL="true">
                            <IconButton>
                              <img
                                src={item.icon}
                                style={{ width: "24px" }}
                                alt={item.title}
                              />
                            </IconButton>
                            <ListItemText
                              sx={{
                                color: "#0E125E",
                                "& .MuiTypography-root": {
                                  fontWeight: 600,
                                },
                              }}
                              primary={item.title}
                            />
                          </CustomSubLink>
                        ))}
                    </List>
                  </Box>
                </Collapse>
                <CustomSubLink to="/feedback" marginL="false">
                  <IconButton>
                    <img
                      src="/images/feedbackLogo.png"
                      style={{ width: "24px" }}
                      alt="Storage"
                    />
                  </IconButton>
                  <ListItemText
                    sx={{
                      color: "#0E125E",
                      "& .MuiTypography-root": {
                        fontWeight: 600,
                      },
                    }}
                    primary="FeedBack"
                  />
                </CustomSubLink>
              </List>
            </Box>
          </Box>
        )
      }

    </>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer anchor="left" open={open} onClose={toggleDrawer("left", false)}>
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const submenu = [
  {
    title: "Certificate Upload",
    path: "/storage",
    icon: "/images/certificateuploadicon.png",
  },
  {
    title: "Trainee Follow Up",
    path: "/trainee-follow-up/storage",
    icon: "/images/traineefollow-up.png",
  },
  {
    title: "Workshop",
    path: "/workshop/storage",
    icon: "/images/workshop.png",
  },
  {
    title: "Logs",
    path: "/storage/logs",
    icon: "/images/workshop.png",
  },
];
