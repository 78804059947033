import { ArrowDownwardSharp, ArrowUpwardSharp } from "@mui/icons-material";
import SortByAlpha from "@mui/icons-material/SortByAlpha";
import {
  Box,
  CircularProgress,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import _ from "lodash";
import React, { useState } from "react";
import { StyledTable } from "./styles";

interface TableProps {
  columns: Array<{
    key: string;
    title: string;
    render?: (item: any) => any;
    sortable?: {
      sort: boolean;
      default: "ASC" | "DESC";
    };
  }>;
  data: any[];
  loading: boolean;
  sx?: SystemStyleObject;
  onRowClick?: (v: any) => void;
  actions?: Array<{
    icon?: React.ReactElement;
    text?: string;
    action: (v: any) => void;
  }>;
  pagination: {
    totalCount: number;
    pageCount: number;
    onChange: (v: number) => any;
    onPageCountChange?: (v: number) => void;
  };
  selection?: boolean;
  selectActions?: any[];
  selectionDependencies?: any;
  filters?: any;
  setFilters?: any;
  toggleSort?: any;
  setToggleSort?: any;
}

function Table(props: TableProps) {
  const {
    columns,
    data,
    sx,
    pagination = {
      totalCount: 0,
      pageCount: 0,
      onChange: () => {},
    },
    loading = false,
    onRowClick,
    actions,
    setFilters,
    filters,
    toggleSort,
    setToggleSort,
  } = props;
  const [page, setPage] = useState(0);

  const handleRowClick = (item: any) => {
    if (!onRowClick) return;
    onRowClick(item);
  };

  const handleSort = (key: any) => {
    setToggleSort(!toggleSort);
    setFilters({
      ...filters,
      orderBy: key,
      order: toggleSort ? "DESC" : "ASC",
    });
  };

  return (
    <>
      <Box sx={{ overflowX: "auto", borderRadius: "4px" }}>
        <StyledTable sx={sx}>
          <thead style={{ backgroundColor: "#2d5696" }}>
            <tr>
              {columns.map((item, index) => (
                <th key={index}>
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography fontSize={14} fontWeight={600}>
                      {item.title}
                    </Typography>
                    {item.sortable && (
                      <Box display="flex" alignItems="center">
                        <SortByAlpha fontSize="small" />
                        <IconButton
                          sx={{ color: "white" }}
                          size="small"
                          onClick={() => {
                            handleSort(item.key);
                          }}
                        >
                          {toggleSort ? (
                            <ArrowDownwardSharp />
                          ) : (
                            <ArrowUpwardSharp />
                          )}
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </th>
              ))}
              {actions && actions.length ? <th>Actions</th> : null}
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              <>
                {data?.length ? (
                  <>
                    {data?.map((item, index) => (
                      <tr key={index} onClick={() => handleRowClick(item)}>
                        {actions?.length && (
                          <Actions actions={actions} item={item} />
                        )}
                        {columns?.map((col, colIndex) => (
                          <td key={colIndex}>
                            {col?.render ? (
                              <Box sx={{ whiteSpace: "nowrap" }}>
                                {col.render(item)}
                              </Box>
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {_.get(item, col.key)}
                              </Typography>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : (
                  <section>No Records Found</section>
                )}
              </>
            ) : (
              <section>
                <CircularProgress />
              </section>
            )}
          </tbody>
        </StyledTable>
      </Box>
      {pagination && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <TablePagination
            sx={{
              border: "none",
            }}
            count={pagination.totalCount || 10}
            page={page}
            onPageChange={(e, value) => {
              setPage(value);
              pagination.onChange(value);
            }}
            rowsPerPageOptions={[50, 100, 250, 500, 1000, 2000, 2500, 5000]}
            rowsPerPage={pagination.pageCount || 10}
            onRowsPerPageChange={(e) => {
              if (pagination.onPageCountChange) {
                pagination.onPageCountChange(+e.target.value);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}

export default Table;

type ActionProps = {
  actions: any[];
  item: any;
};

const Actions = ({ actions, item }: ActionProps) => {
  return (
    <td>
      <div className="flex gap-1">
        {actions?.map((action, index) => {
          if (action.icon) {
            return (
              <IconButton key={index} onClick={() => action.action(item)}>
                {action.icon}
              </IconButton>
            );
          }
        })}
      </div>
    </td>
  );
};

const SelectionActoinBar = ({ actions, selectedRows, isSelectAll }: any) => {
  return (
    <div className="mb-2 flex justify-end">
      {actions?.map((action: any, index: number) => (
        <div className="" key={index}>
          <action.component rows={selectedRows} isSelectAll={isSelectAll} />
        </div>
      ))}
    </div>
  );
};
