import { createContext, FC, useContext, useState } from "react";
import { USER_ROLE } from "../types";

const AuthContext = createContext<any>(null);

const AuthProvider: FC = ({ children }) => {
  const [role, setRole] = useState<USER_ROLE>();

  return (
    <AuthContext.Provider value={{ role, setRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export const useAuth = () => useContext(AuthContext);
