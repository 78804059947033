import {
  Card,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { getStatistics } from "api";
import BarChart3d from "components/BarChart3d";
import { AppTraineeLoader } from "components/CounterLoader";
import { GridContainer, GridContext } from "components/Grid";
import Header from "components/Header";
import { format } from "date-fns";
import moment from "moment";
import { ApplicationContent } from "pages/stats";
import { useState } from "react";
import { useQuery } from "react-query";
import theme from "styles/theme";
import http from "utils/http";
import Table from "./Table";

const themeOption = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: "#7461c9",
    },
  },
});

function ApprovedTrainees() {
  const [district, setDistrict] = useState("");
  const [batch, setBatch] = useState("");

  const { data: batchs, isLoading: batchLdg } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const { data: Approved, isLoading: ApprovedLdg } = useQuery(
    [
      "statistics",
      { batch, districtId: district, type: "APPROVED", educationData: true },
    ],
    getStatistics
  );

  const handleChange = (e: any) => {
    setBatch(e.target.value);
  };

  return (
    <>
      <ThemeProvider theme={themeOption}>
        <Header
          batch={batch}
          showDistrictFilter
          district={district}
          handleChange={handleChange}
          onChange={(v: string) => setDistrict(v)}
        />
        <GridContainer>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 6px 16px #2146831f",
              borderRadius: "5px",
              marginBottom: "2rem",
              height: 400,
            }}
          >
            {Approved?.data?.educationData ? (
              <BarChart3d
                data={Approved?.data?.educationData?.map((item: any) => ({
                  label: item.educationName,
                  value: item.userCount,
                  color: (() => {
                    switch (item.educationName) {
                      case "10th":
                        return "#2d5696";
                      case "Inter":
                        return "#FFA500";
                      case "ITI":
                        return "#FF69B4";
                      case "Polytechnic":
                        return "#0c9fe4";
                      case "Degree":
                        return "#7461c9";
                      case "Engineering":
                        return "#20a87d";
                    }
                  })(),
                }))}
                xAxisName={"Education Qualification"}
                yAxisName={"Number of Approved Traineess"}
                caption={
                  "Number of Approved Trainees vs Education Qualification"
                }
                unitY={""}
                barColor={"#7461c9"}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress sx={{ marginTop: "160px" }} />
              </div>
            )}
          </Card>
          <GridContext>
            <ApplicationContent
              title={"Approved Trainees"}
              count={Approved ? Approved?.data?.count : <AppTraineeLoader />}
              isLoading={true}
              src={"/images/approved-trainees.gif"}
              alt={"tickIcon"}
              color={"#7461c9"}
            />
          </GridContext>
        </GridContainer>
        <Table
          batch={batch}
          distCode={district}
          setDistrict={setDistrict}
          columns={COLUMNS}
          bgHeaderColor={"#7461c9"}
          pageSectionName={"approvedTrainees"}
        />
      </ThemeProvider>
    </>
  );
}

const COLUMNS = [
  {
    header: "Sr.No",
    accessor: "id",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "BOCW ID",
    accessor: "bocRegNo",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Trainee Name",
    accessor: "name",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  { header: "Mother Name", accessor: "motherName" },
  {
    header: "Mobile Number",
    accessor: "mobile",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Aadhar Number",
    accessor: "aadharNumber",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Education Qualification",
    accessor: "higherEducation",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Course Name",
    accessor: "course",
    Cell: ({ value }: { value: any }) => value?.name ?? "",
  },
  {
    header: "Batch Number",
    accessor: "batchNumber",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Course Start Date",
    accessor: "startDate",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Setwin ID",
    accessor: "formId",
    Cell: ({ value }: { value: any }) => value ?? "null",
  },
  {
    header: "Age",
    accessor: "dateOfBirth",
    Cell: ({ value }: { value: any }) =>
      value ? moment().diff(moment(value, "YYYY-MM-DD"), "years") : "",
  },
  {
    header: "Gender",
    accessor: "gender",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Worker/Depedent",
    accessor: "workerName",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Education Status",
    accessor: "educationStatus",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Village",
    accessor: "village",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Mandal",
    accessor: "mandal",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "District",
    accessor: "district",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
];

export default ApprovedTrainees;
