import { FormControl, FormHelperText, FormLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const FormSelect = ({ data, control, name, label, defaultValue }: any) => {
  return (
    <FormControl sx={{ gap: "0px" }} fullWidth>
      <Stack direction={'row'} gap={0.5} alignItems={'center'}>
        <FormLabel sx={{ my: '5px', fontSize: "16px", marginTop: '15px', fontWeight: "500", opacity: "80%" }}>
          {label}
        </FormLabel>
      </Stack>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue} // Set default value from props
        render={({ field, fieldState }) => (
          <>
            <Select
              {...field}
              value={field.value || ''}
              error={!!fieldState?.error}
              displayEmpty
              sx={{ borderRadius: '10px', width: 'auto' }}
              renderValue={(selected) =>
                selected ? (
                  selected
                ) : (
                  <Typography variant='h6' sx={{ fontSize: '14px', fontFamily: "Poppins", fontWeight: '500', color: '#222222', opacity: "50%" }}>
                    Select
                  </Typography>
                )
              }
            >
              {data?.map((item: string, index: number) => (
                <MenuItem key={index} value={item}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#222222", fontFamily: "Poppins" }}>
                    {item}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            {fieldState?.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
          </>
        )}
      />
    </FormControl>
  );
};

export default FormSelect;
