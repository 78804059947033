import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, Card, Typography } from "@mui/material";
import Loader from "components/Loader";
import Layout from "layout/Layout";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import http from "utils/http";
import "./workshopsroll.css";
import { WorkshopPreview } from "pages/registered-trainees/components/ViewFile";
import WorkshopCoverImgs, { WorkshopCards } from "./WorkshopCoverImgs";

function ViewWorkshop() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [unit, setUnit] = useState(0);
  const [url, setUrl] = useState("");
  const { id } = useParams();
  const [subheading, setSubHeading] = useState(1);
  const [img, setImg] = useState("");

  const { data, isLoading } = useQuery("workshopbyid", () =>
    http.get(`/workshop/${id}`).then((res) => res.data)
  );


  const placement = data?.media?.filter(
    (item: any) => item?.type == "PLACEMENT_DRIVE"
  );
  const offerLetter = data?.media?.filter(
    (item: any) => item?.type == "OFFER_LETTER"
  );
  const mediaCoverage = data?.media?.filter(
    (item: any) => item?.type == "MEDIA_COVERAGE"
  );

  const principal = data?.media?.filter(
    (item: any) => item?.type === "PRINCIPAL_SPEECH"
  );

  const feedbackArray = data?.media?.filter(
    (item: any) => item.type === "TRAINEE_FEEDBACK"
  );

  const newCoverageArray = data?.media?.filter(
    (item: any) => item.type === "NEWSPAPER_COVERAGE"
  );
  const WorkshopArray = data?.media?.filter(
    (item: any) => item.type === "WORKSHOP"
  );

  const WorkshopMainArray = data?.media?.filter(
    (item: any) => item.type === "WORKSHOP_MAIN"
  );

  const style = {
    "@media (max-width: 1200px)": {
      display: "none",
    },
  };

  const handleimagePreview = (url: any) => {
    setUrl(url);
    setOpen(true);
  };

  const handleClick = () => {
    navigate(-1);
  };

  if (isLoading) return <Loader />;

  return (
    <Box>
      <WorkshopPreview open={img} setOpen={setImg} fileLink={img} />
      <Layout header="true" />

      <Box
        style={{
          paddingTop: "15px",
          backgroundImage: "url(/images/workshop-background.png)",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            startIcon={<ArrowBackIosIcon />}
            size="large"
            onClick={handleClick}
            style={{ fontWeight: "600" }}
          >
            View Workshop
          </Button>
          <Typography fontSize="24px" fontWeight="500">
            {data?.title}
          </Typography>

          <Typography
            sx={{
              bgcolor: "#11ABFF",
              display: "inline-block",
              p: "8px 18px",
              borderRadius: "10px",
              mt: "5px",
              mr: "40px",
              fontSize: "18px",
              color: "#FFF",
            }}
          >
            {data?.district?.name}
          </Typography>
        </Box>
        <WorkshopCoverImgs
          WorkshopMainArray={WorkshopMainArray}
          WorkshopArray={WorkshopArray}
        />
      </Box>

      {data?.category === "PLACEMENT DRIVE" ? (
        <Box mt={2}>
          <Box
            sx={{
              bgcolor: "#8F00FF",
              width: "94%",
              height: "50px",
              m: "0px auto",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                width: "100%",
                m: "0px auto",
                gridTemplateColumns: "1fr 1fr 1fr",
                alignItems: "center",
                gap: 2,
                height: "100%",
              }}
            >
              <WorkshopCardTitle
                title="Placement Drive Highlights"
                subheading={subheading == 1}
                setSubHeading={setSubHeading}
                value={1}
              />
              <WorkshopCardTitle
                title="Trainees with offer letter"
                subheading={subheading == 2}
                setSubHeading={setSubHeading}
                value={2}
              />
              <WorkshopCardTitle
                title="Media Coverage"
                subheading={subheading == 3}
                setSubHeading={setSubHeading}
                value={3}
              />
            </Box>
          </Box>

          <Box
            sx={{
              // display: "grid",
              width: "88%",
              m: "0px auto 30px auto",
              // pb: 3,
              // gridTemplateColumns: "1fr 1fr 1fr",
              gap: 2,
            }}
          >
            {subheading == 1 ? (
              <WorkshopCards
                Array={placement}
                subheading={subheading == 1}
                setImg={setImg}
              />
            ) : subheading == 2 ? (
              <WorkshopCards
                Array={offerLetter}
                subheading={subheading == 2}
                setImg={setImg}
              />
            ) : (
              <WorkshopCards
                Array={mediaCoverage}
                subheading={subheading == 3}
                setImg={setImg}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Box mt={2}>
          <Box
            sx={{
              bgcolor: "#8F00FF",
              width: "94%",
              height: "50px",
              m: "0px auto",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                width: "100%",
                m: "0px auto",
                gridTemplateColumns: "1fr 1fr 1fr",
                alignItems: "center",
                // justifyItems: "center",
                gap: 2,
                height: "100%",
              }}
            >
              <WorkshopCardTitle
                title="Nodal Officer/ALO Speech"
                subheading={subheading == 1}
                setSubHeading={setSubHeading}
                value={1}
              />
              <WorkshopCardTitle
                title="Trainess Feedback"
                subheading={subheading == 2}
                setSubHeading={setSubHeading}
                value={2}
              />
              <WorkshopCardTitle
                title="Media Coverage"
                subheading={subheading == 3}
                setSubHeading={setSubHeading}
                value={3}
              />
            </Box>
          </Box>

          <Box
            sx={{
              // display: "grid",
              width: "88%",
              m: "0px auto 30px auto",
              // pb: 3,
              // gridTemplateColumns: "1fr 1fr 1fr",
              gap: 2,
            }}
          >
            {subheading == 1 ? (
              <WorkshopCards
                Array={principal}
                subheading={subheading == 1}
                setImg={setImg}
              />
            ) : subheading == 2 ? (
              <WorkshopCards
                Array={feedbackArray}
                subheading={subheading == 2}
                setImg={setImg}
              />
            ) : (
              <WorkshopCards
                Array={newCoverageArray}
                subheading={subheading == 3}
                setImg={setImg}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
const WorkshopCardTitle = ({
  subheading,
  setSubHeading,
  title,
  value,
}: any) => {
  return (
    <Typography
      sx={{
        width: "100%",
        textAlign: "center",
        borderRight: "2px solid #FFF",
        ml: 2,
        color: subheading ? "#FFF" : "#000",
        fontWeight: "600",
        cursor: "pointer",
      }}
      onClick={() => setSubHeading(value)}
    >
      {title}
    </Typography>
  );
};

export default ViewWorkshop;
