import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Box, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getStorageLog } from "api";
import Loader from "components/Loader";
import ReactTable from "components/ReactTable";
import { Dayjs } from "dayjs";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";

const filters = {
  perPage: 500,
  page: 1,
};

function Logs() {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(filters);

  const { data, isLoading } = useQuery(
    [
      "getstoragelog",
      moment(`${startDate}`).format("YYYY-MM-DD"),
      moment(`${endDate}`).format("YYYY-MM-DD"),
      search,
      filters?.page,
      filters?.perPage,
    ],
    getStorageLog
  );

  const handleChange = (event: any) => {
    setSearch(event.target.value as string);
  };

  function handlePage(index: any) {
    setFilter((prev) => ({ ...prev, page: prev.page + index }));
  }

  if (isLoading) return <Loader />;

  return (
    <>
      <Box mt={4} display="flex" justifyContent="flex-end">
        <Box display="flex" width="600px">
          <Box>
            <TextField
              select
              label="Select Category"
              value={search}
              size="small"
              sx={{
                minWidth: 180,
                marginRight: 1,
                "@media (max-width:600px)": {
                  minWidth: 125,
                },
              }}
              onChange={handleChange}
              defaultValue="CERTIFICATE_UPLOAD"
            >
              <MenuItem value={"CERTIFICATE_UPLOAD"}>
                CERTIFICATE_UPLOAD
              </MenuItem>
              <MenuItem value={"TRAINEE_FOLLOW_UP"}>TRAINEE_FOLLOW_UP</MenuItem>
              <MenuItem value={"WORKSHOP"}>WORKSHOP</MenuItem>
            </TextField>
          </Box>

          <Box width="240px" mr={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                label="Start Date"
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                label="End Date"
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        <ReactTable
          columns={COLUMNS}
          data={data?.data?.data}
          handleButton={handlePage}
          page={filter.page}
          setFilter={setFilter}
          pageCount={Math.ceil(data?.data?.count / filter.perPage)}
          perPage={filter.perPage}
          bgHeaderColor="#0c9fe4"
        />
      </Box>
    </>
  );
}

const COLUMNS = [
  {
    header: "Log Type",
    accessor: "logType",
    Cell: ({ value }: { value: any }) => value ?? "null",
  },
  {
    header: "Status",
    accessor: "status",
    Cell: ({ value }: { value: any }) => (value ? "True" : "False"),
  },
  {
    header: "Message",
    accessor: "message",
    Cell: ({ value }: { value: any }) => value ?? "null",
  },
];

export default Logs;
