import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Typography } from '@mui/material';
import { getFeedbackStatistics } from 'api';
import BarChart2d from 'components/Barchart2d';
import PieChart2d from 'components/PieChart2d';
import StatisticsFilter from 'components/StatisticsFilter';
import Layout from 'layout/Layout';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const Statistics = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    courseId: "",
    batch: "",
    districtId: "",
    education: "",
    questionId:"",
  });

  useEffect(() => {
    console.log("Filters changed:", filters);
  }, [filters]);

  const { data: feedbackStatistics, isLoading: isFeedbackStatisticsLoading } = useQuery(
    [
      "feedback-statistics",
      filters,
    ],
    getFeedbackStatistics, 
    { 
      enabled: !!filters 
    }
  );

  const handleBackClick = () => navigate(-1);

  return (
    <Box sx={{ marginTop: "20px", width: "100%", padding:"20px",paddingLeft:"2%"}}>
      <Layout header="true" />
      <Button
        size='small'
        variant='outlined'
        sx={{ width: "10%" }}
        className='print-hide'
        startIcon={<ArrowBackOutlinedIcon />}
        onClick={handleBackClick}
      >
        Back
      </Button>
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <Typography sx={{ fontSize: "22px", color: "#002060", fontWeight: "bold" }}>Evaluation Questionnaire For Skill Training Program - SETWIN</Typography>
      </Box>
      <Box sx={{ marginTop: "40px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <StatisticsFilter filters={filters} setFilters={setFilters} />
      </Box>
      <Box sx={{ marginTop: "15px", display: "flex", flexDirection: "row", justifyContent: "end" }} className='print-hide'>
        <Box sx={{ width: "38%", display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "flex-end" }}>
          <Button variant='outlined' onClick={() => window.print()} sx={{ width: "50%", fontWeight: "600" }} endIcon={<DownloadIcon />}>
            Download statistics
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: '20px', display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        {feedbackStatistics?.data &&
          Object.keys(feedbackStatistics?.data).map((key: string) => (
            feedbackStatistics?.data[key].length >5 ?
            (feedbackStatistics?.data[key] &&<BarChart2d  key={key} title={key} data={feedbackStatistics.data[key]} />) :
            (feedbackStatistics?.data[key] && (
              <PieChart2d key={key} title={key} data={feedbackStatistics.data[key]} />
            ))
          ))
        }
      </Box>
    </Box>
  )
}

export default Statistics;