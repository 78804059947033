import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { useEffect, useRef, useState } from "react";
import { AlertDialog } from "pages/traineefollow-up/TraineeComponents";

import "swiper/swiper-bundle.css";
import autoplayswiper from "./autoplayswiper";
import AutoPlaySwiper from "./autoplayswiper";

SwiperCore.use([Autoplay, Pagination]);

function WorkshopCoverImgs({ WorkshopMainArray, WorkshopArray }: any) {
  const [open, setOpen] = useState(false);
  const [autoPlayoff, setAutoPlayOff] = useState(true);
  const swiperRef = useRef(null);

  const [url, setUrl] = useState("");

  const handleimagePreview = (url: any) => {
    setUrl(url);
    setOpen(true);
  };

  return (
    <>
      <AlertDialog open={open} setOpen={setOpen} url={url} />

      <Grid
        container
        sx={{ p: 3 }}
        columnSpacing={{ xs: "25px", sm: "25px", md: "25px" }}
      >
        <Grid item xs={8}>
          <Box
            sx={{
              backgroundImage: `url(${"/images/laptop.png"})`,
              backgroundSize: "100% 550px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "600px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {WorkshopMainArray?.length > 0 ? (
              <Swiper
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
                style={{
                  width: "78.6%",
                  height: "86%",
                  borderRadius: "10px",
                  paddingTop: "50px",
                }}
              >
                {WorkshopMainArray?.map((i: any) =>
                  i.subtype == "IMAGE" ? (
                    <SwiperSlide>
                      <img
                        src={i?.url}
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                      />
                    </SwiperSlide>
                  ) : i?.url.includes("youtube.com") ? (
                    <SwiperSlide>
                      <iframe
                        allow="fullscreen;"
                        src={(i?.url).replace("watch?v=", "embed/")}
                        style={{
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </SwiperSlide>
                  ) : i?.url.includes("vimeo.com") ? (
                    <SwiperSlide>
                      <iframe
                        src={i?.url}
                        style={{
                          borderRadius: "10px",
                          width: "100%",
                          height: "400px",
                          margin: "15px 15px",
                        }}
                        allow="autoplay; fullscreen; picture-in-picture"
                      ></iframe>
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide>
                      <video
                        style={{
                          objectFit: "fill",
                          height: "400px",
                          width: "94%",
                          borderRadius: "10px",
                          margin: "15px 15px",
                        }}
                        controls
                      >
                        <source src={i?.url} type="video/mp4" />
                      </video>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            ) : (
              <Box
                sx={{
                  backgroundColor: "#F2F2F2",
                  borderRadius: "10px",
                  width: "78%",
                  height: "78%",
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    sx={{ height: 200, width: "200px" }}
                    image="/images/workshopempty.png"
                    title="green iguana"
                  />
                  <Typography
                    style={{
                      color: "red",
                      marginTop: "15px",
                      marginLeft: "45px",
                    }}
                  >
                    No Records Found
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={4}>
          <AutoPlaySwiper workshopArray={WorkshopArray} />
        </Grid>
      </Grid>
    </>
  );
}

export default WorkshopCoverImgs;

export const WorkshopCards = ({ Array, subheading, setImg }: any) => {
  return (
    <Box
      sx={{
        gap: 2,
        display: "grid",
        gridTemplateColumns: "33.3% 33.3% 33.3% ",
        justifyItems: "center",
        mt: 2,
      }}
    >
      {Array?.map((i: any) => (
        <Card elevation={2} sx={{ width: "340px" }}>
          {i?.subtype == "IMAGE" ? (
            <Box onClick={() => setImg(i?.url)} sx={{ cursor: "pointer" }}>
              <img
                src={i?.url}
                alt=""
                style={{
                  width: "300px",
                  height: "250px",
                  objectFit: "contain",
                  margin: "auto",
                }}
              />
            </Box>
          ) : (
            <iframe
              allow="fullscreen;"
              src={(i?.url).replace("watch?v=", "embed/")}
              style={{
                width: "100%",
                height: "250px",
              }}
            ></iframe>
          )}
        </Card>
      ))}

      {(Array?.length == 0 || Array == undefined) && (
        <Card
          elevation={subheading == 1 ? 20 : 2}
          sx={{ width: subheading == 1 ? "100%" : "96%", m: "0px auto" }}
        >
          <Box
            sx={{
              width: "100%",
              height: "250px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/images/workshopempty.png"
              style={{ width: "40%", height: "120px" }}
            />
            <Typography style={{ color: "red", marginTop: "10px" }}>
              No Records Found
            </Typography>
          </Box>
        </Card>
      )}
    </Box>
  );
};
