import {
  ArrowDownwardSharp,
  ArrowUpwardSharp,
  SortByAlpha,
} from "@mui/icons-material";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TableCell,
  IconButton,
  Typography,
  TableBody,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";

function CertifiedTable({
  columns,
  data,
  setFilter,
  filters,
  toggleSort,
  setToggleSort,
}: any) {
  const handleSort = (key: any) => {
    setToggleSort(!toggleSort);
    setFilter({
      ...filters,
      orderBy: key,
      order: toggleSort ? "DESC" : "ASC",
    });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ border: "1px solid #094264" }}>
          <TableHead
            sx={{
              "& .MuiTableCell-head": {
                backgroundColor: "#094264",
              },
            }}
          >
            <TableRow>
              {columns?.map((item: any, index: any) => (
                <TableCell key={index}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding="6px 16px"
                  >
                    <Typography
                      sx={{ fontWeight: 700, color: "#FFF", fontSize: "14px" }}
                    >
                      {item.header}
                    </Typography>

                    {item.sortable && (
                      <Box display="flex" alignItems="center">
                        <SortByAlpha fontSize="small" />
                        <IconButton
                          sx={{ color: "white" }}
                          size="small"
                          onClick={() => {
                            handleSort(item.accessor);
                          }}
                        >
                          {toggleSort ? (
                            <ArrowDownwardSharp />
                          ) : (
                            <ArrowUpwardSharp />
                          )}
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data?.length < 0 ? (
            <div
              style={{
                textAlign: "center",
                width: "200px",
                padding: "5px",
                position: "relative",
                top: "50%",
                left: "30vw",
                height: "2rem",
              }}
            >
              No Records Found
            </div>
          ) : (
            <TableBody>
              {data?.map((row: any, index: any) => {
                return (
                  <TableRow key={index}>
                    {columns.map((col: any, colindex: any) => {
                      return (
                        <TableCell
                          key={colindex}
                          sx={{
                            border: `1px solid #094264`,
                            textAlign: "center",
                          }}
                        >
                          {col?.render ? (
                            <Box sx={{ whiteSpace: "nowrap" }}>
                              {col.render(row)}
                            </Box>
                          ) : (
                            <Typography
                              variant="body2"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              {_.get(row, col.accessor)}
                            </Typography>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default CertifiedTable;
