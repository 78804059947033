import { Add } from "@mui/icons-material";
import { Box, Fab, IconButton, Typography } from "@mui/material";
import { getStorage } from "api";
import Loader from "components/Loader";
import { useState } from "react";
import { useQuery } from "react-query";
import Addbatches from "./AddBatch";
import Folders from "./Folder";

function CertificateUpload() {
  const [filters, setFilters] = useState("");
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useQuery(["storage", filters], getStorage);

  if (isLoading) return <Loader />;

  return (
    <Box
      sx={{
        height: "84vh",
      }}
    >
      <Fab
        size="medium"
        color="secondary"
        sx={{ position: "fixed", bottom: 30, right: 30, borderRadius: "8px" }}
        aria-label="add"
      >
        <Box
          sx={{
            borderRadius: "8px",
            backgroundColor: "#2d5696",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => setOpen(true)} size="large">
            <Add sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
      </Fab>
      {data?.data?.result?.length > 0 ? (
        <Box>
          <Folders
            data={data?.data?.result}
            filters={filters}
            setFilters={setFilters}
          />
        </Box>
      ) : (
        <Box
          pt="20%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="90vh"
          alignItems="center"
        >
          <img src="/images/workshopempty.png" width="200px" height="200px" />
          <Typography mt={2}>No batches Found</Typography>
        </Box>
      )}

      <Addbatches open={open} setOpen={setOpen} />
    </Box>
  );
}

export default CertificateUpload;

<Box
  mt={5}
  display="flex"
  flexDirection="column"
  justifyContent="center"
  height="80vh"
  alignItems="center"
>
  <img src="/images/workshopempty.png" width="200px" height="200px" />
  <Typography mt={2}>No batches Found</Typography>
</Box>;
