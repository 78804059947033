// @ts-nocheck

import { Box, Button, Card, Typography } from "@mui/material";
import {
  deleteMedia,
  deleteMediaVideo,
  uploadMediaWorkshop,
  uploadWorkshopMedia,
} from "api";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import http from "utils/http";
import WorkshopComponent from "./workshopComponent";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { title } from "process";
import Loader from "components/Loader";

function EditWorkshop() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [main, setMain] = useState<File | null>(null);
  const [mainvedioURL, setmainvedioURL] = useState("");
  const [preview, setPreview] = useState("");
  const [wimagefiles, setWImagefiles] = useState<File | null>(null);
  const [principalRadio, setPricipalRadio] = useState("IMAGE");
  const [feedbacksubtype, setFeedbacksubtype] = useState("IMAGE");
  const [newspapersubtype, setNewspapersubtype] = useState("IMAGE");
  const [placementRadio, setPlacementRadio] = useState("IMAGE");
  const [offerLetterRadio, setOfferLetter] = useState("IMAGE");
  const [mediaRadio, setMediaRadio] = useState("IMAGE");

  const [mainimages, setMainImages] = useState("");
  const [bigmain, setBigmain] = useState("");
  const [keys, setkeys] = useState([]);

  const [radiomain, setrRadiomain] = useState("IMAGE");
  const [radioslider, setRadioSlider] = useState("IMAGE");
  const [state, setState] = useState("");
  const [files, setFiles] = useState<File[]>([]);

  const handleRadionewcoverage = (value: any) => {
    setNewspapersubtype(value.target.value);
  };

  const handleRadio = (value: any) => {
    setrRadiomain(value.target.value);
  };

  const handleRadioFeedback = (value: any) => {
    setFeedbacksubtype(value.target.value);
  };
  const handleRadioPrincipal = (value: any) => {
    setPricipalRadio(value.target.value);
  };

  const handlePlacementDrive = (value: any) => {
    setPlacementRadio(value.target.value);
  };

  const handleOfferLetter = (value: any) => {
    setOfferLetter(value.target.value);
  };

  const handleMediaCoverage = (value: any) => {
    setMediaRadio(value.target.value);
  };

  const handleWorkshopMain = (e: any) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", "WORKSHOP_MAIN");
    formData.append("subtype", "IMAGE");

    mutate(formData);
  };

  const { mutate: mutateKeys } = useMutation(uploadMediaWorkshop, {
    onSuccess: (res: any) => {
      navigate("/workshop/storage");
    },
    onError: (err: any) => {},
  });

  const handleRadioMain = (value: any) => {
    setrRadiomain(value.target.value);
  };
  const { data, isLoading } = useQuery("workshopbyid", () =>
    http.get(`/workshop/${id}`).then((res) => res.data)
  );

  const handleVedioMain = () => {
    let formData = {
      url: mainvedioURL,
      type: "WORKSHOP_MAIN",
      subtype: "VIDEO",
    };

    mutate(formData);
  };

  const { mutate: deletemutate } = useMutation(deleteMedia, {
    onSuccess: (res: any) => {
      toast.error("Media Deleted Succesfully");
      setBigmain("");
    },
  });

  const { mutate: deletemutateVideo } = useMutation(deleteMediaVideo, {
    onSuccess: (res: any) => {
      toast.error("Media Deleted Succesfully");
      setBigmain("");
    },
  });

  const handleDelete = () => {
    let key = bigmain.split(".com/", 2);
    deletemutate(key[1]);
  };

  const handleDeleteVideo = () => {
    let key = bigmain.split(".com/", 2);

    deletemutateVideo(key[1]);
  };

  const handleSave = () => {
    let data = { ids: keys };
    mutateKeys({ data, id });
  };
  const principalspeechArray = data?.media?.filter(
    (item: any) => item.type === "PRINCIPAL_SPEECH"
  );

  const placementDriveArray = data?.media?.filter(
    (item: any) => item.type === "PLACEMENT_DRIVE"
  );

  const feedbackArray = data?.media?.filter(
    (item: any) => item?.type === "TRAINEE_FEEDBACK"
  );
  const offerLetterArray = data?.media?.filter(
    (item: any) => item?.type === "OFFER_LETTER"
  );

  const mediacoverageArray = data?.media?.filter(
    (item: any) => item?.type === "MEDIA_COVERAGE"
  );

  const newspaperArray = data?.media?.filter(
    (item: any) => item?.type === "NEWSPAPER_COVERAGE"
  );

  const workshopmainArray = data?.media?.filter(
    (item: any) => item?.type === "WORKSHOP_MAIN"
  );

  const workshopCoverArray = data?.media?.filter(
    (item: any) => item?.type === "WORKSHOP_COVER"
  );

  const WorkshopArray = data?.media?.filter(
    (item: any) => item?.type === "WORKSHOP"
  );

  const handleCoverImage = (e: any) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", "WORKSHOP_COVER");
    formData.append("subtype", "IMAGE");
    // setkeys([...keys, 1]);
    mutate(formData);
  };

  const { mutate } = useMutation(uploadWorkshopMedia, {
    onSuccess: (res: any) => {
      setkeys([...keys, res.data?.mediaObject?.id]);
    },
  });


  if (!workshopCoverArray) return <Loader />;

  return (
    <Box mt={4}>
      <Box
        sx={{
          position: "fixed",
          bottom: "0px",
          zIndex: 1,
          width: "85%",
        }}
      >
        <Card elevation={5}>
          <Box display="flex" justifyContent="flex-end" p="10px">
            <Button
              onClick={handleSave}
              style={{
                backgroundColor: "#2D5696",
                color: "#FFFFFF",
                marginRight: "8%",
              }}
            >
              save
            </Button>
          </Box>
        </Card>
      </Box>
      <Box mb={2}>
        <Link to={"/workshop/storage"}>
          <Typography color="#2D5696" fontSize="18px" fontWeight="600">
            {"< "}
            Edit Workshop Details
          </Typography>
        </Link>
      </Box>
      <Card elevation={3} sx={{ maxWidth: "92vw" }}>
        <Box
          p={3}
          margin="0px 20px"
          display="flex"
          justifyContent="space-between"
        >
          <CustomTitle title="Workshop Title" value={data?.title} />
          <CustomTitle title="Batch" value={data?.batch?.batch} />
          <CustomTitle title="District" value={data?.district?.name} />
          <CustomTitle title="Category" value={data?.category} />
        </Box>
      </Card>
      <Box>
        <Box display="flex" gap={2} mt={2}>
          <Typography fontWeight="600">Workshop Cover</Typography>
        </Box>
        <Box
          sx={{
            mt: "2px",
            bgcolor: `#2D5696`,
            height: "6px",
            width: "40px",
            borderRadius: "5px",
          }}
        ></Box>
        <Box maxWidth="200px" mt={2}>
          <input
            type="file"
            accept="image/jpg, image/jpeg, image/png"
            onChange={handleCoverImage}
            name="upload"
            id="workshopCover"
            style={{ display: "none" }}
          />
          <label
            htmlFor="workshopCover"
            style={{
              display: "inline",
              width: "100%",
              height: "150px",
              backgroundColor: "red",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                maxWidth: "100%",
                minHeight: "186px",
                border: "1px dashed grey",
                backgroundColor: "#F2F2F2",
                borderRadius: "10px",
                display: "flex",
                position: "relative",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/images/image-icon.png"
                width="40px"
                style={{ marginLeft: "20px" }}
              ></img>
              <Typography sx={{ fontSize: "14px", opacity: 0.5 }}>
                Upload image
              </Typography>
            </Box>
          </label>
        </Box>
        <Box mt={2} position="relative" maxWidth="260px">
          {workshopCoverArray.length > 0 && (
            <img
              style={{
                border: "1px solid #bab5b5",
                objectFit: "contain",
                width: "100%",
                height: "250px",
                display: "inline",
                borderRadius: "20px",
              }}
              draggable={false}
              src={workshopCoverArray[0]?.url}
              alt=""
            />
          )}
        </Box>
      </Box>

      <WorkshopComponent
        title="Main Videos/ images"
        state={radiomain}
        type="WORKSHOP_MAIN"
        handleRadio={handleRadioMain}
        setKeys={setkeys}
        array={workshopmainArray}
      />
      <WorkshopComponent
        title="Slider images"
        state={radioslider}
        type="WORKSHOP"
        setKeys={setkeys}
        array={WorkshopArray}
        radio="off"
      />
      {data?.category === "PLACEMENT DRIVE" ? (
        <>
          <WorkshopComponent
            title="Placement Drive Highlights"
            state={placementRadio}
            type="PLACEMENT_DRIVE"
            handleRadio={handlePlacementDrive}
            setKeys={setkeys}
            array={placementDriveArray}
          />
          <WorkshopComponent
            title="Trainees with offer letter"
            state={offerLetterRadio}
            handleRadio={handleOfferLetter}
            type="OFFER_LETTER"
            setKeys={setkeys}
            array={offerLetterArray}
          />

          <WorkshopComponent
            title="Media Coverage"
            state={mediaRadio}
            handleRadio={handleMediaCoverage}
            type="MEDIA_COVERAGE"
            setKeys={setkeys}
            array={mediacoverageArray}
          />
        </>
      ) : (
        <>
          <WorkshopComponent
            title="Nodal Officer / ALO /Principal Speech"
            state={principalRadio}
            type="PRINCIPAL_SPEECH"
            handleRadio={handleRadioPrincipal}
            setKeys={setkeys}
            array={principalspeechArray}
          />
          <WorkshopComponent
            title="Trainees Feedabck"
            state={feedbacksubtype}
            handleRadio={handleRadioFeedback}
            type="TRAINEE_FEEDBACK"
            setKeys={setkeys}
            array={feedbackArray}
          />
          <WorkshopComponent
            title="News paper Coverage"
            state={newspapersubtype}
            handleRadio={handleRadionewcoverage}
            type="NEWSPAPER_COVERAGE"
            setKeys={setkeys}
            array={newspaperArray}
          />
        </>
      )}

      <Box mb="35px"></Box>
    </Box>
  );
}

export default EditWorkshop;

const CustomTitle = ({ title, value }: any) => {
  return (
    <Box p={1}>
      <Typography sx={{ opacity: 0.5, fontSize: "14px" }}>{title}</Typography>
      <Typography sx={{ fontSize: "16px", mt: "6px", wordWrap: "break-word" }}>
        {value}
      </Typography>
    </Box>
  );
};
