import { CssBaseline } from "@mui/material";
import FullPageLoader from "components/FullPageLoader";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./context/AuthContext";
import AppRouter from "./layout/AppRouter";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <FullPageLoader>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <AppRouter />
            <CssBaseline />
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
      <ToastContainer />
    </FullPageLoader>
  );
}

export default App;
