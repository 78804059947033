import {
  Card,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { getStatistics } from "api";
import BarChart3d from "components/BarChart3d";
import { AppTraineeLoader } from "components/CounterLoader";
import { GridContainer, GridContext } from "components/Grid";
import Header from "components/Header";
import { ApplicationContent } from "pages/stats";
import { useState } from "react";
import { useQuery } from "react-query";
import theme from "styles/theme";
import http from "utils/http";
import Table from "./Table";

const themeOption = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: "#094264",
    },
  },
});

function CertifiedTrainees() {
  const [district, setDistrict] = useState("");
  const [batch, setBatch] = useState("");

  const { data: batchs } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );
  const handleChange = (e: any) => {
    setBatch(e.target.value);
  };

  const { data: CourseCompletion, isLoading: CourseCompletionLdg } = useQuery(
    [
      "statistics",
      {
        districtId: district,
        batch,
        type: "COURSE_COMPLETED",
        educationData: true,
      },
    ],
    getStatistics
  );

  return (
    <ThemeProvider theme={themeOption}>
      <Header
        showDistrictFilter
        batch={batch}
        handleChange={handleChange}
        district={district}
        onChange={(v: string) => setDistrict(v)}
      />
      <GridContainer>
        <Card
          sx={{
            boxShadow: "0px 6px 16px #2146831f",
            borderRadius: "5px",
            marginBottom: "2rem",
            width: "100%",
            height: 400,
          }}>
          {CourseCompletion?.data ? (
            <BarChart3d
              data={CourseCompletion?.data?.educationData.map(
                ({ educationName, userCount }: any) => ({
                  label: educationName,
                  value: userCount,
                  color: (() => {
                    switch (educationName) {
                      case "10th":
                        return "#2d5696";
                      case "Inter":
                        return "#FFA500";
                      case "ITI":
                        return "#FF69B4";
                      case "Polytechnic":
                        return "#0c9fe4";
                      case "Degree":
                        return "#20a87d";
                      case "Engineering":
                        return "#7461c9";
                    }
                  })(),
                })
              )}
              xAxisName={"Education Qualification"}
              yAxisName={"Number of Certified Trainees"}
              caption={"Certified Trainees"}
              unitY={""}
              barColor={"#20a87d"}
            />
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          )}
        </Card>
        <GridContext>
          <ApplicationContent
            title={"Certified Trainees"}
            count={
              CourseCompletion ? (
                CourseCompletion?.data?.count
              ) : (
                <AppTraineeLoader />
              )
            }
            src={"/images/certified-trainees.gif"}
            alt={"lapmIcon"}
            color={"#094264"}
          />
        </GridContext>
      </GridContainer>
      <Table distCode={district} batch={batch} setDistrict={setDistrict} />
    </ThemeProvider>
  );
}

export default CertifiedTrainees;
