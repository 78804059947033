import { Close } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { updateRegisteredTrainee } from "api";
import { Dayjs } from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import http from "utils/http";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  data: any;
}

function UpdateRegisteredTrainee({ open, setOpen, data }: Props) {
  const [state, setState] = useState<any>({});
  const [value, setValue] = useState<Dayjs | null>(null);
  const [dateofbirth, setDateofBirth] = useState("");
  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateRegisteredTrainee, {
    onSuccess: () => {
      toast.success("Updated");
      queryClient.invalidateQueries("applications");
      setOpen(false);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { data: districts } = useQuery("district", () =>
    http.get("/district").then((res) => res.data)
  );

  const { data: education } = useQuery("education", () =>
    http.get("/education").then((res) => res.data)
  );

  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (value) {
      const date = new Date(`${value}`);
      setDateofBirth(moment(date).format("YYYY-MM-DD"));
    }
  }, [setValue, value]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    mutate({
      id: data?.id,
      data: {
        ...state,
        dateOfBirth: dateofbirth,
        status:
          state?.status === "waiting"
            ? null
            : state?.status === "success"
            ? true
            : "fail",
      },
    });
  };

  useEffect(() => {
    setState({
      aadharNumber: data?.aadharNumber || "",
      mandal: data?.mandal || "",
      reqCode: data?.reqCode || "",
      mobile: data?.mobile || "",
      status:
        data?.status === null
          ? "waiting"
          : data?.status === true
          ? "success"
          : "fail",
      gender: data?.gender || "",
      higherEducation: data?.education || "",
      workerName: data?.workerName || "",
      educationStatus: data?.educationStatus || "",
      village: data?.village || "",
      district: data?.district || "",
      ipaddress: data?.ipAddress || "",
    });
  }, [data]);

  return (
    <Drawer
      anchor="right"
      sx={{ zIndex: 1202 }}
      PaperProps={{ sx: { width: 550 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <AppBar position="sticky">
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="subtitle1">Update Registered Trainee</Typography>
          <IconButton onClick={() => setOpen(false)} sx={{ color: "white" }}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            onChange={handleChange}
            variant="outlined"
            size="small"
            label="Mobile Number"
            name="mobile"
            value={state?.mobile}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            size="small"
            name="higherEducation"
            label="Education Qualification"
            value={state?.higherEducation}
            select
            onChange={handleChange}
          >
            {education?.map((item: any) => (
              <MenuItem value={item.name}>{item?.name}</MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            size="small"
            name="reqCode"
            label="Req Code"
            value={state.reqCode}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            size="small"
            name="aadharNumber"
            label="Aadhar Number"
            value={state?.aadharNumber}
            onChange={handleChange}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth"
              disableFuture
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => (
                <TextField sx={{ mt: 2 }} size="small" fullWidth {...params} />
              )}
            />
          </LocalizationProvider>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            size="small"
            name="status"
            label="Status"
            value={state.status}
            select
            onChange={handleChange}
          >
            <MenuItem value="waiting">Waiting</MenuItem>
            <MenuItem value="success">Success</MenuItem>
            <MenuItem value="fail">Fail</MenuItem>
          </TextField>

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            size="small"
            name="gender"
            label="Gender"
            value={state.gender}
            select
            onChange={handleChange}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="others">Others</MenuItem>
          </TextField>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            size="small"
            name="workerName"
            label="worker/Dependent Name"
            value={state.workerName}
            onChange={handleChange}
          ></TextField>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            size="small"
            name="educationStatus"
            label="Education Status"
            value={state.educationStatus}
            select
            onChange={handleChange}
          >
            <MenuItem value="studying">Studying</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
          </TextField>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            size="small"
            name="village"
            label="village"
            value={state?.village}
            onChange={handleChange}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            size="small"
            label="Mandal"
            name="mandal"
            value={state.mandal}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            size="small"
            name="district"
            label="district"
            value={state?.district}
            select
            onChange={handleChange}
          >
            {districts?.map((item: any) => (
              <MenuItem value={item.name}>{item?.name}</MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            size="small"
            name="ipAddress"
            label="IP Address"
            value={state?.ipAddress}
            onChange={handleChange}
          />

          <Box mt={2} textAlign="right">
            <Button
              style={{ backgroundColor: "#f50000" }}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
}

export default UpdateRegisteredTrainee;
