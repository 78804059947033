import { Button, CircularProgress, Link as MLink } from "@mui/material";
import {
  bulkUpdateTimeStatistics,
  exportTimeStatistics,
  getHalfCompletedUsers,
} from "api";
import Filters from "components/Filters";
import { useFullPageLoader } from "components/FullPageLoader";
import ReactTable from "components/ReactTable";
import { UserProfileStore } from "layout/AppRouter";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddTime from "./AddTime";

const filters = {
  perPage: 100,
  page: 1,
  search: "",
  course: {
    id: 0,
    name: null,
    courseId: "",
    courseBatchNumber: null,
  },
  education: "",
  district: "",
  batch: "",
};

interface Props {
  batch?: string;
  distCode?: string;
  setDistrict?: any;
}

function Table(props: Props) {
  const role = UserProfileStore.useState((s) => s.state.role);
  const [filter, setFilter] = useState(filters);
  const queryClient = useQueryClient();
  const { setLoading } = useFullPageLoader();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, isLoading } = useQuery(
    [
      "half-completed-applicants-data",
      {
        ...filter,
        course: filter?.course?.courseId,
        offset: (filter.page - 1) * filter.perPage,
        limit: filter.perPage,
        district: props.distCode,
        batch: props.batch,
      },
    ],
    getHalfCompletedUsers
  );

  useEffect(() => {
    setFilter({
      ...filter,
      batch: !props?.batch ? "" : props!.batch,
      district: !props?.distCode ? "" : props!.distCode,
    });
  }, [props.batch, props.distCode]);

  const { mutate } = useMutation(exportTimeStatistics, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "Time Statistics.xlsx";
      link.click();
      toast.success("Exported successfully");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate: bulkUpdate } = useMutation(bulkUpdateTimeStatistics, {
    onSuccess: (res: any) => {
      toast.success("Imported successfully");
      queryClient.invalidateQueries("time-statistics");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleExport = () => {
    setLoading(true);
    mutate({
      ...filter,
      course:
        (filter?.course?.courseId == "" ? "" : filter?.course?.courseId) ==
        undefined
          ? ""
          : filter?.course?.courseId,
      batch: props?.batch,
      district: props?.distCode,
    });
  };

  function handlePage(index: any) {
    setFilter((prev) => ({ ...prev, page: prev.page + index }));
  }

  const handleImportOnClick = async () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) return;
      const formData = new FormData();
      formData.append("file", file);
      setLoading(true);
      bulkUpdate(formData);
    };

    input.click();
  };

  return (
    <>
      <Filters
        filters={filter}
        setFilters={setFilter}
        distCode={props.distCode}
        setDistrict={props.setDistrict}
      />
      <div className="flex gap-4 items-center justify-end">
        {role === "SUPER_ADMIN" && (
          <Button
            variant="contained"
            startIcon={<img src="/images/bulkupload.svg" width="16px" />}
            style={{
              backgroundColor: "#F2F2F2",
              color: "#1C1C1C",
              marginBottom: "5px",
              padding: "5px 10px",
              fontSize: "14px",
            }}
            onClick={handleImportOnClick}>
            Bulk Update
          </Button>
        )}
        {(role == "ADMINISTRATOR" ||
          role == "SUPER_ADMIN" ||
          role == "SME_ADMIN") && (
          <Button
            variant="contained"
            startIcon={<img src="/images/export_execl.svg" width="16px" />}
            style={{
              backgroundColor: "#F2F2F2",
              color: "#1C1C1C",
              margin: "0px 10px",
              padding: "5px 10px",
              fontSize: "14px",
            }}
            onClick={handleExport}>
            Export to Excel
          </Button>
        )}
        {(role == "ADMINISTRATOR" ||
          role == "SUPER_ADMIN" ||
          role == "SME_ADMIN") && (
          <MLink
            underline="none"
            style={{
              margin: "0px 5px",
              padding: "0px",
            }}
            href={`/print-table?${new URLSearchParams({
              ...filter,
              course:
                (filter?.course?.courseId == ""
                  ? ""
                  : filter?.course?.courseId) == undefined
                  ? ""
                  : filter?.course?.courseId,
              batch: props?.batch,
              district: props?.distCode,
            } as any).toString()}&category=timeStatistics`}
            target={"_blank"}>
            <Button
              variant="contained"
              startIcon={<img src="/images/export_pdf.svg" width="16px" />}
              style={{
                backgroundColor: "#F2F2F2",
                color: "#1C1C1C",
                padding: "5px 10px",
                fontSize: "14px",
              }}>
              Export to Pdf
            </Button>
          </MLink>
        )}
      </div>
      {isLoading && <CircularProgress></CircularProgress>}
      {data && (
        <ReactTable
          columns={columns({
            showEdit: role === "SUPER_ADMIN",
          })}
          data={data.data?.result}
          handleButton={handlePage}
          page={filter.page}
          setFilter={setFilter}
          pageCount={Math.ceil(data?.data?.totalCount / filter.perPage)}
          perPage={filter.perPage}
          bgHeaderColor="#20a87d"
        />
      )}
      <AddTime
        open={!!searchParams.get("addTime")}
        setOpen={(v) => setSearchParams({})}
      />
    </>
  );
}

export const columns: any = ({ showEdit }: any) => {
  return [
    {
      header: "Sr.No",
      accessor: "id",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    {
      header: "BOCW ID",
      accessor: "bocRegNo",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    {
      header: "Trainee Name",
      accessor: "name",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    {
      header: "Mobile Number",
      accessor: "mobile",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    {
      header: "Aadhar Number",
      accessor: "aadharNumber",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    {
      header: "Education Qualification",
      accessor: "higherEducation",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    {
      header: "Course Name",
      accessor: "course",
      Cell: ({ value }: { value: any }) => value?.name ?? "",
    },
    {
      header: "Setwin ID",
      accessor: "formId",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    {
      header: "Min Hours to avail 50% bill",
      accessor: "halfCourseTime",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    {
      header: "Course Start Date",
      accessor: "startDate",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    {
      header: "50% Course Duration Date",
      accessor: "halfCompletedDate",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    {
      header: (
        <p>
          Actual Hours spent
          <br />
          by the Trainee
        </p>
      ),
      accessor: "timeSpent.timeSpent",
      Cell: ({ value }: { value: any }) => {
        return (parseInt(value) / 3600).toFixed(2) ?? "";
      },
    },
    {
      header: "District",
      accessor: "district",
      Cell: ({ value }: { value: any }) => value ?? "",
    },
    ...(showEdit
      ? [
          {
            header: "Actions",
            accessor: "district.name",
            Cell: ({ value, row }: any) => {
              return (
                <Link
                  className="hover:underline text-primary font-medium"
                  to={`?addTime=true&selected=${row?.values?.formId}`}>
                  Add Hours
                </Link>
              );
            },
          },
        ]
      : []),
  ];
};

export default Table;
