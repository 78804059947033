import { Box, Button, CircularProgress, Link } from "@mui/material";
import { exportHalfCompleted, getHalfCompletedUsers } from "api";
import Filters from "components/Filters";
import { useFullPageLoader } from "components/FullPageLoader";
import ReactTable from "components/ReactTable";
import { UserProfileStore } from "layout/AppRouter";
import moment from "moment";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

const filters = {
  perPage: 100,
  page: 1,
  search: "",
  course: {
    id: 0,
    name: null,
    courseId: "",
    courseBatchNumber: null,
  },
  education: "",
  district: "",
};

interface Props {
  batch?: string;
  distCode?: string;
  setDistrict?: any;
}

function Table(props: Props) {
  const [filter, setFilter] = useState(filters);
  const role = UserProfileStore.useState((s) => s.state.role);
  const { setLoading } = useFullPageLoader();

  const { data: applicantsData, isLoading } = useQuery(
    [
      "half-completed-applicants-data",
      {
        ...filter,
        offset: (filter.page - 1) * filter.perPage,
        limit: filter.perPage,
        district: props.distCode,
        batch: props.batch,
        course: filter?.course?.courseId,
      },
    ],
    getHalfCompletedUsers
  );

  const { mutate } = useMutation(exportHalfCompleted, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "50% Completed.xlsx";
      link.click();
      toast.success("Exported successfully");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleExport = () => {
    setLoading(true);
    mutate({
      ...filter,
      course: filter.course.courseId,
      district: props.distCode,
      batch: props.batch,
    });
  };

  function handlePage(index: any) {
    setFilter((prev) => ({ ...prev, page: prev.page + index }));
  }

  return (
    <>
      <Filters
        filters={filter}
        setFilters={setFilter}
        distCode={props.distCode}
        setDistrict={props.setDistrict}
      />
      <div className="flex gap-2 items-center justify-end">
        {(role == "ADMINISTRATOR" ||
          role == "SUPER_ADMIN" ||
          role == "SME_ADMIN") && (
          <Button
            variant="contained"
            startIcon={<img src="/images/export_execl.svg" width="16px" />}
            style={{
              backgroundColor: "#F2F2F2",
              color: "#1C1C1C",
              margin: "0px 10px",
              padding: "5px 10px",
              fontSize: "14px",
            }}
            onClick={handleExport}
          >
            Export to Excel
          </Button>
        )}
        {(role == "ADMINISTRATOR" ||
          role == "SUPER_ADMIN" ||
          role == "SME_ADMIN") && (
          <Link
            underline="none"
            style={{
              margin: "0px 5px",
              padding: "0px",
            }}
            href={`/print-table?${new URLSearchParams({
              ...filter,
              course:
                (filter?.course?.courseId == ""
                  ? ""
                  : filter?.course?.courseId) == undefined
                  ? ""
                  : filter?.course?.courseId,
              district: props?.distCode,
              batch: props?.batch,
            } as any).toString()}&category=courseCompletion`}
            target={"_blank"}
          >
            <Button
              variant="contained"
              startIcon={<img src="/images/export_pdf.svg" width="16px" />}
              style={{
                backgroundColor: "#F2F2F2",
                color: "#1C1C1C",
                padding: "5px 10px",
                fontSize: "14px",
              }}
            >
              Export to Pdf
            </Button>
          </Link>
        )}
      </div>
      <Box mt={1}></Box>
      {isLoading && <CircularProgress />}
      {applicantsData && (
        <ReactTable
          columns={COLUMNS}
          data={applicantsData.data?.result}
          handleButton={handlePage}
          page={filter.page}
          setFilter={setFilter}
          pageCount={Math.ceil(
            applicantsData?.data?.totalCount / filter.perPage
          )}
          perPage={filter.perPage}
          bgHeaderColor="#0c9fe4"
        />
      )}
    </>
  );
}

const COLUMNS = [
  {
    header: "Sr.No",
    accessor: "id",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "BOCW ID",
    accessor: "bocRegNo",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Trainee Name",
    accessor: "name",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Mobile Number",
    accessor: "mobile",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Aadhar Number",
    accessor: "aadharNumber",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Education Qualification",
    accessor: "higherEducation",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Course Name",
    accessor: "course",
    Cell: ({ value }: { value: any }) => value?.name ?? "",
  },
  {
    header: "Setwin ID",
    accessor: "formId",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Min Hours to avail 50% bill",
    accessor: "halfCourseTime",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "Course Start Date",
    accessor: "startDate",
    Cell: ({ value }: { value: any }) => {
      return value ? moment(value).format("YYYY-MM-DD") : "";
    },
  },
  {
    header: "50% Course Duration Date",
    accessor: "halfCompletedDate",
    Cell: ({ value }: { value: any }) => {
      return value ? moment(value).format("YYYY-MM-DD") : "";
    },
  },

  {
    header: (
      <p>
        Actual Hours spent
        <br />
        by the Trainee
      </p>
    ),
    accessor: "timeSpent.timeSpent",
    Cell: ({ value }: { value: any }) => {
      return (parseInt(value) / 3600).toFixed(2) ?? "";
    },
  },

  {
    header: "District",
    accessor: "district",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
  {
    header: "50% Completed",
    accessor: "halfCompleted",
    Cell: ({ value }: { value: any }) => value ?? "",
  },
];

export default Table;
