import styled from "@emotion/styled"
import { Box, Divider, Stack, Typography } from "@mui/material"
import { getFeedbacks } from "api";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";



const PrintFeedbackForms = () => {
  const location = useLocation();
  const params = parseParams(location.search);
  const {isLoading,data}=useQuery(["pdf-feedbackform-data",{
    ...params,
    districtId:params.district

  }],getFeedbacks)
  
  if (isLoading) return <div className="p-5">Loading...</div>;


  return (
    <div >
      {
        data?.data?.count===0 ?(
          <div className="p-5">No Data Found</div>
        ):(
          data?.data?.data?.map((item:any,index:number)=>{
            
            return(
            <div key={index}>
              <FeedbackTemplate index={index} data={item} />
              <Divider
                className="print-hide"
                sx={{
                  border: '2px solid black',
                  width: '70%',
                  marginLeft: '15%',
                }}
              />
            </div>

          )})
        )
      }
      
        {/* <FeedbackTemplate />
        <Divider className="print-hide" sx={{border:"2px solid black"}}/>
        <FeedbackTemplate /> */}
    </div>
  )
}

export default PrintFeedbackForms

const FeedbackTemplate=({index,data}:any)=>{
    const getDate=(timestamp:any)=>{
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); 
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    }
    const getTime=(timestamp:any)=>{
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; 
    return `${hours}:${minutes} ${period}`;
    }
    
    const sections = [
      {
        title: 'PARTICIPANT DEMOGRAPHICS',
        fields: [
          { label: 'Mobile Number', value: data?.form?.mobile },
          { label: 'Full Name Of The participant', value: data?.form?.name },
          { label: 'SETWIN Training ID', value: data?.form?.formId },
          { label: 'District', value: data?.form?.district },
          { label: 'Age Of The Participant', value: data?.age },
          { label: 'Gender', value: data?.gender },
          { label: 'Employment Status Prior To Training', value: data?.priorEmployment },
          { label: 'Current Employment Status', value: data?.currentEmploymentStatus },
          { label: 'Social Group', value: data?.socialGroup },
          { label: 'Is Your Parents/father A Construction worker?', value: data?.isConstructionWorker ? "Yes" : "No" },
        ],
      },
      {
        title: 'PRE-TRAINING INFORMATION',
        fields: [
          { label: 'How Did You Hear About The Training Program?', value:data?.source },
          { label: 'Was This your First Skill Training Program?', value: data?.isFirstTraining ? "Yes" : "No" },
        ],
      },
      {
        title: 'TRAINING DETAILS',
        fields: [
          { label: 'Name Of The Training Course Attended', value: data?.form?.course?.name  },
          { label: 'Duration Of The Course', value: `${data?.form?.course?.duration } Months` },
          { label: 'Quality Of Training Materials Provided', value: data?.qualityOfTraining },
          { label: 'Relevance Of The Training Content To Your Expected Job Role', value: data?.relevanceOfTraining },
          { label: 'Instructors Teaching Method Effectiveness', value: data?.instructorEffectiveness },
          { label: 'Assessment Methods Used During The Training', value: data?.assessmentMethod },
        ],
      },
      {
        title: 'TRAINING EVALUATION',
        fields: [
          { label: 'Certification Received', value: data?.isCertificationReceived ? "Yes" : "No" },
          { label: 'How Did You Feel Immediately After Completing Training', value: data?.postTrainingConfidence },
          { label: 'Opportunities For Career Advancement Post- Training', value: data?.careerOpportunities },
          { label: 'Additional Training Or Skills Needed', value: data?.additionalTrainingRequired },
          { label: 'Recommendations For Others Considering Similar Training', value: data?.recommendation },
          { label: 'Overall Opinion About The Programme', value: data?.overallOpinion },
          { label: 'Form Submitted On The Date', value: getDate(data?.createdAt) },
          { label: 'Form Submitted On The Time', value: getTime(data?.createdAt) },
        ],
      },
    ];


    return(
      <StyledFeedBackComponent className="print-margin" sx={{marginLeft:"17%",marginBottom:"5%",marginTop:"3%"}}>
        <Typography
          fontWeight='600'
          sx={{ marginBottom:"10px", fontSize: "18px",color:"#1E4B8F" }}
        >
          SETWIN Online Training Feedback
        </Typography>
        {sections.map((section) => (
          <Stack key={section.title}>
            <Heading value={section.title} />
            {section.fields.map((field, index) => (
              index % 2 === 0 && (
                <StyledFeedbackStackComponent key={field.label}>
                  <PreviewComponent label={section.fields[index].label} value={section.fields[index].value} />
                  {section.fields[index + 1] && (
                    <PreviewComponent label={section.fields[index + 1].label} value={section.fields[index + 1].value} />
                  )}
                </StyledFeedbackStackComponent>
              )
            ))}
          </Stack>
        ))}
      </StyledFeedBackComponent>

    )
}

const parseParams = (querystring: string) => {
  const params = new URLSearchParams(querystring);

  const obj: any = {};

  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};



const PreviewComponent = ({label,value}:any) => {
  return (
    <Box sx={{ gap:"0px", width: '45%' }}>
      <Typography sx={{fontSize:"13px",marginTop: '1px',fontWeight:"400",opacity:"80%" }}>{label}</Typography>
      <Typography  sx={{color:"#020202",opacity:"100%",fontWeight:"600",fontSize:"13px"}}>{value}</Typography>
    </Box>
  )
}

const Heading = ({...props}) => {
  return (
    <Box sx={{
        display:"flex",
        flexDirection:"row",
        gap:"5px",
        marginBottom:"1px"
        }}>
        <Box
        sx={{
            border:"3px solid #002060",
            borderRadius:"4px"
        }}
        >
        </Box>
        <Typography sx={{fontWeight:"600",color:"#002060",fontSize:"14px"}}>{props.value}</Typography> 
      
    </Box>
  )
}

const StyledFeedBackComponent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap:0px;
`;

const StyledFeedbackStackComponent = styled(Stack)`
  display: flex;
  flex-direction: row;
  width: 100%;
  
  @media (min-width: 700px) {
    flex-direction: row;
    gap: 30px; 
  }

  @media (min-width: 960px) {
    flex-direction: row; 
    gap: 0px; 
    align-items:flex-end;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    gap: 0px; 
    align-items:flex-start;
`;



