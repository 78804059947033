import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import "./pdf-print.css";

import { certifiedDownload } from "api";

export const parseParams = (querystring: string) => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj: any = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

export default function CertifiedPrintPdf() {
  const location = useLocation();
  const params = parseParams(location.search);

  const { isLoading, data } = useQuery(["applications"], () =>
    certifiedDownload({
      ...params,
      district:
        params?.district == "undefined" || undefined ? "" : params?.district,
      course: params?.course,
      limit: params?.perPage,
      offset: (params?.page - 1) * params?.perPage,
    })
  );

  if (isLoading) return <div className="p-5">Loading...</div>;

  return (
    <Box>
      {data?.data?.forms?.length == 0 ? (
        <div className="p-5">No Data Found</div>
      ) : (
        data?.data?.forms?.map((item: any, index: number) => (
          <PdfTemplate data={item} key={index} />
        ))
      )}
    </Box>
  );
}

const PdfTemplate = ({ data }: { data: any }) => {
  if (!data) return null;
  return (
    <>
      <div className="certified-pdf-template">
        <header style={{ marginTop: "200px" }}>
          <div className="title" style={{ width: "100%" }}>
            <h1 style={{ fontWeight: "bold", textDecoration: "underline" }}>
              UNDERTAKING
            </h1>
          </div>
        </header>
        <main>
          <div style={{ margin: "25px 40px" }}>
            <div className="certified-main">
              <div>I, </div>
              <div className="certified-value">{data?.name}</div>
            </div>
            <div className="certified-main">
              <div>Dependent of </div>
              <div className="certified-value">{data?.workerName}</div>
            </div>

            <div className="certified-main">
              <div>Whose BOCW ID is: </div>
              <div className="certified-value">{data?.bocRegNo}</div>
            </div>
            <div>here by declare and confirm that;</div>
            <div
              style={{
                marginTop: "25px",
                marginLeft: "50px",
                marginBottom: "25px",
              }}
            >
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  I have enrolled the course on www.tbocw.com SETWIN Online
                  Training myself.
                </li>
                <li>
                  I have undergone the training myself without any
                  impersonation.
                </li>
                <li>
                  I have participated myself in the online test without any
                  impersonation.
                </li>
              </ul>
            </div>

            <div style={{ marginBottom: "8px" }}>
              And hence, I am eligible to receive the Certificate of Completion
              in
              <div
                style={{
                  textAlign: "center",
                  borderBottom: " 1px solid rgba(0, 0, 0, 0.1)",
                  margin: "0px 5px",
                  fontWeight: "bold",
                  color: "#c60260",
                }}
              >
                {data?.course?.name}
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div
            className="signs"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "100px",
            }}
          >
            <div style={{ width: "350px" }}>
              <div
                style={{
                  height: "100px",
                  width: "350px",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                }}
              ></div>
              <p style={{ textAlign: "center", paddingTop: "10px" }}>
                Signature of the Candidate
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
