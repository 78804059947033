import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { getStatistics } from "api";
import { StyledCards } from "components/Cards";
import {
  AppTraineeLoader,
  RegTraineeLoader,
  TimeStatsLoader,
} from "components/CounterLoader";
import Header from "components/Header";
import { useState } from "react";
import { useQuery } from "react-query";
import Lottie from "lottie-react";
import http from "utils/http";

const DashboardStats = () => {
  const [batch, setBatch] = useState("");
  const [districtId, setDistCode] = useState("");

  const { data: batchs } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const currentyear = new Date().getFullYear();

  const handleChange = (e: any) => {
    setBatch((data) => e.target.value);
  };

  const { data: Registered, isLoading: RegisteredLdg } = useQuery(
    ["statistics", { batch, districtId, type: "REGISTRATION" }],
    getStatistics
  );

  const { data: Approved, isLoading: ApprovedLdg } = useQuery(
    ["statistics", { batch, districtId, type: "APPROVED" }],
    getStatistics
  );

  const { data: TimeStats, isLoading: TimestatsLdg } = useQuery(
    ["statistics", { batch, districtId, type: "TIME_STATISTICS" }],
    getStatistics
  );

  const { data: HalfCourse, isLoading: HalfCourseLdg } = useQuery(
    ["statistics", { batch, districtId, type: "HALF_COMPLETED" }],
    getStatistics
  );

  const { data: CourseCompletion, isLoading: CourseCompletionLdg } = useQuery(
    ["statistics", { batch, districtId, type: "COURSE_COMPLETED" }],
    getStatistics
  );

  return (
    <>
      <Header
        batch={batch}
        handleChange={handleChange}
        showDistrictFilter
        district={districtId}
        onChange={(v: string) => setDistCode(v)}
      />
      <Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "62% 36%",
            justifyContent: "space-between",
            "@media (max-width:900px)": {
              display: "block",
            },
          }}
        >
          <StyledCards>
            <img
              style={{ width: "650px", margin: "0.5rem" }}
              src={districtId ? `/images/${districtId}.png` : "/images/ALL.png"}
              alt="Telangana Districts Map"
            />
          </StyledCards>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              "@media (max-width:900px)": {
                display: "grid",
                justifyContent: "space-between",
                gridTemplateColumns: "47% 47%",
              },
              "@media (max-width:590px)": {
                gridTemplateColumns: "100%",
              },
            }}
          >
            <ApplicationContent
              title={"Registered Trainees"}
              count={
                Registered ? Registered?.data?.count : <RegTraineeLoader />
              }
              isLoading={RegisteredLdg}
              src={"/images/registered-trainees.gif"}
              alt={"filesIcon"}
              color={"#2d5696"}
            />

            <ApplicationContent
              title={"Approved Trainees"}
              count={Approved ? Approved?.data?.count : <AppTraineeLoader />}
              isLoading={true}
              src={"/images/approved-trainees.gif"}
              alt={"tickIcon"}
              color={"#7461c9"}
            />

            <ApplicationContent
              title={"Time Statistics"}
              count={TimeStats ? TimeStats?.data?.count : <TimeStatsLoader />}
              src={"/images/time-statistics.gif"}
              alt={"tickIcon"}
              color={"#20a87d"}
            />
            <ApplicationContent
              title={"50% Course Completion"}
              count={HalfCourse ? HalfCourse?.data?.count : <TimeStatsLoader />}
              src={"/images/course-completion.gif"}
              alt={"piechartIcon"}
              color={"#0c9fe4"}
            />
            <ApplicationContent
              title={"Course Completion"}
              count={
                CourseCompletion ? (
                  CourseCompletion?.data?.count
                ) : (
                  <TimeStatsLoader />
                )
              }
              src={"/images/certified-trainees.gif"}
              alt={"lapmIcon"}
              color={"#094264"}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardStats;

export function ApplicationContent({
  src,
  alt,
  title,
  count,
  color,
  ...props
}: any) {
  return (
    <>
      <StyledCards style={{ background: `${color}`, ...props }}>
        <Box>
          {src && <img
            style={{
              margin: "auto",
              width: "120px",
              maxWidth: "120px",
              borderRadius: "5px",
            }}
            src={src}
            alt={alt}
          />}
        </Box>
        <Box
          sx={{
            color: "white",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              whiteSpace: "nowrap",
              fontWeight: "700",
              textAlign: "left",
              "@media (max-width:900px)": {
                fontSize: "12px",
              },
            }}
          >
            {title}
          </Typography>

          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              textAlign: "center",
              "@media (max-width:900px)": {
                fontSize: "16px",
              },
            }}
          >
            {count}
          </Typography>
        </Box>
      </StyledCards>
    </>
  );
}
