import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  deleteMedia,
  deleteMediaVideo,
  uploadMedia,
  uploadWorkshopMedia,
  workshopUploadMedia,
} from "api";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import CategoryIcon from "@mui/icons-material/Category";
import EditIcon from "@mui/icons-material/Edit";
import SortDialog from "./SortDilog";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";

function WorkshopComponent({
  title,
  state,
  handleRadio,
  type,
  setKeys,
  array,
  radio,
}: any) {
  const [files, setFiles] = useState<File[]>([]);
  const [id, setId] = useState("");
  const [vediourl, setVediourl] = useState("");
  const [preview, setPreview] = useState([]);
  const [image, setImage] = useState<any>([]);
  const [vedio, setVedio] = useState<any>([]);
  const [sortEdit, setSortEdit] = useState(false);

  const queryClient = useQueryClient();

  const handleSChange = (e: any) => {
    setFiles([e.target.files[0]]);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", type);
    formData.append("subtype", state);

    mutate(formData);
  };
  function isUrlValid(userInput: any) {
    var res = userInput.match(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    );
    if (res == null) return false;
    else return true;
  }

  const { mutate } = useMutation(uploadWorkshopMedia, {
    onSuccess: (res: any) => {
      if (state === "VIDEO") {
        if (isUrlValid(vediourl)) {
          setVedio((prev: any) => [...prev, res?.data?.videoUrl]);
          setKeys((prev: any) => [...prev, res.data?.id]);
        } else {
        }
      } else {
        setImage((prev: any) => [...prev, res?.data?.url]);
        setKeys((prev: any) => [...prev, res.data?.mediaObject?.id]);
      }
    },
  });

  const { mutate: deletemutate } = useMutation(deleteMedia, {
    onSuccess: (res: any) => {
      toast.error("Media Deleted Succesfully");
      queryClient.invalidateQueries("workshopbyid");
    },

    onError: (err: any) => {},
  });

  const handleVedio = () => {
    let formData = {
      url: vediourl,
      type: type,
      subtype: state,
    };

    mutate(formData);

    setVediourl("");
  };

  const { mutate: deletemutateVideo } = useMutation(deleteMediaVideo, {
    onSuccess: (res: any) => {
      toast.error("Video Deleted Succesfully");
      queryClient.invalidateQueries("workshopbyid");
    },
  });

  const handleD = (item: any) => {
    deletemutate(item?.key);
  };

  const handleliveVedio = (item: any) => {
    deletemutateVideo(item?.url);
  };

  const handleDeleteImage = (item: any) => {
    setImage((prev: any) => prev.filter((i: any) => i != item));
    let key = item.split(".com/", 2);
    deletemutate(key[1]);
  };

  const handleDVideo = (item: any) => {
    setVedio((prev: any) => prev.filter((i: any) => i != item));
    let key = item.split(".com/", 2);
    deletemutateVideo(key[1]);
  };

  function mouseEnter(a: any) {
    setId(a?.id);
  }
  const onMouseLeave = (item: any) => {
    setId("");
  };

  return (
    <Box display="flex" flexDirection="column">
      <SortDialog open={sortEdit} setOpen={setSortEdit} Array={array} />
      <Box>
        <Box mt={1} display="flex" alignItems="center">
          <Box maxWidth="400px">
            <Box display="flex" gap={2}>
              <Typography fontWeight="600">{title}</Typography>
            </Box>

            <Box
              sx={{
                mt: "2px",
                bgcolor: `#2D5696`,
                height: "6px",
                width: "40px",
                borderRadius: "5px",
              }}
            ></Box>
          </Box>
          <IconButton sx={{ ml: 2 }} onClick={() => setSortEdit(true)}>
            <DriveFileRenameOutlineRoundedIcon color="primary" />
          </IconButton>
        </Box>

        <Box p={2} display="flex" flexDirection="row">
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            m="15px 0px"
            gap={2}
          >
            <Box display="inline" minWidth="100%">
              {radio != "off" && (
                <Typography
                  sx={{
                    mt: 1,
                    color: "#222222",
                    opacity: 0.5,
                    fontSize: "14px",
                    display: "inline",
                  }}
                >
                  Select Media
                </Typography>
              )}

              {radio != "off" && (
                <Box maxWidth="200px">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={state}
                      onChange={handleRadio}
                    >
                      <FormControlLabel
                        value="IMAGE"
                        control={<Radio />}
                        label="Image"
                      />
                      <FormControlLabel
                        value="VIDEO"
                        control={<Radio />}
                        label="Video"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}

              {state === "IMAGE" && (
                <Box minWidth="200px">
                  <input
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={handleSChange}
                    name="upload"
                    id={type}
                    style={{ display: "none" }}
                  />
                  <label
                    htmlFor={type}
                    style={{
                      display: "inline",
                      width: "100%",
                      height: "150px",
                      backgroundColor: "red",
                      zIndex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "100%",
                        minHeight: "186px",
                        border: "1px dashed grey",
                        backgroundColor: "#F2F2F2",
                        borderRadius: "10px",
                        display: "flex",
                        position: "relative",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/images/image-icon.png"
                        width="40px"
                        style={{ marginLeft: "20px" }}
                      ></img>
                      <Typography sx={{ fontSize: "14px", opacity: 0.5 }}>
                        Upload image
                      </Typography>
                    </Box>
                  </label>
                </Box>
              )}
              {state === "VIDEO" && (
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "186px",
                    border: "1px dotted grey",
                    backgroundColor: "#F2F2F2",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      pt: "25px",
                      pl: "30px",
                      display: "flex",
                    }}
                  >
                    <Box>
                      <img src="/images/vedio.png" width="20px"></img>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        opacity: 0.5,
                        paddingLeft: "15px",
                      }}
                    >
                      Enter Video URL
                    </Typography>
                  </Box>
                  <TextField
                    sx={{ pl: "30px", pr: "25px" }}
                    id="standard-basic"
                    fullWidth
                    variant="standard"
                    onChange={(e: any) => setVediourl(e.target.value)}
                  />
                  <Button
                    style={{
                      margin: "20px",
                      backgroundColor: "#2D5696",
                      color: "#FFFFFF",
                      padding: "0px 10px",
                    }}
                    onClick={handleVedio}
                  >
                    submit
                  </Button>
                </Box>
              )}
            </Box>
            {image?.map((item: any) => (
              <Box
                m="0px 12px"
                position="relative"
                onMouseEnter={() => mouseEnter(item)}
                onMouseLeave={() => onMouseLeave(item)}
              >
                <img
                  style={{
                    border: "1px solid #bab5b5",
                    objectFit: "contain",
                    width: "100%",
                    height: "250px",
                    display: "inline",
                    borderRadius: "20px",
                  }}
                  draggable={false}
                  src={item}
                  alt=""
                />
                <Box
                  position="absolute"
                  bottom="15px"
                  right="15px"
                  display={item?.id == id ? "inline" : "none"}
                  onClick={() => handleDeleteImage(item)}
                  sx={{ cursor: "pointer" }}
                >
                  <img src="/images/delete.png" width="15px" height="15px" />
                </Box>
              </Box>
            ))}
            {vedio?.map((item: any) => (
              <Box
                m="0px 12px"
                position="relative"
                onMouseEnter={() => mouseEnter(item)}
                onMouseLeave={() => onMouseLeave(item)}
              >
                {item?.includes("youtube.com") ? (
                  <iframe
                    width="100%"
                    height="250px"
                    style={{ borderRadius: "20px" }}
                    src={item.replace("watch?v=", "embed/")}
                  ></iframe>
                ) : item?.includes("vimeo.com") ? (
                  <iframe
                    src={item}
                    width="100%"
                    height="250px"
                    style={{ borderRadius: "20px" }}
                    allow="autoplay; fullscreen; picture-in-picture"
                  ></iframe>
                ) : (
                  <video
                    style={{
                      width: "100%",
                      height: "250px",
                    }}
                    controls
                  >
                    <source
                      src={item}
                      style={{ width: "100%" }}
                      type="video/mp4"
                    />
                  </video>
                )}
                <Box
                  position="absolute"
                  bottom="15px"
                  right="15px"
                  display="inline"
                  onClick={() => handleDVideo(item)}
                  sx={{ cursor: "pointer" }}
                >
                  <img src="/images/delete.png" width="15px" height="15px" />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr 1fr"
        m="15px 0px"
        sx={{
          gridRowGap: "20px",
        }}
      >
        {array?.map((item1: any) =>
          item1?.subtype === "IMAGE" ? (
            <Box
              m="0px 12px"
              position="relative"
              onMouseEnter={() => mouseEnter(item1)}
              onMouseLeave={() => onMouseLeave(item1)}
            >
              <img
                style={{
                  border: "1px solid #bab5b5",
                  objectFit: "contain",
                  height: "250px",
                  width: "100%",
                  display: "inline",
                  borderRadius: "20px",
                }}
                draggable={false}
                src={item1?.url}
                alt=""
              />
              <Box
                position="absolute"
                bottom="15px"
                right="15px"
                display={item1?.id == id ? "inline" : "none"}
                onClick={() => handleD(item1)}
                sx={{ cursor: "pointer" }}
              >
                <img src="/images/delete.png" width="15px" height="15px" />
              </Box>
            </Box>
          ) : (
            <Box
              m="0px 12px"
              position="relative"
              onMouseEnter={() => mouseEnter(item1)}
              onMouseLeave={() => onMouseLeave(item1)}
            >
              {(item1?.url).includes("youtube.com") ? (
                <iframe
                  width="100%"
                  height="250px"
                  style={{ borderRadius: "20px" }}
                  src={(item1?.url).replace("watch?v=", "embed/")}
                ></iframe>
              ) : (item1?.url).includes("vimeo.com") ? (
                <iframe
                  src={item1?.url}
                  width="100%"
                  height="250px"
                  style={{ borderRadius: "20px" }}
                  allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>
              ) : (
                <video
                  style={{
                    objectFit: "fill",
                    width: "100%",
                    height: "250px",
                    display: "inline",
                    borderRadius: "20px",
                  }}
                  controls
                >
                  <source src={item1?.url} type="video/mp4" />
                </video>
              )}
              <Box
                position="absolute"
                bottom="15px"
                right="15px"
                display={item1?.id == id ? "inline" : "none"}
                onClick={() => handleliveVedio(item1)}
                sx={{ cursor: "pointer" }}
              >
                <img src="/images/delete.png" width="15px" height="15px" />
              </Box>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
}

export default WorkshopComponent;
