import { styled } from "@mui/material";
import _ from "lodash";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import http from "utils/http";
import * as COLUMNS from "./columns";
import { parseParams } from "./printPdf";

const urlMap: any = {
  registeredTrainees: "/registration-form",
  timeStatistics: "/stats/half-completed",
  approvedTrainees: "/stats/enrolled-users",
  courseCompletion: "/stats/half-completed",
  certifiedTrainees: "/stats/certified-trainees",
  feedbackData:"/feedback",
};

function PrintTable() {
  const location = useLocation();
  const params: any = parseParams(location.search);

  const { isLoading, data } = useQuery(["applications", params?.category], () =>
    http
      .get(urlMap[params?.category], 
        params?.category==='feedbackData' ? (
        {
        params: {
          ...params,
          districtId:
            params.district == "undefined" || undefined ? "" : params.district,
          limit: 500,
        },
      }):({
        params: {
          ...params,
          district:
            params.district == "undefined" || undefined ? "" : params.district,
          limit: 500,
        },
      })
    )
      .then((res) => {
        return res.data;
      })
  );

  if (isLoading) return <div className="p-5">Loading...</div>;

  const tableColums = ((COLUMNS as any)[params?.category] as any[]).filter(
    (item) => item.key !== "formUrl" && item.key !== "id"
  );

  if (!params?.category) return <h1>Something Went Wrong </h1>;

  return (
    <div className="px-4">
      <table className="text-sm">
        <thead>
          <tr>
            <th className="font-semibold bg-gray-300 text-left px-2 border border-gray-400">
              S.No
            </th>
            {tableColums.map((item, index) => (
              <th
                className="font-semibold bg-gray-300 text-left px-2 border border-gray-400"
                key={index}>
                {item.header}
              </th>
            ))}
          </tr>
        </thead>
        {params?.category==='feedbackData' ?
          (data?.data ? (
            <StyledBody>
              {data?.data?.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="px-2 py-0 border border-gray-400">
                    {index + 1}
                  </td>
                  {tableColums?.map((col, colIndex) => (
                    <td
                      className="px-2 py-0 border border-gray-400"
                      key={colIndex}>
                      {col?.Cell ? (
                        <>{col.Cell(item[col.accessor])}</>
                      ) : (
                        <>{_.get(item, col.accessor)}</>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </StyledBody>
            ) : (
              <section>No Records Found</section>
            )
        )
        :
        (data?.result ? (
          <StyledBody>
            {data?.result?.map((item: any, index: number) => (
              <tr key={index}>
                <td className="px-2 py-0 border border-gray-400">
                  {index + 1}
                </td>
                {tableColums?.map((col, colIndex) => (
                  <td
                    className="px-2 py-0 border border-gray-400"
                    key={colIndex}>
                    {col?.Cell ? (
                      <>{col.Cell(item[col.accessor])}</>
                    ) : (
                      <>{_.get(item, col.accessor)}</>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </StyledBody>
        ) : (
          <section>No Records Found</section>
        ))
        }
      </table>
    </div>
  );
}

const StyledBody = styled("tbody")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "& tr:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "@media print": {
    border: "none",
  },
}));

export default PrintTable;
