import { Box, CircularProgress } from "@mui/material";

function Loader() {
  return (
    <Box
      p={3}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <CircularProgress></CircularProgress>
    </Box>
  );
}

export default Loader;
