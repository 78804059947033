import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Alert,
  Box,
  CircularProgress,
  createTheme,
  Link as MuiLink,
  ThemeProvider,
} from "@mui/material";
import { getStatistics } from "api";
import { AppTraineeLoader } from "components/CounterLoader";
import { format } from "date-fns";
import { UserProfileStore } from "layout/AppRouter";
import moment from "moment";
import Table from "pages/registered-trainees/Table";
import { ApplicationContent } from "pages/stats";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import theme from "styles/theme";
import http from "utils/http";
import TableActions from "./components/TableActions";
import TableFilters from "./components/TableFilters";
import UpdateRegisteredTrainee from "./UpdateRegisteredTrainee";
import { title } from "process";

type IParams = {
  query?: string;
  offset?: number;
  limit?: number;
  district?: string | null;
  batch?: string | null;
  courseId?: number | "";
  search?: string | null;
  course?: string | number | null;
  education?: string | number | null;
  order?: "ASC" | "DESC";
  orderBy?: string;
};

const themeOption = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: "#2d5696",
    },
  },
});

export const fetchApplications = async ({
  query,
  offset = 0,
  district = "",
  limit = 500,
  search = "",
  course = "",
  courseId = "",
  education = "",
  batch = "",
  order,
  orderBy,
}: IParams) => {
  const res = await http.get("/registration-form", {
    params: {
      query,
      offset,
      limit,
      district,
      batch,
      search,
      courseId: course,
      education,
      order,
      orderBy,
    },
  });

  UserProfileStore.update((s) => {
    s.distName = district || "AllDistricts";
  });
  return res.data;
};

interface IFilters {
  page: number;
  limit: number;
  search?: string;
  district?: any;
  batch?: any;
  course?: any;
  education?: string | number | null;
  order?: "ASC" | "DESC";
  orderBy?: string;
}

const RegisteredTrainees = () => {
  const [filters, setFilters] = useState<IFilters>({
    page: 1,
    limit: 500,
    search: "",
    district: null,
    course: null,
    education: "",
    batch: "",
    order: "DESC",
    orderBy: "",
  });
  const [toggleSort, setToggleSort] = useState(false);
  const role = UserProfileStore.useState((s) => s.state.role);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: batchs } = useQuery("batch", () =>
    http.get("/batch").then((res) => res.data)
  );

  const { isLoading, data, error, refetch } = useQuery(
    [
      "applications",
      filters.district,
      filters.page,
      filters.limit,
      filters.course,
      filters.batch,
      filters.education,
      filters.order,
      filters.orderBy,
      filters.search,
    ],
    () =>
      fetchApplications({
        offset: (filters.page - 1) * filters.limit,
        limit: filters.limit,
        district: filters.district?.id,
        search: filters.search,
        course: filters.course?.id,
        education: filters.education,
        batch: filters.batch,
        order: filters.order,
        orderBy: filters.orderBy,
      })
  );

  const { data: Registered, isLoading: RegisteredLdg } = useQuery(
    [
      "statistics",
      {
        districtId: filters.district?.id,
        batch: filters.batch,
        type: "REGISTRATION",
      },
    ],
    getStatistics
  );

  useEffect(() => {
    if (!filters?.search?.length) refetch();
  }, [filters.search]);

  if (error) {
    return (
      <div className="mx-auto pt-10">
        <Alert severity="error">Server Error</Alert>
      </div>
    );
  }
  return (
    <ThemeProvider theme={themeOption}>
      <Box
        style={{
          display: "grid",
          justifyContent: "center",
          marginTop: "2rem",
          gridTemplateColumns: "36%",
        }}
      >
        <ApplicationContent
          title={"Registered Trainees"}
          count={Registered ? Registered?.data?.count : <AppTraineeLoader />}
          isLoading={RegisteredLdg}
          src={"/images/registered-trainees.gif"}
          alt={"filesIcon"}
          color={"#2d5696"}
        />
      </Box>
      <div className="px-3">
        <TableFilters
          filters={filters}
          setFilters={setFilters}
          refetch={refetch}
        />
        <>
          <div className="flex gap-4 items-center h-9 mb-2 justify-end ">
            <TableActions filters={filters} data={data} />
          </div>
          {isLoading && <CircularProgress />}
          {data?.result && (
            <div className="space-y-2">
              <Table
                pagination={{
                  totalCount: data?.totalCount,
                  pageCount: filters.limit,
                  onPageCountChange: (v) =>
                    setFilters({ ...filters, limit: v }),
                  onChange: (newPage) => {
                    setFilters((prev) => ({ ...prev, page: newPage + 1 }));
                  },
                }}
                loading={isLoading}
                data={data?.result}
                columns={columns({
                  showEdit: role === "SUPER_ADMIN" || role === "ADMINISTRATOR",
                })}
                filters={filters}
                setFilters={setFilters}
                toggleSort={toggleSort}
                setToggleSort={setToggleSort}
              />
            </div>
          )}
        </>
      </div>
      <UpdateRegisteredTrainee
        data={data?.result?.find(
          (item: any) => item.id?.toString() === searchParams?.get("selected")
        )}
        open={!!searchParams.get("editOpen")}
        setOpen={(v) => setSearchParams({})}
      />
    </ThemeProvider>
  );
};

export default RegisteredTrainees;

export const columns: any = ({ showEdit }: any) => {
  return [
    { key: "id", title: "Sr.No." },
    {
      key: "bocRegNo",
      title: "BOCW ID",
      sortable: {
        sort: true,
        default: "ASC",
      },
    },
    { key: "name", title: "Trainee Name" },
    { key: "motherName", title: "Mother Name" },
    { key: "mobile", title: "Mobile Number" },
    { key: "aadharNumber", title: "Aadhar Number" },
    { key: "education", title: "Education Qualification" },
    {
      key: "courseName",
      title: "Course Name",
      render: (data: any) => data.courseName.name,
    },
    { key: "batchNumber", title: "Batch Number" },
    {
      key: "courseStartDate",
      title: "course Start Date",
      render: (data: any) => (
        <>
          {data?.courseStartDate
            ? moment(data?.courseStartDate).format("YYYY-MM-DD")
            : "-"}
        </>
      ),
    },

    { key: "setwinId", title: "ID Generated by SETWIN" },
    {
      key: "status",
      title: "Status of Application",
      render: (data: any) => (
        <>
          {data.status === true && "Success"}
          {data.status === false && "Fail"}
          {data.status === null && "Waiting"}
        </>
      ),
    },
    {
      key: "dateOfBirth",
      title: "Age",
      render: (data: any) => (
        <>
          {data?.dateOfBirth
            ? moment().diff(moment(data?.dateOfBirth, "YYYY-MM-DD"), "years")
            : ""}
        </>
      ),
    },
    {
      title: "Gender",
      key: "gender",
    },

    { key: "workerName", title: "Name of the Worker" },
    {
      key: "educationStatus",
      title: "Education Status",
      render: (data: any) => <>{data?.educationStatus}</>,
    },
    { key: "village", title: "Village" },

    { key: "mandal", title: "Mandal" },
    {
      key: "district",
      title: "District",
      render: (data: any) => data.district,
    },
    {
      key: "ipAddress",
      title: "IP Address",
    },
    {
      title: "View",
      key: "id",
      render: (data: any) => (
        <Link
          className="hover:underline text-primary font-medium"
          to={`${data?.id}`}
        >
          View
        </Link>
      ),
    },
    ...(showEdit
      ? [
          {
            title: "Edit",
            key: "id",
            render: (data: any) => <Edit data={data} />,
          },
        ]
      : []),
    {
      title: "Download",
      key: "formUrl",
      render: (data: any) => (
        <MuiLink target="_blank" href={data?.formUrl}>
          <FileDownloadOutlinedIcon />
        </MuiLink>
      ),
    },
  ];
};

function Edit({ data }: any) {
  return (
    <>
      <Link
        className="hover:underline text-primary font-medium"
        to={`?editOpen=true&selected=${data?.id}`}
      >
        Edit
      </Link>
    </>
  );
}

export const excelColumns: { title: string; key: string; render?: any }[] = [
  { key: "id", title: "Sr.No." },
  {
    key: "bocRegNo",
    title: "BOCW ID",
  },
  { key: "name", title: "Trainee Name" },
  { key: "mobile", title: "Mobile" },
  { key: "education", title: "Education" },
  {
    key: "courseName",
    title: "Course Name",
    render: (value: any) => {
      return value.name;
    },
  },
  { key: "batchNumber", title: "Batch Number" },
  {
    key: "registrationDate",
    title: "Registration Date",
    render: (data: any) => format(new Date(data), "yyyy-mm-dd") || "-",
  },
  {
    key: "status",
    title: "Status of Application",
    render: (value: any) => {
      if (value == null) {
        return "Waiting";
      } else if (value == 0 || value == false) {
        return "Fail";
      } else {
        return "Success";
      }
    },
  },
  { key: "setwinId", title: "ID Generated by SETWIN" },
  { key: "workerName", title: "Name of the Worker" },
  { key: "district", title: "District" },
];
