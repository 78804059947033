import { Button, CircularProgress, Link } from "@mui/material";
import { exportEnrolledUsers, getEnrolledUsers } from "api";
import Filters from "components/Filters";
import { UserProfileStore } from "layout/AppRouter";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFullPageLoader } from "../../components/FullPageLoader";
import ReactTable from "../../components/ReactTable";

const filters = {
  perPage: 100,
  page: 1,
  search: "",
  course: {
    id: 0,
    name: null,
    courseId: "",
    courseBatchNumber: null,
  },
  education: "",
  district: "",
  batch: "",
};

interface Props {
  distCode?: string;
  batch?: string;
  setDistrict?: any;
  columns: any[];
  bgHeaderColor: string;
  pageSectionName: string;
}

function Table(props: Props) {
  const {
    columns,
    bgHeaderColor,
    pageSectionName,
    distCode,
    batch,
    setDistrict,
  } = props;
  const [filter, setFilter] = useState(filters);
  const { setLoading } = useFullPageLoader();
  const role = UserProfileStore.useState((s) => s.state.role);

  const { data: applicantsData, isLoading } = useQuery(
    [
      "applicants-data",
      {
        ...filter,
        course: filter?.course?.courseId,
        offset: (filter.page - 1) * filter.perPage,
        limit: filter.perPage,
        district: distCode,
        batch: batch,
      },
    ],
    getEnrolledUsers
  );

  const { mutate } = useMutation(exportEnrolledUsers, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "Approved Trainees.xlsx";
      link.click();
      toast.success("Exported successfully");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleExport = () => {
    setLoading(true);
    mutate({
      ...filter,
      course:
        (filter?.course?.courseId == "" ? "" : filter?.course?.courseId) ==
        undefined
          ? ""
          : filter?.course?.courseId,
      district: distCode,
      batch: batch,
    });
  };

  function handlePage(index: any) {
    setFilter((prev) => ({ ...prev, page: prev.page + index }));
  }

  return (
    <>
      <Filters
        filters={filter}
        setFilters={setFilter}
        distCode={distCode}
        setDistrict={setDistrict}
      />
      {(role === "SUPER_ADMIN" ||role == "ADMINISTRATOR" ||role == "SME_ADMIN") && (
        <div className="flex gap-2 items-center justify-end">
          <Button
            variant="contained"
            startIcon={<img src="/images/export_execl.svg" width="16px" />}
            style={{
              backgroundColor: "#F2F2F2",
              color: "#1C1C1C",
              margin: "5px 10px",
              padding: "5px 10px",
              fontSize: "14px",
            }}
            onClick={handleExport}>
            Export to Excel
          </Button>
          <Link
            underline="none"
            style={{
              margin: "0px 5px",
              padding: "0px",
            }}
            href={`/print-table?${new URLSearchParams({
              ...filter,
              course:
                (filter?.course?.courseId == ""
                  ? ""
                  : filter?.course?.courseId) == undefined
                  ? ""
                  : filter?.course?.courseId,
              district: distCode,
              batch: batch,
            } as any).toString()}&category=${pageSectionName}`}
            target={"_blank"}>
            <Button
              variant="contained"
              startIcon={<img src="/images/export_pdf.svg" width="16px" />}
              style={{
                backgroundColor: "#F2F2F2",
                color: "#1C1C1C",
                padding: "5px 10px",
                fontSize: "14px",
              }}>
              Export to Pdf
            </Button>
          </Link>
        </div>
      )}
      {isLoading && <CircularProgress />}
      {applicantsData && (
        <ReactTable
          columns={columns}
          data={applicantsData.data?.result}
          handleButton={handlePage}
          page={filter.page}
          setFilter={setFilter}
          pageCount={Math.ceil(
            applicantsData?.data?.totalCount / filter.perPage
          )}
          perPage={filter.perPage}
          bgHeaderColor={bgHeaderColor}
        />
      )}
    </>
  );
}

export default Table;
