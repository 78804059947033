import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { workshopUploadVideo } from "api";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  data?: any;
}

function AddWorkshopVideoDialog({ open, setOpen }: Props) {
  const { folderId } = useParams();
  const id = folderId;
  const [state, setState] = useState("");
  const queryClient = useQueryClient();

  const { mutate } = useMutation(workshopUploadVideo, {
    onSuccess: () => {
      queryClient.invalidateQueries("getworkshopstorage");
      setOpen(false);
      setState("");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleChange = (e: any) => {
    setState(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      url: state,
      folderId: id,
    };
    mutate(data);
  };

  return (
    <Drawer
      anchor="right"
      sx={{ zIndex: 1202 }}
      PaperProps={{ sx: { width: 400 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <AppBar position="sticky">
        <Toolbar
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1"> Add Video URL</Typography>
          <IconButton onClick={() => setOpen(false)} sx={{ color: "white" }}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            size="small"
            name="url"
            label="Add video url "
            multiline
            maxRows={4}
            value={state}
            onChange={(e: any) => setState(e.target.value)}
          ></TextField>

          <Box mt={2} textAlign="right">
            <Button
              style={{ backgroundColor: "#f50000" }}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
}

export default AddWorkshopVideoDialog;
