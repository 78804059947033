import { Alert, Box, CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { GoBackButton } from "../../../components";
import http from "../../../utils/http";
import "./preview-page.css";

const ApplicationDetailsPage = () => {
  const params: any = useParams();

  const { data, isLoading, error } = useQuery(
    ["application", params.applicationId],
    () => http.get(`/registration-form/${params.applicationId}`)
  );

  if (error)
    return (
      <div className="mx-auto pt-10">
        <Alert severity="error">Server Error</Alert>
      </div>
    );

  return (
    <div className="w-full">
      <GoBackButton label="Application Details" />

      {!isLoading ? (
        <Preview data={data?.data} />
      ) : (
        <Box display={"flex"} justifyContent="center" pt={5}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ApplicationDetailsPage;

const Preview = ({ data }: { data: any }) => {
  return (
    <Box sx={{ margin: "0 auto", pb: "100px" }}>
      <header className="header">
        <div className="title">
          <h1>
            Telangana Building And Other Construction Workers Welfare Board
          </h1>
          <h2>
            SETWIN Application form for Online Skill Upgradation Training
            Programme
            <br />
            for Registered BOC Workers and their Dependents
          </h2>
        </div>
        <div className="passport-photo">
          <img
            height="100%"
            width="100%"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            src={data?.applicantFileUrl}
            alt="passport size photo"
          />
        </div>
      </header>
      <Box className="page">
        <div className="field-section">
          <section className="details">
            <ol>
              <div className="form-control">
                <li>Serial Number</li>
                <p>{data?.id}</p>
              </div>
              <div className="form-control">
                <li>Batch Number</li>
                <p>{data?.batchNumber}</p>
              </div>
              <div className="form-control">
                <li>Name of the Worker</li>
                <p>{data?.workerName}</p>
              </div>
              <div className="form-control">
                <li>BOC CARD Number</li>
                <p>{data?.bocRegNo}</p>
              </div>
              <div className="form-control">
                <li>Name of Worker/Dependent</li>
                <p>{data?.name}</p>
              </div>
              <div className="form-control">
                <li>Dependent Mother Name</li>
                <p>{data?.motherName}</p>
              </div>
              <div className="form-control">
                <li>Education Qualification</li>
                <p>{data?.higherEducation}</p>
              </div>
              <div className="form-control">
                <li>Course Selected</li>
                <p>{data?.course?.name}</p>
              </div>
              <div className="form-control">
                <li>Mobile Number of Dependent</li>
                <p>{data?.mobile}</p>
              </div>
              <div className="form-control">
                <li>District</li>
                <p>{data?.district}</p>
              </div>
              <div className="form-control">
                <li>ID Generated by SETWIN</li>
                <p>{data?.formId}</p>
              </div>
              <div className="form-control">
                <li>Trainee Aadhar Number</li>
                <p>{data?.aadharNumber}</p>
              </div>
            </ol>
          </section>
        </div>
        <div className="aadhar-section">
          <img
            src={data?.aadharFileUrl}
            alt=""
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </Box>
      <footer>
        <div className="signs">
          <div className="candidate-sign">
            <img
              src={data?.signUrl}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              alt="Signature of candidate"
            />
            <p style={{ textAlign: "center", paddingTop: "10px" }}>
              Signature of the Candidate
            </p>
          </div>
          <div className="alo-sign">
            <div
              style={{
                height: "140px",
                width: "240px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}></div>
            <p style={{ textAlign: "center", paddingTop: "10px" }}>
              Signature of ALO
            </p>
          </div>
        </div>
      </footer>
    </Box>
  );
};
